export default (text) => {
    if(!text) return '';
    let result = text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    if(result.includes('-')){
        result = result
            .split('-')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join('-');
    }

    if(result.includes('#')){
        result = result
            .split('#')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join('#');
    }


    if(result.includes('Hi,')){
        result = result.replace('Hi,','HI,');
    }

    return result;
};
