<template>
    <div>
        <h2>Instagram</h2>
        <p>We are here</p>
        {{username}}
    </div>
</template>

<script>
    import axios from 'axios';



    export default {
        props:['username'],
        name: "Instagram",
        mounted(){
                        this.loadData();


        },
        methods:{
            async loadData(){
                const {data} = await axios.get('https://www.instagram.com/' + this.username);
                                const result = data.match(/<script type="text\/javascript">window\._sharedData = (.*);<\/script>/);
                let profileData = {};
                if(result && result[1]){
                    profileData = JSON.parse(result[1]);
                }
                var {entry_data} = profileData;
                var {ProfilePage} = entry_data;
                                var user = ProfilePage[0].graphql.user;
                                const media = user.edge_owner_to_timeline_media.edges;

            }
        },
        data(){
            return {
                data:{}
            }
        }
    }
</script>

<style scoped>

</style>
