import axios from 'axios';
import formatPhoneNumber from '../../helpers/formatPhoneDashes';
import formData from 'form-data';
function toFormUrlEncoded(obj, prefix) {
  const str = [];

  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const k = prefix ? `${prefix}[${key}]` : key;
    const v = obj[key];

    if (typeof v === 'object' && v !== null && !Array.isArray(v)) {
      str.push(toFormUrlEncoded(v, k)); // handle nested object
    } else if (Array.isArray(v)) {
      v.forEach((val, i) => {
        str.push(`${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`);
      });
    } else {
      if(v !== null) str.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }

  return str.join('&');
}



import ph from '../../../src/lib/posthog';
class VercelSalesforceAPI {
    constructor() {
        this.action = null;
        this.data = null;
        this.debug = false;
        this.sendData = {
            contact:{ name:null, first_name:null, last_name:null, email:null, phone:null,full_name:null },
            listing:null,
            agent_email:null,
            form:{},
            lead_source:null,
            description:null,
            referral_source:null,
            web_source:null,
            pba__Request__c:{
                Region__c:null,
                District__c:null,
                Neighborhood__c:null,
                MLS_Number__c:null,
                Property_Price__c:null,
                Bedrooms_min__c:null,
                pba__TotalArea_pb_min__c:null,
                pba__PropertyType__c:null,
                LeadSource__c:null,
                Inquiry_Message__c:null,
                web_source__c:null,
            }
        };
    }

    setFirstName(first_name) {
        this.sendData.contact.first_name = first_name;
    }

    getLeadSource(){
      return this.sendData.pba__Request__c.LeadSource__c;
    }

    setLastName(last_name) {
        this.sendData.contact.last_name = last_name;
    }
    setComments(comments){
      this.sendData.pba__Request__c.Inquiry_Message__c = comments;
    }
    getComments(){
      return this.sendData.pba__Request__c.Inquiry_Message__c;
    }
    setEmail(email) {
        this.sendData.contact.email = email;
    }
    getMessage(){
      return this.sendData.pba__Request__c.Inquiry_Message__c;
    }
    setPhone(phone) {
      console.log('the phone was set to contact',phone);
      this.sendData.contact.phone = phone;
    }

    setAgentEmail(agent_email) {
        this.sendData.agent_email = agent_email;
        this.sendData.pba__Request__c.Listing_Agent_Email__c = agent_email;
    }

    setAction(action) {
        this.action = action;
    }
    setFullName(full_name) {
      this.sendData.contact.full_name = full_name;
    }

    mapLeadFROMUISource(source){
        const mappings = {
            "Advisor Page": "HL Web - Request Agent",
            "Advisor Index Page": "HL Web - Request Agent",
            "Meet with Agent" : 'HL Web - Request Agent',
            "Listing Page": "HL Web - Listing",
            "Listing - Ask a Question": "HL Web - Listing",
            "New Account": "HL - Registration (CE)",
            "Live Chat": "HL Web - Live Chat",
            "Make Offer": "HL Web - Make Offer",
            "Schedule Showing": "HL Web - Request Showing",
            "More Info": "HL Web - Question",
            "Project Page": "HL Web - Development",
            "Project - Ask a Question": "HL Web - Development",
            "Project - Developer": "HL Web - Developer",
            "Project Page - Details": "HL Web - Direct Email (Agent)",
            "Update Account": "HL Web - Registration",
            "Property Evaluation": "HL Web - CMA",
            "Blog Post": "HL Web - Direct Email",
            "Default": "HL Web - Question",
            "Connect with an Agent": "HL Web - Request Agent",
            "HL Web - Email Message":"HL Web - Email Message",
            "Web - Agent Site" : "Web - Agent Site"
        };

        if(mappings[source]){
            this.setLeadSource(mappings[source]);
        }else{
            this.setLeadSource(source);
        }
    }

    setAsFindAgentLead(){
        this.setLeadSource('HL Web - Request Agent');
    }

    setWebsource(source){
      this.sendData.web_source = source;
      this.sendData.pba__Request__c.web_source__c = source;
    }

    setAsRequestAgentLead(){
        this.setLeadSource('HL Web - Request Agent');
    }

    setAsWebQuestion(){
        this.setLeadSource('HL Web - Question')
    }

    setAsFacebookAd(){
        this.setLeadSource('HL Lead - Facebook');
    }
    setWebSource(source){
      this.sendData.pba__Request__c.web_source__c = source;
    }

    setUserObject(obj) {
        if(obj.client_advisor){
            this.sendData.agent_email = obj.client_advisor.email;
        }
        if(obj.agent){
            this.sendData.agent_email = obj.agent.email;
        }


    }
    setTimeZone(tz){
      this.sendData.pba__Request__c.originatingTimeZone = tz;
    }
    setRequestedCallBackTime(time){
      this.sendData.pba__Request__c.requestedCallbackTime = time;
    }

    setFormData(data){
        this.sendData.form = data;
    }
    setAsRequestShowing(){
        this.setLeadSource('HL Web - Request Showing');
    }

    setAsMakeOffer(){
        this.setLeadSource('HL Web - Make Offer');
    }

    setAsBoomtownRegistration(){
        this.setLeadSource('Web - BoomTown registration');
    }

    setAsBoomtownActivity(){
        this.setLeadSource('Web - BoomTown activity');
    }

    setListing(listing) {
        this.sendData.listing = {...listing};
        this.sendData.pba__Request__c = {
            Region__c:null,
            District__c:null,
            Neighborhood__c:null,
            MLS_Number__c:null,
            Property_Price__c:null,
            Bedrooms_min__c:null,
            pba__TotalArea_pb_min__c:null,
            pba__PropertyType__c:null,
            LeadSource__c:null,
            ...this.sendData.pba__Request__c,
        };
        this.sendData.pba__Request__c.Region__c = listing.island ? listing.island : listing.geo_island;
        this.sendData.pba__Request__c.District__c = listing.geo_district ? listing.geo_district : listing.district;
        this.sendData.pba__Request__c.Neighborhood__c = listing.geo_town ? listing.geo_town : listing.town;
        this.sendData.pba__Request__c.MLS_Number__c = listing.mls_id ? listing.mls_id : listing.mls_number;
        this.sendData.pba__Request__c.Property_Price__c = listing.asking_price;
        this.sendData.pba__Request__c.Bedrooms_min__c = listing.beds;
        this.sendData.pba__Request__c.pba__TotalArea_pb_min__c = listing.total_floor_area ? listing.total_floor_area : listing.living_area;
        let t = listing.dwelling_type_name && Array.isArray(listing.dwelling_type_name) && listing.dwelling_type_name.length > 0 ? listing.dwelling_type_name[0] : listing.dwelling_type_name;
        if(t === 'Single Family Home' || t === 'House' || t === 'Single Family Residence'){
            this.sendData.pba__Request__c.pba__PropertyType__c = 'Residential';
        }
        if(t && t === 'Condo' || t === 'Condominium' || t === 'Townhouse' || t.includes('ondo')){
            this.sendData.pba__Request__c.pba__PropertyType__c = 'Condo';
        }
        if(t && t.toLowerCase().includes('land')){
            this.sendData.pba__Request__c.pba__PropertyType__c = 'Land';
        }
        if(this.sendData.lead_source){
            this.sendData.pba__Request__c.LeadSource__c = this.sendData.lead_source;
        }
        //console.log(this.sendData);
        //map the records
    }
    setSearch(search) {
        this.sendData.search = search;
    }

    setMLSNumber(mls_number) {
      this.sendData.pba__Request__c.MLS_Number__c = mls_number;
    }


    setAsWebAgentSite(){
        this.setLeadSource('Web - Agent Site');
    }

    setFormProperty(property, value) {
        let f = {...this.sendData.form};
        f[property] = value;
        this.sendData.form = f;
    }
    setLeadSource(lead_source) {
        this.sendData.lead_source = lead_source;
        this.sendData.pba__Request__c.LeadSource__c = lead_source;
        this.sendData.contact.LeadSource = lead_source;
    }
    setReferralSource(referral_source) {
        this.sendData.referral_source = referral_source;
        this.sendData.pba__Request__c.web_source__c = referral_source;
    }
    buildSendData() {
      let data = {};
        let sendData = JSON.parse(JSON.stringify({action: this.action, version: 1.1, ...this.data, ...this.sendData}));
        //if the sendData.pba__Request__c is all null, remove it
        //if pba__Request__c.mls_id
        if (!this.sendData.pba__Request__c.MLS_Number__c) {
          delete (this.sendData.pba__Request__c.MLS_Number__c);
        }
        // contact: {
        //   FirstName: apiData.contact.first_name,
        //     LastName: apiData.contact.last_name,
        //     Email: apiData.contact.email,
        //     Phone: apiData.contact.phone,
        //     LeadSource:apiData.lead_source ? apiData.lead_source : '',
        // },
        sendData.contact.FirstName = sendData.contact.first_name;
        sendData.contact.LastName = sendData.contact.last_name;
        if(!sendData.contact.LastName){
          sendData.contact.LastName = 'Unknown';
        }
        if(!sendData.contact.FirstName){
          sendData.contact.FirstName = 'Unknown';
        }
        sendData.contact.Email = sendData.contact.email;
        sendData.contact.Phone = sendData.contact.phone;
        sendData.contact.LeadSource = sendData.lead_source ? sendData.lead_source : sendData.contact.LeadSource;
        if (!sendData.listing) {
          delete (sendData['listing']);
        }

        if(!sendData.pba__Request__c.Inquiry_Message__c){
          sendData.pba__Request__c.Inquiry_Message__c = '';
        }
        if (sendData.contact.Phone) {
          sendData.pba__Request__c.Inquiry_Message__c = sendData.pba__Request__c.Inquiry_Message__c + "\nPhone: " + formatPhoneNumber(sendData.contact.Phone);
        }
        if(sendData.pba__Request__c.LeadSource__c === 'HL Web - Favorite Listing'){
          sendData.pba__Request__c.Inquiry_Message__c = 'Favorited listing.';
        }
        return sendData;
      }

    async create() {
      const sendData = this.buildSendData();
      //console.log('data to vercel',sendData);
      this.sendPropertyBaseRequest(sendData);
      this.sendToPosthog(sendData);
      try{
            const r2 = await axios.post('https://salesforce-data-ingestion.vercel.app/api', sendData,{
                headers: {
                    'Content-Type': 'application/json'
                },
            }).catch((e) => {
                console.log('Error in vercel API call:', e);
            });
            return true;
        } catch (error) {
            console.error('Error in VercelAPI:', error);
            return null;
        }


    }
    async sendToPosthog(sendData){
      const p = new ph();
      console.log('data to posthog',sendData);
      await p.capture(sendData.contact.LeadSource,sendData)

    }
    async setIsland(island){
      this.sendData.pba__Request__c.Region__c = island;
    }
    async setCommunity(community){
      this.sendData.pba__Request__c.Neighborhood__c = community;
    }
    async sendPropertyBaseRequest(sendData){
      console.log('the sendData passed in is',sendData);
      const data = {
        request:{
          ...sendData.pba__Request__c,
        }
      }
      data.contact = {
        FirstName: sendData.contact.first_name,
        LastName: sendData.contact.last_name,
        Email: sendData.contact.email,
        Phone: sendData.contact.phone,
        LeadSource:sendData.contact.LeadSource
      };

      if(!data.contact.LastName || !data.contact.LastName.length) data.contact.LastName = "Unknown";
      console.log('data to frontdesk', data);
      return;

      const fd = toFormUrlEncoded(data);
        await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a', fd, {headers: {'Content-Type': 'multipart/form-data'}});
    }
}

export default VercelSalesforceAPI;

