import { render, staticRenderFns } from "./AreaAdditionalContent.vue?vue&type=template&id=5e791409&scoped=true"
import script from "./AreaAdditionalContent.vue?vue&type=script&lang=js"
export * from "./AreaAdditionalContent.vue?vue&type=script&lang=js"
import style0 from "./AreaAdditionalContent.vue?vue&type=style&index=0&id=5e791409&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e791409",
  null
  
)

export default component.exports