<template>
  <div class="hawaiilife-signup-inline">
      <div class="inner-div" v-if="!showSuccess">
        <h2>Get <span data-v-2708082a="" class="bolder"><span class="hl-blue">Hawaii</span><span class="green">Life</span>Style</span> in Your Inbox</h2>
        <p>Receive first to know info on Hawaii real estate.</p>
      </div>
    <div class="flex-row-center" v-if="!showSuccess">
      <input type="text" :class="{error:error && submitted}" v-model="email" placeholder=">> Enter Email" class="hl-input">
      <a @click="signup" class="hl-button inline">Sign Up</a>
    </div>
    <div v-if="showSuccess">
      <p>Thank you! We will email you about once a week.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Cookie from "js-cookie";

export default {
  data(){
    return {email:'',error:false,submitted:false,showSuccess:false}
  },
  name: "HawaiiLifeStyleInline",
  methods:{
    async signup(){
      this.submitted = true;
      const url = 'https://platform.hawaiilife.com/subscribe';
      if(this.email){
        this.error = false;
        await axios.post(url,{email:this.email,list_id:'fab580be3f6c8ca85ffb9d7b5b927a13'});
        this.showSuccess = true;
        window.setTimeout(() => {
          this.show = false;
          Cookie.set('dismissed_hawaii_lifestyle_signup','1',365);
        },5000);
      }else{
        this.error = 'Please Enter an Email';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hawaiilife-signup-inline{
  background-color: rgba(255,255,255,0.9);
  padding:10px;
  box-sizing: content-box;
  .inner-div{
    margin-left:20px;
    margin-right:20px;
  }
  font-family: "Whitney A",'Whitney B',sans-serif;
  display:flex;
  flex-direction: row;
  align-items: center;
  @media(max-width:800px){
    flex-direction: column;
    align-items: flex-start;

  }
  width:auto;
  div{
    flex:1;
  }
  h1,h2{
    font-weight:400;
    margin-bottom:0px;
    font-size:20px;
    margin-top:0px;
  }
  p{
    margin-top:3px;
    color:#666;
    margin-bottom:0px;
  }
}

.flex-row-center{
  padding-left:0px;
  @media(max-width:800px){
    border-left:none;
    align-content: center;
    padding-left: 0px;
    margin-bottom:5px;
  }
  display:flex;
  flex-direction: row;
  align-items: center;
  .hl-button{
    width:auto;
    min-width:100px;
    padding-top:11px;
    padding-bottom: 11px;
    margin-top:0px;
    margin-left:3px;
  }
  .hl-input{
    border:1px #999999 solid;
    border-radius: 0px;
  }

}
</style>
