<template>
    <div style="position:relative;">
        <h2>{{title}}</h2>
        <div v-if="loading">Loading...</div>
        <div class="blog-post-collection">
            <div v-for="blogPost in posts.slice(0,limit)" @click="openBlogPost(blogPost.url)">
                <a :href="blogPost.categoryLink"><span class="blog-category" target="_blank" v-if="blogPost.category">{{blogPost.category}}</span></a>
                <div v-if="blogPost.thumb" class="image-bg" :style="{'background-image':`url(${blogPost.thumb})`}"></div>
                <a :href="blogPost.url" target="_blank" class="no-underline"><h4>{{blogPost.title}}</h4></a>
                <div class="blog-post-description" v-html="blogPost.desc + '...'">
                </div>
                <div class="blog-post-summary">
                    <span v-if="blogPost.author">{{blogPost.author}}</span> | <span>{{blogPost.pubDateFormat}}</span>
                </div>
            </div>
        </div>
        <div v-if="readMoreText || suggestedTitle" class="more-wrapper">
            <small class="more more-link bottom-right"><a class="more-link scott conrad" :href="readMoreUrl">{{readMoreText ? readMoreText : suggestedTitle}} ></a></small>
        </div>
    </div>




</template>

<script>
    import axios from 'axios';
    import { parse } from 'node-html-parser';
    var windowEventAttached = false;
    var searchParam = function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        if(!url) return '';
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    export default {
        name: "BlogList",
        props:['title','url','limit','moreText','moreLink','link','readMoreText','src','intelligent','section'],
        data(){
            return {
                posts:[],
                loading:true,
                suggestedTitle:null,
                urlOverride:null,
                srcOverride:null,
            }
        },
        methods:{
          getImageFromContent(content){
              var result = content.match(/(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/g);
              if(result && result[0]) return result[0];
              return null;
          },
          openBlogPost(url){
              window.open(url,'_blank');
          },
           async loadPosts(){
                let platform_url = window.location.origin.includes('localhost') ? 'http://localhost:3000' : 'https://platform.hawaiilife.com';
                const prs = [];
                let urls = [];
                const makeUrl = (url,params = {}) => {
                    return {url:url,params};
                };
                if(this.url && this.url.includes(',')) {
                    urls = this.url.split(',').map(item => {
                        return makeUrl(item.trim());
                    });
                }else if(this.url){
                    urls = [makeUrl(this.url)];
                }
                let URL_OVERRIDES = '';
                if(this.intelligent){
                    let islandTitles = {
                        'kauai':`Kaua'i`,
                        'maui':'Maui',
                        'big-island':'Big Island',
                        'oahu':'Oahu',
                        'lanai':'Lanai',
                        'molokai':'Molokai'
                    };

                    let location = window.location.pathname;
                    let path = window.location.pathname;
                    if(location.includes('/listings') && this.section == 'listings') {
                        location = location.replace(/.*\/listings\//, '');
                        location = location.replace('/listings', ''); //just in case we have this..
                        location = location.split('/').filter((item) => {
                            return (item.substring(0,1) != '-' && item.length);
                        }).join('/');

                        if (location.includes('?')) {
                            const split = location.split('?');
                            location = '/' + split[0];
                        }
                        const parts = location.split('/');
                        const island = parts[0];
                        if(island){
                            location = '/listings/' + island;
                        }
                        this.suggestedTitle = `Read More Real Estate News `;
                        if(island && islandTitles[island]){
                            this.suggestedTitle = `Read More about ${islandTitles[island]}`;
                        }


                        if (island) {
                            this.urlOverride = 'https://hawaiilife.com/blog/category/' + island;
                            path = `/listings/${island}`;
                            URL_OVERRIDES = [makeUrl(`https://www.hawaiilife.com/blog/category/${island}/`)];
                            this.dataOverride = 'rss';
                        }else{
                            URL_OVERRIDES = [makeUrl(`https://www.hawaiilife.com/blog/?s=`)];
                        }
                    }

                    if(location.includes('-gluxury')){
                      URL_OVERRIDES = [makeUrl(`https://www.hawaiilife.com/blog/category/luxury`)];
                    }
                    if(location.includes('-gconservation')){
                      URL_OVERRIDES = [makeUrl(`https://www.hawaiilife.com/blog/category/conservation`)];
                    }


                    let query = searchParam('query') ? searchParam('query') : null;
                    if(query){
                        urls = [makeUrl('https://platform.hawaiilife.com/api/blog/search/' + query.toLowerCase())];
                    }else{
                        urls = [makeUrl(platform_url + '/api/blog/search/related',{location,path})];
                    }
                }
                if(URL_OVERRIDES && URL_OVERRIDES.length) urls = URL_OVERRIDES;
                for(var url of urls){
                    prs.push(axios.get(url.url,{params:url.params}));
                }
                let allPosts = [];
                const posts = await Promise.all(prs);
                if(this.dataType == 'json'){
                    const {data} = posts[0];
                    if(posts && data.result) for(var post of data.result) {
                        let postURL = post.url.replace('about.hawaiilife.com', 'www.hawaiilife.com') + '?ref=wc-embed';
                        allPosts.push({
                            title: post.title[0],
                            link:postURL,
                            url:postURL,
                            pubDate: post.pubDate,
                            desc: post.summary.trim(),
                            thumb: post.thumb,
                            pubDateFormat: post.posted_display,
                            author: post.author_name,
                            //     desc:item.description ? item.description[0] : "",
                            //     author:item['dc:creator'] ? item['dc:creator'][0] : null,
                            //     pubDateFormat:new Date(item.pubDate).toLocaleDateString('en-US',{month:'long',year:'numeric',day: 'numeric'})
                        });

                    }
                    this.loading = false;
                    this.posts = allPosts;
                    return;
                }
                for(var postCollection of posts){
                    const d = postCollection.data;
                    const result = parse(d);
                    const articleSelect = result.querySelectorAll('.packery article');
                    if(articleSelect){
                        for (var item of articleSelect){
                            let html = parse(item.innerHTML);
                            const titleElement = html.querySelector('h3.title a');
                            const title = titleElement.text;
                            const link = titleElement.attributes.href + '?ref=wc-embed';
                            const list = html.querySelectorAll('ul.list li');
                            const pubDate = list[1].text;
                            const blogAuthor = list[0].text;
                            const desc = html.querySelector('.content-holder p') ? html.querySelector('.content-holder p').text : '';
                            const thumbE = html.querySelector('img');
                            const categoryE = html.querySelector('span.category');
                            const categoryLink = categoryE ? categoryE.attributes.href : null;
                            const category = categoryE ? categoryE.text : null;
                            let thumb = null;
                            if(thumbE) {
                                thumb = thumbE.attributes.src;
                            }
                            if(!title) continue;
                            allPosts.push({
                                title,
                                link:link,
                                url:link,
                                pubDate:pubDate,
                                desc,
                                thumb,
                                category,
                                categoryLink,
                                pubDateFormat:pubDate,
                                author:blogAuthor.trim(),
                                //     desc:item.description ? item.description[0] : "",
                                //     author:item['dc:creator'] ? item['dc:creator'][0] : null,
                                //     pubDateFormat:new Date(item.pubDate).toLocaleDateString('en-US',{month:'long',year:'numeric',day: 'numeric'})
                            });
                        }
                    }
                }
                allPosts.sort((a,b) => {
                    const first = new Date(a.pubDate);
                    const second = new Date(b.pubDate);
                    if(first < second) return 1;
                    if(first > second) return -1;
                    return 0;
                });
                this.loading = false;
                this.posts = allPosts;

            }
        },

        async mounted(){
            this.loadPosts();
            // window.addEventListener("hashchange", () => {
            //     this.loadPosts();
            // }, false);
            // try {
            //     window.addEventListener("popstate", () => {
            //         if(console)             //         this.loadPosts();
            //     });
            // }catch(e){
            //     //this might not exist,, everywhere
            // }
            let url = window.location.href;
            if(this.intelligent) {
                setInterval(() => {
                    const now = window.location.href;
                    if (now !== url) {
                        url = now;
                        this.loadPosts();
                    } else {
                    }
                }, 2000);
            }
        },
        computed:{
            readMoreUrl(){
                if(this.urlOverride) return this.urlOverride;
                if(this.url) return this.url;
                if(this.link) return this.link;
            },
            dataType(){
                if(this.dataOverride) return this.dataOverride;
                return this.src;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../assets/styles';
    .more-wrapper{
        padding-top:30px;
    }
    .blog-post-collection{
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      >div{
        width:31%;
        box-sizing:content-box;
        @media(max-width: 800px){
          width:40%;
        }
        @media(max-width: 520px){
          width:100%;
        }
        padding-bottom:20px;
      }



    }


</style>
