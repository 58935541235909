<template>
  <div>
  <modal v-if="show" :close-modal="closeModal" :hide-close="true" >
    <div class="registration-complete">
      <div class="inner">
        <h2>Registration Complete</h2>
        <p v-if="1 === 2">Congratulations, you have access to Hawaii's most comprehensive real estate search?</p>
        <p>Please help us with your search by answering these <i>optional</i> questions.</p>
        <p v-if="!allAgents.length">Loading...</p>
        <p>Are you already working with an agent? If so, please select them</p>
        <select name="" v-model="agentEmail" class="hl-select wider">
          <option :value="null">I am not</option>
          <option v-for="agent of allAgents" :value="agent.email">{{agent.user_name}}</option>
        </select>
        <p>I am a</p>
        <div class="checkbox-items">
          <div>
            <input type="checkbox" v-model="types" value="Buyer" />
            <span>Buyer</span>
          </div>
          <div>
            <input type="checkbox" v-model="types" value="First Time Home Buyer" />
            <span>First Time Home Buyer</span>
          </div>
          <div>
            <input type="checkbox" v-model="types" value="Homeowner" />
            <span>Homeowner</span>
          </div>
          <div>
            <input type="checkbox" v-model="types" value="Investor" />
            <span>Investor</span>
          </div>
          <div>
            <input type="checkbox" v-model="types" value="Seller" />
            <span>Seller</span>
          </div>
          <div>
            <input type="checkbox" v-model="types" value="Renter" />
            <span>Renter</span>
          </div>
          <div>
            <input type="checkbox" v-model="types" value="Military Service Member" />
            <span>Military Service Member</span>
          </div>
        </div>
        <div class="row">
          <div class="w-50">
            <label for="">Your Timeframe</label>
            <select name="" v-model="timeframe" class="hl-select">
              <option :value="null">Select</option>
              <option value="Immediate">Immediate</option>
              <option value="3-6 Months">3-6 months</option>
              <option value="6 - 12 Months">6-12 months</option>
              <option value="Over a Year">Over a year from now</option>
            </select>
          </div><div class="w-50">
          <label for="">Preferred Language</label>
          <select name="" v-model="language" id="" class="hl-select">
            <option :value="null">Select</option>
            <option value="English">English</option>
            <option value="Japanese">Japanese</option>
            <option value="Chinese">Chinese</option>
          </select>
        </div>
        </div>

        <div class="row">
          <label for="">Enter any questions you would like answered:</label><br />
        </div>
        <div>
        <input type="text" class="hl-input" placeholder="Your question(s)" v-model="questions" />
        </div>
        <div class="row footer">
          <div class="w-50"><a class="hl-button" @click="send">Submit</a></div>
          <div class="w-50"><a @click="show = false;">Skip</a></div>
        </div>
        </div>
      </div>
  </modal>
  </div>
</template>

<script>
import axios from 'axios';
import {serialize} from "object-to-formdata";
import Modal from './Modal';
export default {
  components:{
    Modal,
  },
  data(){
    return {
      opened:false,
      types:[],
      allAgents:[],
      show:false,
      timeframe:null,
      questions:'',
      agentEmail:null,
      language:null,
      sent:false,
    }
  },
  async mounted(){
    if(window.location.href.includes('registration-welcome')){
      await this.getAgents();
      this.show = true;
    }
  },
  methods:{
    async getAgents(){
      const {data} = await axios.get('https://www.hawaiilife.com/api/v1/advisors/all');
      this.allAgents = data;
    },
    closeModal(){
      this.open = false;
    },
    async send(){
      let message = '';
      if(this.types && this.types.length){
      message += 'Type: \n';
        for(var checked of this.types){
          message += ' - ' + checked + '\n';
      }
      }
      if(this.timeframe){
        message += 'Timeframe: '  + this.timeframe + '\n';
      }
      if(this.language){
        message += 'Language: ' + this.language + '\n';
      }

      if(this.questions){
        message += 'Question: \n';
        message += this.questions;
      }

      message += '\n ==== \n';
      const data = {
        contact:{
          Email:this.email,
          FirstName:window.config.firstName ? window.config.firstName : 'Unknown',
          LastName:window.config.lastName ? window.config.lastName : 'Unknown',
          Leadsource: 'HL Web - Registration',
        },
        request:{
          Inquiry_Message__c: message,
          web_source__c:window.location.href,
          Listing_Agent_Email__c:this.agentEmail ? this.agentEmail : null,
          LeadSource__c: 'HL Web - Supplemental Info'
        }
      }

      const bodyFormData = serialize(data);
       const  d = await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',bodyFormData,{
         headers: {
           'content-type': 'application/x-www-form-urlencoded'
         }
       });
       this.show = false;
    }
  },
  computed:{
    email(){
      if(window.config && window.config.userId) return window.config.userId;
    }

  },
  name: "AfterRegistration"
}
</script>

<style lang="scss" scoped>
div.registration-complete{
  color:#222222;
  font-family: "Whitney A","Whitney B",sans-serif;
  min-height:300px;
  margin-top:-10px;
  background-color:white;
  box-sizing:content-box;
  max-width:600px;
  padding:20px;
  @media(max-width:520px){
  padding:5px;
  }
  select{
    font-family: "Whitney A","Whitney B",sans-serif;
  }
  @media(max-width:520px){
    max-width:100vw;
  }
  .hl-select{
    height:42px;
    font-size:15px;
    padding-right:0px;
    &.wider{
      width:50%;
    }
  }
  div.checkbox-items{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    margin-left:-20px;
    @media(max-width:520px){
      margin-left:-5px;
    }
    div{
      width:50%;
      box-sizing:border-box;
      padding:20px;
      font-size:17px;
      display:flex;
      align-items: center;
      input[type="checkbox"]{
        width:25px;
        height:25px;
        margin-right:10px;
        border:1px #333333 solid !important;
      }
      @media(max-width:520px){
        padding:5px;
        font-size:14px;
        input[type="checkbox"]{
          width:15px !important;
          height:15px !important;
          margin-right:5px;
        }

      }




    }
  }
  div.row{
    display:flex;
    flex-direction: row;
    padding-top:10px;
    padding-bottom:10px;
    justify-content: space-between;
  }
  div.w-50{
    width:48%;
    box-sizing: content-box;
    label{
      display:block;
      width:100%;
    }
    select{
      width:100%;
      @media(max-width:520px){
        max-width:85%;
      }
    }
  }
  div.row label{
    width:100%;
    display:block;
  }
  div.row input{
    width:100%;
    clear:both;
  }

  input.hl-input{
    box-sizing: content-box;
    max-width:80%;
  }

  .footer{
    align-items: center;
    a{
      color:#12b5ea;
      text-decoration: underline;
      &:hover{
        cursor:pointer;
      }
      &.hl-button{
        text-decoration: none;
      }
    }
  }




}

</style>
