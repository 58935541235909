<template>
  <div class="matterport-embedded">
    <img v-if="imageUrl && imageUrl.length" :src="imageUrl" class="matterport-thumbnail" alt="" @click="showVirtualTour = true;" />
    <a href="" v-if="!imageUrl || !imageUrl.length" @click.prevent="showVirtualTour = true;">{{linkText}}</a>
    <div class="modal" v-if="showVirtualTour">
    <div class="matterport-wrapper">
      <svg class="close-matterport-modal" data-mls="990000" @click="showVirtualTour = false;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
      <iframe width="100%" height="400px" :src="'https://my.matterport.com/show/?m=' + this.matterportSource" frameborder="0" allowfullscreen="" allow="xr-spatial-tracking"></iframe>
      <div class="logo-and-text">
        <img src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/logo.svg.png" alt="Hawaii life real estate brokers" />
        <h2>Love this property? <a @click="openCEWidget">Find out more.</a></h2>
      </div>

    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatterportEmbed",
  props: {
    'imageUrl': {type: String,default:''},
    'matterportSource': {type: String},
    'linkText':{type:String,default:''},
    'mls':{type:String,default:''},
    'address':{type:String,default:""}
  },
  mounted(){
      },
  methods:{
    openCEWidget(){
      const data = 'https://my.matterport.com/show/?m=' + this.matterportSource;
      if(window.HL_TRIGGER_LIVE_CHAT_WITH_MESSAGE){
        window.HL_TRIGGER_LIVE_CHAT_WITH_MESSAGE('', this.mls, 'viewed: ' + this.address);
      }
    },
  },
  data() {
    return {
      showVirtualTour: false,
    }
  }
}
</script>

<style lang="scss">
.matterport-thumbnail{
  max-width:100%;

}
.modal{
  position:fixed;
  top:0;
  left:0;
  min-height:100vh;
  min-width:100vw;
  z-index:500;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(10,10,10,0.8);
  .matterport-wrapper{
    position:relative;
    margin:0 auto;
    min-width:400px;
    width:80%;
    @media(max-width:520px){
      width:90%;
    }
    .close-matterport-modal{
      &:hover{
        cursor:pointer;
      }
      width:25px;
      height:25px;
      fill:white;
      position:absolute;
      top:-35px;
      right:-35px;
      @media(max-width:520px){
        position:fixed;
        right:10px;
        top:10px;
        z-index:3000;
      }
    }
  }

  .logo-and-text{
    padding-top:10px;
    display:flex;
    flex-direction: row;
    align-items: center;
    @media(max-width:520px){
      padding-left:20px;
      padding-top:15px;
    }
    img{
      height:70px;
      width:70px;
      @media(max-width: 520px){
        height:40px;
        width:40px;
      }
    }
    h2{
      @media(max-width:520px){
        font-size:14px;
      }
      font-size:17px;
      padding-left:10px;
      color:white;
      font-family: "Helvetica Neue",sans-serif;
      font-weight:200;
      margin-top:5px;
      margin-bottom:0px !important;
      a{
        color:white;
        font-weight:400;
        text-decoration: none;
        text-underline-style: dashed-heavy;
        &:hover{
          cursor:pointer;
        }
      }
    }
  }
}

</style>
