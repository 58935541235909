<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="rotated arrow-right"  width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
</template>

<script>
export default {
  name: "ArrowRight"
}
</script>

<style lang="scss" scoped>
svg{
  &.rotated{
    transform:rotate(-90deg);
   }
}
</style>
