import axios from 'axios';

export default class Posthog{
    constructor(){
        this.api_host =  'https://us.i.posthog.com/i/v0/e/';
        this.api_key = 'phc_4Hokg9ccYRemG73mElezBfPJFzHSqE28ICsCRXhDsB3';
        this.capture_pageview = true
        this.project_id = 112620;
        this.eventData = {};
    }
    async capture(eventName = null,additionalData = {}){
        let current_url = null;
        let current_page = null;

        //if(process.browser && window){
        //    current_url = window.location.href;
            current_page = window.location.pathname;
        //}else{
            if(additionalData.pba__Request__c && additionalData.pba__Request__c.web_source__c){} {
                current_url = additionalData.web_source__c ? additionalData.web_source__c : null;
        }
        const payload = {
            api_key: this.api_key,
            event: eventName,
            $project_id: this.project_id,
            distinct_id: eventName + Math.floor(new Date().getTime()),
            $current_url: current_url,
            $current_page: current_page,
            properties: {
                ...this.eventData,...additionalData,
            }
        };
        try {
            const response = await axios.post(this.api_host, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log(response.data);
        } catch (error) {
            console.error("Error sending event:", error.response ? error.response.data : error.message);
        }
    }
}
