<template>
  <div class="cta-modal-overlay" v-if="showCTAModal" @click="clickOnOverlay">
    <div class="cta-bg-layer"></div>
    <div class="cta-body" v-if="activeTemplate">
    <div style="position:relative;">
    <div class="cta-form" v-if="!submittedToHL">
      <div :style="getOrderFor('promo-graphic')" class="promo-graphic">
        <div>
          <img :src="activeTemplate.img" alt="">
          <p v-if="activeTemplate.displayCallOrText" style="text-align: center" class="call-or-text black">We're available now to discuss your needs. <br /><a href="tel:800-370-3848" style="color:black !important;">Call 800-370-3848</a></p>
        </div>
      </div>
      <div :style="getOrderFor('form-wrapper')" class="form-wrapper form-wrapper-cta">
        <div class="cta-form-body-copy">
          <svg @click="hideForm" width="14" height="14" class="hide-modal" viewBox="0 0 14 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black"/>
          </svg>
          <h3 class="cta-h3">{{activeTemplate.header}}</h3>
          <h4 class="cta-h4">{{activeTemplate.subheader}}</h4>
          <p v-if="activeTemplate.html" v-html="activeTemplate.html"></p>
          <input type="hidden" :value="activeTemplate.downloadURL" />
        </div>
        <div class="rel">
          <label for=""><span class="required">*</span> Name</label>
          <input :class="{error: errorForm && errorName}" v-model="lead.fullName" type="text" />
        </div>
        <div class="rel">
          <label for=""><span class="required">*</span>Email</label>
          <input :class="{error: errorForm && errorEmail}" v-model="lead.email" type="text" required />
        </div>
        <div class="rel">
          <label><span class="required">*</span> Phone</label>
          <input :class="{error: errorForm && errorPhone}" v-model="lead.phone" type="text" />
        </div>
        <div class="rel" v-if="!hideAddress">
          <label for=""><span class="required">*</span> Address</label>
          <input :class="{error: errorForm && errorAddress}" v-model="lead.address" type="text" />
        </div>
        <div class="rel" v-if="!hideComments">
          <label for="">Comments</label>
          <textarea v-model="lead.comments" type="textarea"></textarea>
        </div>
        <div class="rel">
          <a @click.stop="sendLead" class="button hl-button" id="submit-cma">{{activeTemplate.submitText}}</a>
        </div>
      </div>
    </div>
      <div class="policies" v-if="!submittedToHL">
        © Hawai‘i Life Real Estate Brokers  |  <a href="https://www.hawaiilife.com/privacy">Policies</a>
      </div>
    </div>
    <div class="cta-success" v-if="submittedToHL">
      <h2>{{activeTemplate.successHeader}}</h2>
      <p>{{activeTemplate.successSubheader}}</p>
    </div>
  </div>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import validateEmail from "@/helpers/validateEmail";
import validatePhone from "@/helpers/validatePhone";
import axios from "axios";
import {serialize} from "object-to-formdata";
export default {
  name: "CTAModal",
  props:['template'],
  components:{
    Modal
  },
  watch: {
    template: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      this.$forceUpdate();
    }
  },
  methods:{
    clickOnOverlay(){
      if(this.submittedToHL) this.hideForm();
    },
    hideForm(){
      this.$store.commit('updateShowCTAModal',false);
      this.sellconfirm = false;
      this.submittedToHL = false;
      this.$forceUpdate();
      //this.$destroy();
      // remove the element from the DOM
      //this.$el.parentNode.removeChild(this.$el);
    },
    getOrderFor(order){
    console.log('in order:',order,this.activeTemplate);
      if(order == 'promo-graphic') {
        return {
          order: this.activeTemplate.order.promoGraphicPosition == 'left' ? 1 : 2
        }
      }
      if(order == 'form-wrapper') {
        return {
          order: this.activeTemplate.order.formPosition === 'right' ? 2 : 1
        }
      }

    },
    async sendLead(){
      if(!this.lead.email || !validateEmail(this.lead.email)){
        this.errorEmail = true;
      }else{
        this.errorEmail = false;
      }

      if(!this.lead.fullName){
        this.errorName = true;
      }else{
        this.errorName = false;
      }
      if(!this.lead.phone || !validatePhone(this.lead.phone)){
        this.errorPhone = true;
      }else{
        this.errorPhone = false;
      }

      if(!this.lead.address){
        this.errorAddress = true;
      }else{
        this.errorAddress = false;
      }
      if(this.hideAddress){
        this.errorAddress = false;
      }

      if(this.errorEmail || this.errorName || this.errorAddress || this.errorPhone){
        this.errorForm = true;
        return;
      }
      this.submittedToHL = true;
      this.prospect = {
        "contact": {
          "FirstName": this.lead.fullName.split(" ")[0],
          "LastName": this.lead.fullName.split(" ")[1] ? this.lead.fullName.split(" ")[1] : '(unknown)',
          "Email": this.lead.email,
          "LeadSource": this.activeTemplate.leadSource,
          "Phone":this.lead.phone,
          //automatically removed MobilePhone Mapping
          //"MobilePhone":this.lead.phone,
        },
      };

      this.request = {
        "LeadSource__c": this.activeTemplate.leadSource,
        "web_source__c": window.location.href.substring(0,200),
        "Inquiry_Message__c": this.activeTemplate.defaultNotes && !this.lead.comments ? this.activeTemplate.defaultNotes : this.lead.comments,
      };

      let leadData = {
        contact:this.prospect.contact,
        request:this.request,
      };
      const leadComment = this.request.Inquiry_Message__c ? this.request.Inquiry_Message__c + " \n" : '';
      leadData.request.Inquiry_Message__c = leadComment + "Phone: " + this.prospect.contact.Phone;
      const ld = serialize(leadData);
      const result = await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',ld, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
      });
      this.lead.email = null;
      this.lead.phone = null;
      this.lead.fullName = null;
      this.submittedToHL = true;
      this.errorForm = false;
      console.log(this.activeTemplate);
      if(this.activeTemplate.downloadURL){
        setTimeout(() => {
          window.open(this.activeTemplate.downloadURL, '_blank');
          this.submittedToHL = false;
          this.hideForm();
        },500);
      }else{
        this.submittedToHL = true;
      }
    },
  },
  data() {
    return {
      lead:{
        fullName:null,
        email: null,
        phone: null,
        address: null,
        comments: null
      },
      errorName:false,
      errorEmail:false,
      errorPhone:false,
      errorAddress:false,
      errorComments:false,
      errorForm:false,
      submitted:false,
      submittedToHL:false,
    }
  },
  mounted(){
    console.log('we were mounted');
  },
  beforeUpdate() {
    console.log('we were updated');
    this.$forceUpdate();
  },
  computed:{
    hideAddress(){
      return this.activeTemplate.hiddenFields.includes('address');
    },
    showCTAModal(){
      return this.$store.state.showCTAModal
    },
    hideComments(){
      return this.activeTemplate.hiddenFields.includes('comments');
    },
    temp(){
      return this.template
    },
    activeTemplate(){
      return this.$store.state.activeCTATemplate;
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/cta.scss';
h2{

}
h3{

}
h4{
  line-height:27px;
}



p{
  font-family:"Whitney A","Whitney B",sans-serif;
  margin-left:0px;
  line-height:24px;
}



.cta-form{
  transition: max-width 1s ease-in-out;
  max-width:60vw;
  @media screen and (max-width: 1400px) {
    max-width:75vw;
  }
  @media screen and (max-width: 768px) {
    max-width:100vw;
  }
  background-color: white;
  display:flex;
  flex-direction:row;
  > div {
    width:50%;
    &.form-wrapper-cta{
      width:50%;
      @media screen and (max-width: 768px) {
        width:100%;
        padding-left:20px;
      }
    }
    img{
      max-width:100%;
    }
  }
}


.form-wrapper{
  font-family: "Whitney A", "Whitney B", sans-serif;
  background-color: white;
  display:flex;
  flex-direction: column;
  padding:10px;
  padding-top:30px;
  padding-left:20px;
  padding-right:20px;
  input, textarea{
    font-size: 17px;
    height:40px;
    padding-left:10px;
    color:#777777;
    box-sizing: content-box;
    background-color: #fafafa;
    border:none;
    padding-top:20px;
    padding-right:-10px;
    font-size:15px;
    width:100%;
    &.error{
      border:1px rgba(255,0,0,0.8) solid;
      outline:none !important;
    }
    font-family: sans-serif;
    border: 1px solid #EEEEEE;
    /* sm */

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  }
  textarea{
    padding:10px;
    padding-top:20px;
    padding-right:2px;
  }
  label, h2, h3, p{
    color:black !important;
  }

  .hl-button{
    margin-top:20px;
  }
}

:root{
  --hvs-input-background: #ffffff;
  --hvs-input-container-border-color:rgba(255,255,255,0.0);
  --hvs-input-ring-color: rgba(255,255,255,0.0);
  --hvs-input-font-size: 14px;
}

#hvs-input{
  border-radius:0px !important;

}

.hvs-border-wrapper{
  background-color: white;
  padding:5px;
}

svg:hover{
  cursor:pointer;
}
@media(max-width: 768px){
  .promo-graphic{
    display:none;
  }
  .form-wrapper{
    max-width:90vw;
    padding-left:15px;
    padding-right:10px;
  }
  .cta-form-body-copy svg.close-modal{
    right:-20px;
  }

}

span.required{
  color:red !important;
}
.cta-text{
  font-family: "Whitney A", "Whitney B", sans-serif;
  color:white;

}
#submit-cma.hl-button{
  width:100%;
  max-width: 100%;
  padding-top:20px;
  padding-bottom:20px;
  padding-left:0;
  padding-right:0;
  padding-left:10px;
  padding-right:5px;
  font-weight:300;
  margin-top:0px;
  box-shadow: 0 2px 8px rgba(50, 50, 93, 0.2);
  &:hover{
    text-decoration: none;
  }
}
.cta-form-body-copy{

  .hide-modal{
    top:20px;
    right:20px;
    position:absolute;
  }

}

.form-wrapper-cta{
  padding-left:30px;
}

.promo-graphic{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-content: center;
  @media screen and (max-width: 768px) {
    display:none;
  }
}

.never-share-info{
  font-size:12px;
  color:#737373;
  line-height:17px;
}

div.rel{
  width:calc(100% - 30px);
  position:relative;
  margin-bottom:20px;
  label{
    position:absolute;
    font-weight:100;
    left:10px;
    top:10px !important;
    font-size:14px;
    color:rgba(115, 115, 115, 1) !important;
  }
}

div.policies{
  position:absolute;
  bottom:15px;
  width:100%;
  text-align: center;
  font-family: "Whitney A", "Whitney B", sans-serif;
  font-size:13px;
  a{
    color:black;

  }

}

.cta-form{
  padding-left:40px;
  padding-bottom:30px;

  @media screen and (max-width: 1400px) {
    padding-left:40px;

    .cta-form-body-copy{
      max-width:70vw;
    }

    .promo-graphic{
      max-width:30vw;
    }

  }
  @media screen and (max-width:800px){
    padding-left:0px;
  }

}

h3.cta-h3{
  font-size: 30px;
  font-weight:600;
}
h4.cta-h4{
  font-size:22px;
  font-family: "Whitney A", "Whitney B", sans-serif;
}

.cta-modal-overlay{
  position:fixed;
  z-index:5000;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  .cta-bg-layer{
    background-color: rgba(100,100,100,0.5);
    z-index:4999;
    height:100vh;
    width:100vw;
    backdrop-filter: blur(6px);
    transform: translateZ(0px);
  }
  .cta-body{
    z-index:5000;
    position:fixed;
    top:0;
    display:flex;
    align-items:center;
    justify-content: center;
    height:100vh;
    width:100vw;
    margin-left:auto;
    box-shadow: 0 2px 8px rgba(50, 50, 93, 0.2);
    .inner{
    }
  }
}






</style>
