<template>
  <div class="hl-modal" :class="getClasses" :style="style" @click.stop="closeModal">
    <div class="inner" :class="modalClass ? modalClass : 'small'" @click.stop="() => {}">
      <close-x v-if="!noX" :close="closeModal"></close-x>
      <div>
        <div>
          <slot>
          </slot>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CloseX from './close-x';
export default {
  props:['closeModal','modalLevelsOpen','noX','modalClass','zIndex','opaque','full','additionalClass'],
  components:{
    CloseX
  },
  name: "Modal",
  computed:{
    getClasses(){
      var cl = {}
      if(this.opaque) cl['opaque'] = true;
      if(this.additionalClass) cl[this.additionalClass] = true;
      return cl;
    },
    style:function(){
      return {'z-index': this.$props.zIndex ? this.$props.zIndex : 7000 };
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/mixins/no-scrollbar';



</style>
