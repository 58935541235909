<template>
    <div class="ce-wrapper-modal" v-if="showRequestCallback">
        <div class="ce-component-inline-centered">
            <a class="close-x-new" @click="resetData">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">
            <polygon fill="black" points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
            </svg>
            </a>
            <div class="inner">
                <div class="success" v-if="showSuccess" @click="hideSuccessAndActionItems()">
                    <div class="inner-success">
                        <h2>Mahalo!</h2>
                        <p v-if="!customSuccessMessage">One of our experts will contact you shortly.</p>
                        <p v-if="customSuccessMessage">{{customSuccessMessage}}</p>
                    </div>
                </div>
                <div class="contact-form-inner shadowed">
                    <div class="cf-body">
                        <div>
                            <a href="tel:808-344-1480" v-if="!scheduleCall" class="hl-button centered">Call Now!</a>
                            <h2 class="center-text" v-if="!scheduleCall" style="color:black;">or</h2>
                            <a style="width:100%; text-align: center; margin:0 auto; color:black;" class="schedule-call hover-pointer" v-if="!scheduleCall" @click.prevent="scheduleCall = true;"><span style="display:inline-block;"><span class="underline-blue dashed">Schedule</span> a call from us</span>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 96 96" style="enable-background:new 0 0 96 96; width:25px;" xml:space="preserve">
                                    <g id="XMLID_2_">
	<path id="XMLID_19_" class="st0" d="M24.7,73.4h9.5v-9.5h-9.5V73.4z M36.4,73.4h10.6v-9.5H36.4V73.4z M24.7,61.8h9.5V51.2h-9.5
		V61.8z M36.4,61.8h10.6V51.2H36.4V61.8z M24.7,49.1h9.5v-9.5h-9.5V49.1z M49.1,73.4h10.6v-9.5H49.1V73.4z M36.4,49.1h10.6v-9.5
		H36.4V49.1z M61.8,73.4h9.5v-9.5h-9.5V73.4z M49.1,61.8h10.6V51.2H49.1V61.8z M37.4,33.2v-9.5c0-0.3-0.1-0.5-0.3-0.7
		c-0.2-0.2-0.5-0.3-0.7-0.3h-2.1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.7v9.5c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h2.1
		c0.3,0,0.5-0.1,0.7-0.3C37.3,33.7,37.4,33.5,37.4,33.2z M61.8,61.8h9.5V51.2h-9.5V61.8z M49.1,49.1h10.6v-9.5H49.1V49.1z
		 M61.8,49.1h9.5v-9.5h-9.5V49.1z M62.8,33.2v-9.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3h-2.1c-0.3,0-0.5,0.1-0.7,0.3
		c-0.2,0.2-0.3,0.5-0.3,0.7v9.5c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h2.1c0.3,0,0.5-0.1,0.7-0.3S62.8,33.5,62.8,33.2z M75.5,31.1
		v42.3c0,1.1-0.4,2.1-1.3,3s-1.8,1.3-3,1.3H24.7c-1.1,0-2.1-0.4-3-1.3c-0.8-0.8-1.3-1.8-1.3-3V31.1c0-1.1,0.4-2.1,1.3-3
		c0.8-0.8,1.8-1.3,3-1.3H29v-3.2c0-1.5,0.5-2.7,1.6-3.7c1-1,2.3-1.6,3.7-1.6h2.1c1.5,0,2.7,0.5,3.7,1.6c1,1,1.6,2.3,1.6,3.7v3.2
		h12.7v-3.2c0-1.5,0.5-2.7,1.6-3.7c1-1,2.3-1.6,3.7-1.6h2.1c1.5,0,2.7,0.5,3.7,1.6c1,1,1.6,2.3,1.6,3.7v3.2h4.2c1.1,0,2.1,0.4,3,1.3
		C75.1,28.9,75.5,29.9,75.5,31.1z"/>
</g>
</svg>
                            </a>
                            <div v-if="scheduleCall">
                                <h2 class="black-text">Schedule a callback</h2>
                                <div style="margin-bottom:20px;">
                                    <input type="text" class="hl-input" :class="{error:phoneNumberError && submitted}" v-model="phoneNumber" placeholder="Enter Phone Number" />
                                </div>
                                <div style="height:60px;" class="date-picker-wrapper">
                                    <div style="display:none">{{callDate}}</div>
                                    <calendar v-model="callDate" :minDate="today"  format="ddd, MMM D" color="#12b5ea"></calendar>
                                </div>
                                <select class="hl-select" :class="{error:timeError && submitted}" v-model="callTime" style="margin-top:10px;">
                                    <option :value="null" disabled>When</option>
                                    <option v-for="(time,idx) in times" v-if="showTime(idx)" :value="idx">{{time}}</option>
                                </select>
                                <a @click="scheduleCallback" v-if="callTime && callDate" class="hl-button centered">Schedule Call</a>
                            </div>
                        </div><!--end of show call body -->
                    </div><!--end of show call body -->
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
    const {DateTime} = require('luxon');
    import { VueDatePicker } from '@mathieustan/vue-datepicker';
    import axios from 'axios';
    const apiBase = window.location && window.location.href.includes('localhost') ? 'http://localhost:3000/api/lead/create' : 'https://platform.hawaiilife.com/api/lead/create';
    export default {
        name: "CEComponent",
        props:['innerHeadline'],
        components:{
            Calendar:VueDatePicker,
        },
        mounted(){
            this.attachEvents();
            //
        },
        methods:{
            attachEvents(){

                    window.HL_ON_SHOW_CALL_INLINE = () => {
                        this.showRequestCallback = true;
                    }
            },
            closeRequestCallback(){
            },

            hideSuccessAndActionItems(){
                this.showSuccess = false;
                this.showActionItems = false;
            },
            async sendInquiry(sendData){
                                const {data} = await axios.post(apiBase,sendData);
                return data;
            },
            showAndCancelHideCallbackOverride(){
                this.showActionItems = true;
                this.callBackHideCancelled = true;
            },
            async scheduleCallback(){
                const date = new Date();
                const now = DateTime.fromJSDate(new Date(),{zone:'local'});
                const d = DateTime.fromJSDate(new Date(),{zone:'Pacific/Honolulu'});
                this.submitted = true;
                const result = this.validateCallbackForm();
                if(!result) return;
                const hours = now.hour - d.hour;
                const minutes = now.minute - d.minute;
                let dateToCall = this.callDate;
                if(typeof dateToCall === 'string'){
                    dateToCall = DateTime.fromFormat(this.callDate,'y-MM-dd').toJSDate();
                    dateToCall.setHours(0,0);
                }
                const time = DateTime.fromJSDate(dateToCall);
                const time2 = time.set({hours:0,minutes:0});
                const hour = Math.floor(this.callTime / 60);
                const minute = this.callTime % 60;
                const theirtime = time2.plus({hour,minute});
                const change = time2.plus({hours: hour - hours,minutes: minute - minutes});
                this.showSuccess = true;
                this.customSuccessMessage = `We will call you at ${theirtime.toFormat('hh:mm a')} on ${theirtime.toFormat('M/d')}`;
                this.customSuccessMessage += ` - Hawaii Time: ${change.toFormat('M/d h:mm a')}`;
                const message = `Call them at ${theirtime.toFormat('hh:mm a')} on ${theirtime.toFormat('M/d')}- Hawaii Time: ${change.toFormat('M/d h:mm a')}`;

                const inquiryData =  {
                    email:null,
                    phone:this.phoneNumber,
                    notes:message,
                    contactReason:'Other',
                    requestCallBack:1,
                    callTimeHST:change,
                    theirTime:theirtime,
                    callback:true,
                };
                this.submitted = false;
                await this.sendInquiry(inquiryData);
                this.resetData();
            },
            resetData(){
                this.showRequestCallback = false;
            },
            showTime(minutes){
                const now =  DateTime.fromJSDate(new Date());
                const selected = DateTime.fromJSDate(this.callDate);
                if(selected.diffNow().days === 0){
                    const minutesNow = (now.hour * 60) + now.minute;
                    if(minutesNow + 30 > minutes){
                        return false;
                    }
                }
                //todo, compare

                return true;
            },
            validateCallbackForm(){
                if(this.timeError) return false;
                if(this.phoneNumberError) return false;
                return true;
            }
        },
        data(){
            let now = new Date();
            return {
                inquiryData:{
                    name:null,
                    email:null,
                    phone:null,
                    contactReason: null,
                    notes:''
                },
                windowWidth:null,
                callDate:now,
                today:new Date(),
                showCallBody:true,
                phoneNumber:null,
                callTime:null,
                tomorrow:now,
                fromEmail:null,
                scheduleCall:true,
                showSuccess:false,
                customSuccessMessage:null,
                submitted:false,
                inquiryMessage:null,
                showRequestCallback:false,
                callBackHideCancelled:false,
                times:{
                    480:'8am',
                    510:'8:30am',
                    540:'9am',
                    570:'9:30am',
                    600:'10am',
                    630:'10:30am',
                    660:'11am',
                    690:'11:30am',
                    720:'12pm',
                    750:'12:30pm',
                    780:'1pm',
                    810:'1:30pm',
                    840:'2pm',
                    870:'2:30pm',
                    900:'3pm',
                    930:'3:30pm',
                    960:'4pm',
                    990:'4:30pm',
                    1020:'5pm',
                    1050:'5:30pm',
                    1080:'6pm',
                    1110:'6:30pm',
                    1140:'7pm',
                    1170:'7:30pm',
                    1200:'8pm',
                    1230:'8:30pm',
                    1260:'9pm',
                    1290:'9:30pm',
                    1320:'10pm',
                }
            }
        },
        computed:{
            usOrMe(){
                return 'Us';
            },
            phoneNumberError(){
                if(this.phoneNumber) {
                    const match = this.phoneNumber.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                    if(!match) return true;
                }
                if(!this.phoneNumber) return true;
                return false;
            },
            error(){
                return this.phoneNumberError || this.timeError;
            },
            timeError(){
                if(!this.callTime  || !this.phoneNumber) return true;
                if(!this.callTime && this.phoneNumber) return true;
                return false;
            },

        }
    }
</script>

<style lang="scss">
    .ce-wrapper-modal{
        position:fixed;
        z-index:3000;
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
        width:100vw;
        height:100%;
        top:0px;
        left:0px;
        display:flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top:30px;
        justify-content: center;
    }

    .ce-component-inline-centered select{
        min-height:40px;
        -webkit-appearance: none;
        font: 100 14px arial;
    }


    body.embedded-chat{
        .datepicker{
            font-family: "Whitney A", "Whitney B", sans-serif;
        }
        span.datepicker-header__year-button{
            display:none !important;
        }
        .ce-wrapper-modal{
            display:flex;
            z-index:1000;
            &.inner{
                position:relative;
                height:100%;
                width:100%;
                margin:5px;
            }
        }

        .ce-component-inline-centered{
            position:relative;
            font-family: "Whitney A", "Whitney B", sans-serif;
            max-width:90vw;
            a.close-x-new{
                position:absolute;
                z-index:6000;
                width:16px;
                height:16px;
                right:10px;
                top:10px;
                svg{
                }
                &:hover{
                    cursor:pointer;
                }
            }

            div.contact-live-chat-wrapper{
                display:flex;
                flex-direction: row;
            }

            div.contact-form-inner{
                min-width:375px;
                h2.black{
                    color:black;
                }
                label.hl-label{
                    color:black;
                    font-size:14px;
                }
                input[type="text"].hl-input{
                    padding:1px 35px 1px 10px;
                    &:focus{
                        margin-bottom:10px;
                    }
                }
                font-family: "Whitney A", "Whitney B", sans-serif;
                max-width:320px;
                min-height:300px;
                background-color: rgba(0,0,0,0.0);
                border-radius:20px;
                .cf-body{
                    position:relative;
                    padding:20px;
                    background-color: white !important;
                    min-height:10px;
                    box-sizing: border-box;
                    @media(max-width: 500px){
                        max-width:90%;
                        .hl-button{
                            width:90%;
                        }
                    }
                    padding-bottom:40px;
                    -webkit-box-shadow: 5px 5px 17px 5px rgba(0,0,0,0.27);
                    box-shadow: 5px 5px 17px 5px rgba(0,0,0,0.27);
                    .hl-button{
                        margin-top:20px;
                    }
                    .datepicker__wrapper{
                        .datepicker__input{
                            border:1px #f3f3f3 solid;
                            padding:5px;
                            border-radius:5px;
                            color:#222222 !important;
                        }
                    }
                }
            }

            select{
                min-width:50%;
                @media(max-width: 500px) {
                    font-size: 16px;
                }
                font-weight:400;
                option {
                    size:14px;
                }
                &.error{
                    outline:1px red solid;
                }
            }

            div.success{
                position:fixed;
                top:0;
                left:0;
                height:100vh;
                width:100vw;
                display:flex;
                background: radial-gradient(rgba(255,255,255,0.9),rgba(255,255,255,0.8),rgba(255,255,255,0.6),rgba(255,255,255,0.0));
                display:flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size:32px;
                color:black;
                transition: visibility 0s, opacity 0.5s linear;
                h2{
                    font-size:32px;
                }
                p{
                    font-size:20px;
                }
                .inner-success{
                    width:60%;
                    margin:0 auto;
                    box-sizing: border-box;
                }
            }



        }

        button.submitButton.uiButton--default.uiButton.embeddedServiceSidebarButton{
            border-radius: 0;
            font-family: "Whitney B","Whitney A";
            font-weight:700;
            font-size:18px;
            &:hover{
                background-color: #665726;
            }
        }

        &.embedded-chat-enabled .embeddedServiceSidebar{
            @media only screen {
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
        .layout-docked .dockableContainer{
            @media(min-width:500px){
                margin-bottom:20px;
            }
        }

        .menu__content.menu__content--active.menu__content--fixed{
            z-index:10000 !important;
        }
        .st0{fill:#ffffff;}

        .hl-button.smaller-text span{
            font-size:12px;
            padding-top:10px;
        }
        a.schedule-call{
            color: rgb(34, 34, 34);
            display:block;
            margin:0 auto;
            font-size:16px !important;
            position:relative;
            line-height: 35px;
            svg{
                margin-bottom:-7px;
                display:inline-block;
                path{
                    fill:#333;
                }
            }
        }
    }
</style>
