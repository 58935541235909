<template>
    <div>
        <h2 class="loading" v-if="loading">Loading...</h2>
    <div class="hl-web-component map-layout" v-if="mapHTML" v-html="mapHTML">
    </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "RecentActivity",
        data(){
            return {
                loading:true,
                mapHTML:null,
            }
        },
        async mounted(){
            const prs = [
                new Promise((res,rej) => {
                    axios.get('https://www.hawaiilife.com/js_templates/map.html').then(result => {
                        const {data} = result;
                        res(data);
                        this.loading = false;
                        this.mapHTML = data;
                    });
                })
            ]
            await Promise.all(prs);
        }
    }
</script>

<style lang="scss">
    h2.loading{
        color:#f8f8f8;
    }
    .hl-web-component.map-layout{
        font-family:'Whitney Bold','Whitney A','Whitney B',sans-serif;
        font-size:21px;
        .typeahead__primary{
            display:none;
        }
     .hawaii-islands svg text {
         font-weight:700;
         font-size:21px !important;
         a {
             font-size:21px !important;
             font-weight: 600;
             color: #12b5ea !important;
             fill: #12b5ea;
         }
     }
    }
</style>
