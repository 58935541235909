export default (price) => {
    const p = +price;

    if(p >= 1000000){
        return (p / 1000000).toFixed(3).toString() + 'M';
    }
    else{
        return (p / 1000) + 'k';
    }
}
