import { parse } from 'node-html-parser';
export default (htmlData,defaults = {}) => {
    let allPosts = [];
    let mainPost = null;
    let postOffsetStart = 1;
    const result = parse(htmlData);
    const items = result.querySelectorAll('.view.view-blog.view-id-blog article');
    for(var item of items){
        const title = item.querySelector('h3.title').text;
        const image = item.querySelector('img').attributes.src;
        const href = 'https://vacations.hawaiilife.com' + item.querySelector('a').attributes.href;
        const desc = item.querySelector('.field.field-name-body').text;
        const category = '';

        const [day,month,year] = item.querySelector('.field.field-name-post-date').text.split(' ');
        allPosts.push({
            title,
            link: href,
            url: href,
            pubDate:`${month} ${day}, ${year}`,
            desc,
            thumb:image,
            thumbLarge:image,
            category:'Vacation Rentals',
            categoryLink:'Vacation Rentals',
            pubDateFormat: `${month} ${day}, ${year}`,
            author: 'Hawaii Life Vacations Blog',
            //     desc:item.description ? item.description[0] : "",
            //     author:item['dc:creator'] ? item['dc:creator'][0] : null,
            //     pubDateFormat:new Date(item.pubDate).toLocaleDateString('en-US',{month:'long',year:'numeric',day: 'numeric'})
        });
    }
    mainPost = allPosts[0];
    return {allPosts,mainPost,postOffsetStart};
}
