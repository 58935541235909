<template>
    <intersect @enter="intersected">
    <div style="width:100%;" class="hl-web-component mb-20">
        <h2 @click="goToMoreNewWindow" class="top-promo wide" v-if="title && !hidetitle">{{title}}

            <!--<a @click="goToMoreNewWindow" :href="moreUrl" class="more-link arrow right not-mobile"> {{linktext ? linktext : 'See More'}}</a>-->
            <a @click="goToMoreNewWindow" v-if="moretop" :href="moreUrl" class="more-link arrow right not-mobile"> More {{title}}</a>
            <a @click="goToMoreNewWindow" v-if="moretop" :href="moreUrl" class="more-link arrow right mobile-only"> {{'More'}}</a>
        </h2>
        <div class="loading" v-if="loading">We're loading...</div>
        <div class="listing-columns columns" :class="getClassForListings()">
            <div @click="goToListing(listing)" class="listing" v-for="listing in listings">
                <div class="image-bg" :style="getImageStyleForListing(listing)">
                    <div class="listing-label" v-if="getListingLabel(listing) === 'Just Listed'">Just Listed</div>
                    <div class="listing-label" v-if="getListingLabel(listing) === 'New Price'">Reduced Price</div>
                    <div class="listing-label" v-if="getListingLabel(listing) === 'Conservation'">Conservation</div>
                    <div class="listing-label" v-if="getListingLabel(listing) === 'Luxury'">Luxury</div>
                </div>
                <div class="listing__details">
                    <div class="listing__info">
                        <p v-if="listing.address || 1 === 1" style="text-align: left;">
                            {{listingAddress(listing)}}
                        </p>
                        <p style="text-align:left;  text-overflow: ellipsis; overflow:hidden; white-space: nowrap;" v-html="getExcerpt(listing)"></p>
                    </div>
                    <ul class="listing__info listing__info--strong">
                        <li>
                            {{getPrice(listing)}}
                        </li>
                        <li v-if="listing.total_beds">
                            {{listing.total_beds}}
                            <span>bd</span>
                        </li>
                        <li v-if="getSquareFeetForListing(listing) !== 'n/a'">
                            {{lang !== 'ja' ? getSquareFeetForListing(listing) : getSquareMetersForListing(listing)}}
                            <span>{{getMeasurementForArea(listing)}}</span>
                        </li>
                    </ul>
                    <ul class="listing__info small">
                        <li class="fee-simple">{{getTranslation(listing.land_ownership) && getTranslation(listing.land_ownership).length ? getTranslation(listing.land_ownership) + ' | ' : 'n/a | '}}
                        <span v-if="getAgentsForListing(listing).length">
                            {{getAgentsForListing(listing) + ' | '}}
                        </span>
                        <span v-if="listing.days_on_market"> {{listing.days_on_market}} {{listing.days_on_market > 1 ? getTranslation('days') : getTranslation('day')}}</span>
                    </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </intersect>
</template>

<script>
    import axios from 'axios';
    import Intersect from 'vue-intersect';
    import GoogleEvent from "../helpers/GoogleEvent";
    export default {
        name: "Listings",
        props:['query','lang','limit','columns','title','morelink','linktext','moretop','ref','labelfilter','hidetitle','listings_override'],
        components:{
            Intersect
        },
        data(){
            return {
                fetchedListings:null,
                loading:true,
                pricesLoaded:false,
                exchangeRates:null,
            }
        },
        methods:{
            intersected(){
            },
            listingAddress(listing){
              if (listing.address && listing.address.single_line) return listing.address.single_line;
              if(listing.address && listing.address.line_1) return listing.address.line_1;
              return 'see details';
            },
            getImageStyleForListing(listing){
                if(!listing.images || listing.images.length === 0){
                    return {'background-image':`url('https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/missing_image.png')`}
                }
                return {'background-image':`url(${listing.images[0].tile_url.replace('tile','medium')}),url('https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/missing_image.png')`}
            },
            getExcerpt(listing){
                const {i18n} = listing;
                if(i18n.excerpts && i18n.excerpts[this.lang]){
                    let html = i18n.excerpts[this.lang];
                    html = html.split('\n');
                    html = html[0];
                    return html;
                }
            },
            getSquareMetersForListing(listing){
              if(listing.total_floor_area){
                  return Math.floor(+listing.total_floor_area / 27);
              }
              return 'n/a';
            },
            getSquareFeetForListing(listing){
              if(listing.total_floor_area) return listing.total_floor_area;

              return 'n/a';
            },
            getMeasurementForArea(listing){
              if(this.lang === 'ja'){
                  return 'm²';
              }
              return 'sqft';
            },
            getPrice(listing){

                if(this.lang === 'ja' && this.exchangeRates){
                    if(this.lang === 'ja'){
                        return '￥' + ((listing.asking_price * this.exchangeRates.rates.JPY / 1000000) ).toFixed(1) + 'M';
                    }
                }
                return Intl.NumberFormat('en-US',{style:'currency',currency: 'USD',minimumFractionDigits:0}).format(listing.asking_price)
            },
            getClassForListings(){
                if(this.columns && this.columns === '4') return {four:true};
                return {three:true};
            },
            goToListing(listing){
                var url = 'https://www.hawaiilife.com/listings/' + listing.slug;
                if(this.lang === 'ja'){
                    url += '?excerpt=ja';
                }
                if(this.ref){
                    url += url.includes('?') ? '&' + this.ref : '?' + this.ref;
                }
                //GoogleEvent('embedded-component','click','view-property','listing',url);
                var win = window.open(url, '_blank');
                win.focus();
                return false;
            },
            getListingLabel(listing){
                if(this.labelfilter) return this.labelfilter;
                if(listing.reduced) return 'price_reduced';
                if(listing.just_listed) return 'just_listed';
            },
            getTranslation(text){
              if(!this.lang) return text;
              const translations = {
                  'ja':{
                      'Fee Simple': '土地付き',
                      'FS': '土地付き',
                      'Leasehold' : '借地権',
                      'LH' : '借地権',
                      'day':'日',
                      'days':'日'
                  }
              }
              if(translations[this.lang] && translations[this.lang][text]) return translations[this.lang][text];
              return text;


            },
            goToMoreNewWindow(){
                var url = this.moreUrl;
                if(! url) return;
                if(this.ref){
                    url += url.includes('?') ? '&' + this.ref : '?' + this.ref;
                }
                window.location = url;
            },
            getAgentsForListing(listing){
                const {advisors} = listing;
                if(!advisors || !advisors.length) return '';
                return advisors.map(item => {
                    return item.user_name
                }).join(', ');

            }
        },
        async mounted(){
            const result = await this.$store.dispatch('loadQuery',this.query);
            this.fetchedListings = result.slice(0,this.listingLimit);
            if(this.lang){
                const rates = await axios.get('https://api.exchangeratesapi.io/latest');
                this.exchangeRates = rates.data;
            }
            this.loading = false;
            GoogleEvent('embedded-component','click','view-property','listing','scott');
        },
        computed:{
            listings(){
                if(this.fetchedListings && this.fetchedListings.length) return this.fetchedListings;
                if(this.listings_override) return this.listings_override;

            },
            moreUrl(){
                let link = this.morelink;
                if(!link) return '/listings';
                const suffix = link.includes('?') ? '&' : '?';
                return this.morelink + suffix + this.ref;
            },
            listingLimit(){
                if(this.limit) return this.limit;
                return 4;
            }
        }

    }
</script>

<style lang="scss">
    .mb-20{
        margin-bottom:20px;
    }
    .linear-gradient-to-grey{
        padding-bottom: 30px;
        border-bottom:2px #f8f8f8 solid;
        background-image: linear-gradient( -90deg, white, #fdfdfd);
    }
    .image-bg{
        height:200px; background-position: center; background-repeat: no-repeat; background-size:cover;
        width:100%;
        position:relative;
        div.listing-label{
            position:absolute;
            color: #FFFFFF;
            bottom:10px;
            left:0;
            display: inline-block;
            font-family: "Whitney A", "Whitney B", sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
            padding:5px 10px;
            background-color: rgba(100,100,100,0.5);
            &.green{
                background: #47B048;
            }
            &.blue{
                background: #12B5EA;
            }
        }
    }

    div.listing-columns{
        font-family: 'Whitney A','Whitney B',sans-serif;
        width:100%;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &.columns{
            margin-bottom:10px;
        }
        &.three{
            > div {
                width:calc(30% - 5px);
                min-width:300px;
            }
        }
        &.four{
            > div {
                width:calc(25% - 10px);
            }
        }
        .listing{
            padding-bottom:10px;
            &:hover{
                cursor:pointer;
            }
        }
    }

    div.listing__details{
        padding:0 5px;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
        font-weight:300;
    }

    .hl-web-component {
        ul.listing__info {
            list-style-type: none;
            display: block;
            padding-left: 0px !important;
            clear: both;
            font-size: 18px;
            line-height: 1em;
            margin-bottom: 0.2em;
            color: #232323;
            margin: 0;
            padding: 0;
            margin-block-start: none !important;

            &.small {
                font-size: 12px;
                font-weight: 500;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            li {
                float: left;
                display: inline-block;
                padding: 0 6px 0 0;

            }

            li:after {
                content: ' |';

            }

            li:last-child:after {
                display: table;
                content: '';
            }

            &.listing__info--strong {
                font-weight: 700;

                span {
                    font-weight: normal;
                }
            }
        }
    }

    @media (max-width: 600px) {
        div.listing-columns{
            &.three, &.four{
                > div {
                    width: 100%;
                }
            }
        }

        a.more-link{
            &.arrow{
                padding-right:30px;
                margin-top:-10px;
                padding-top:2px;
                padding-bottom:8px;
            }
            &.right {
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                padding-right:20px;
                top:0px;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        h2.top-promo.wide{
        }
        a.more-link {
            &.arrow {
                &.mobile-only {
                    display:block;
                    top:6px;
                    padding-left:5px;
                    text-align: right;
                    padding-right:35px !important;

                }
                &.not-mobile{
                    display:none;
                }
                padding-right: 30px;
                margin-top: -10px;
                padding-top: 2px;
            }
        }

        div.listing__details{
            padding:0px;
        }
    }

    @media only screen and (min-width: 600px) and (max-width: 1024px) {
        div.listing-columns{
            &.three, &.four{
                > div {
                    width: calc(49% - 5px);
                }
            }
        }
    }


</style>
