<template>
  <div class="ds">
    <div class="section-wrapper" :class="{external:external}">
      <div class="section-with-header" :class="{external:external}" v-if="searchHistory && searchHistory.length">
        <div class="header">Previous Searches</div>
        <div class="results-list">
          <div @click="populateSearchTextAndSearch(search)" v-for="(search,idx) in searchHistory"  v-if="showPreviousSearch(search)" class="results-list-item">
            <div class="icon">
              <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 5H11V10L15.28 12.54L16 11.33L12.5 9.25V5ZM12 0C9.61305 0 7.32387 0.948211 5.63604 2.63604C3.94821 4.32387 3 6.61305 3 9H0L3.96 13.03L8 9H5C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 10.8565 18.2625 12.637 16.9497 13.9497C15.637 15.2625 13.8565 16 12 16C10.07 16 8.32 15.21 7.06 13.94L5.64 15.36C7.27 17 9.5 18 12 18C14.3869 18 16.6761 17.0518 18.364 15.364C20.0518 13.6761 21 11.3869 21 9C21 6.61305 20.0518 4.32387 18.364 2.63604C16.6761 0.948211 14.3869 0 12 0Z" fill="#999999"/>
              </svg>
            </div>
            <div class="summary">
              {{search}}
            </div>
            <div class="ml-auto">
              <svg width="14" height="14" @click.stop="removeSearchAtIndex(idx)" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#9E9E9E"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="hidden-previous-searches" v-if="hiddenResultCount">
          {{hiddenResultCount}} hidden not matching "{{searchText}}"
        </div>

      </div>
      <div class="section-with-header" :class="{external:external}" v-if="savedSearches && savedSearches.length">
        <div class="header">Saved Searches</div>
        <div class="results-list">
          <div v-for="search in savedSearches" @click.stop="activateSearch(search)" v-if="displaySavedSearch(search)" class="results-list-item">
            <div class="icon">
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.90342 1.61863C8.0046 1.61863 8.10578 1.61863 8.20696 1.61863C11.8157 1.61863 14.4264 1.61863 18.0352 1.61863C18.1431 1.61863 18.2578 1.61189 18.359 1.63212C18.7569 1.71307 19.0335 2.07732 19.0065 2.47529C18.9795 2.87327 18.6557 3.19704 18.2578 3.23077C18.1768 3.23751 18.0891 3.23077 18.0082 3.23077C14.4197 3.23077 11.8225 3.23077 8.23394 3.23077C8.13276 3.23077 8.03158 3.23077 7.91691 3.23077C7.89668 3.35218 7.87644 3.46685 7.84946 3.57478C7.68757 4.30327 7.067 4.82941 6.31827 4.8429C5.81237 4.85639 5.30647 4.8429 4.80732 4.8429C3.78878 4.8429 3.15471 4.27629 3.0333 3.22402C2.94561 3.22402 2.85792 3.22402 2.77023 3.22402C1.78541 3.22402 1.80084 3.23077 0.816024 3.22402C0.391069 3.22402 0.0538025 2.90699 0.00658524 2.50227C-0.0338867 2.09081 0.235926 1.71307 0.647391 1.62538C0.73508 1.60514 0.829515 1.60514 0.92395 1.60514C1.8683 1.60514 1.80565 1.60514 2.74999 1.60514C2.84443 1.60514 2.93212 1.60514 3.0333 1.60514C3.06703 1.10599 3.2424 0.687779 3.6134 0.377494C3.91019 0.134662 4.26095 -0.000244141 4.65218 -0.000244141C5.16482 -0.000244141 5.67747 -0.000244141 6.19011 -0.000244141C7.12097 -0.000244141 7.76852 0.579854 7.88319 1.49722C7.88993 1.53095 7.89668 1.56467 7.90342 1.61863ZM6.26431 1.62538C5.71794 1.62538 5.19855 1.62538 4.67241 1.62538C4.67241 2.15826 4.67241 2.6844 4.67241 3.21053C5.21204 3.21053 5.73143 3.21053 6.26431 3.21053C6.26431 2.6844 6.26431 2.16501 6.26431 1.62538Z" fill="#999999"/>
                <path d="M14.3586 9.69251C14.3248 10.1512 14.1764 10.5492 13.8527 10.8595C13.5424 11.1562 13.1646 11.3046 12.7329 11.3114C12.2203 11.3114 11.7076 11.3114 11.195 11.3114C10.2709 11.3046 9.62334 10.7313 9.50867 9.81393C9.50193 9.7802 9.50193 9.75322 9.48843 9.69926C9.38051 9.69926 9.28607 9.69926 9.18489 9.69926C6.11577 9.69926 4.0469 9.69926 0.977772 9.69926C0.83612 9.69926 0.687723 9.69251 0.552816 9.65204C0.181823 9.53737 -0.0407726 9.16638 0.00644467 8.78864C0.0604073 8.4109 0.370692 8.12085 0.755176 8.09387C0.83612 8.08712 0.923809 8.09387 1.00475 8.09387C4.06039 8.09387 6.10903 8.09387 9.16466 8.09387C9.26584 8.09387 9.36702 8.09387 9.4682 8.09387C9.50193 7.92524 9.52216 7.77009 9.56938 7.6217C9.76499 6.96065 10.3788 6.49523 11.0736 6.48174C11.5727 6.47499 12.0652 6.48174 12.5643 6.48174C13.6098 6.48174 14.2304 7.02811 14.3586 8.10061C14.4462 8.10061 14.5339 8.10061 14.6216 8.10061C16.1258 8.10061 16.632 8.10061 18.143 8.10061C18.7703 8.10061 19.175 8.67397 18.9389 9.22034C18.7905 9.56435 18.5072 9.71949 18.1362 9.71949C16.6388 9.71949 16.1393 9.71949 14.6419 9.71949C14.5542 9.69251 14.4597 9.69251 14.3586 9.69251ZM12.7127 9.69251C12.7127 9.15963 12.7127 8.63349 12.7127 8.10061C12.1798 8.10061 11.6537 8.10061 11.1343 8.10061C11.1343 8.64024 11.1343 9.16638 11.1343 9.69251C11.6672 9.69251 12.1798 9.69251 12.7127 9.69251Z" fill="#999999"/>
                <path d="M3.02656 14.543C3.06029 14.1113 3.18845 13.7336 3.48524 13.43C3.79553 13.1063 4.18001 12.9309 4.63195 12.9241C5.15134 12.9174 5.67747 12.9174 6.19686 12.9241C7.12772 12.9241 7.76852 13.5042 7.88319 14.4216C7.88994 14.4553 7.88994 14.4823 7.90343 14.5363C8.00461 14.5363 8.10579 14.5363 8.20697 14.5363C11.8157 14.5363 14.4264 14.5363 18.0352 14.5363C18.1431 14.5363 18.2578 14.5295 18.359 14.5565C18.7569 14.6375 19.0335 15.0017 19.0065 15.3997C18.9795 15.7977 18.6558 16.1214 18.251 16.1484C18.1701 16.1552 18.0824 16.1484 18.0015 16.1484C14.4129 16.1484 11.8157 16.1484 8.2272 16.1484C8.12602 16.1484 8.02485 16.1484 7.93716 16.1484C7.91692 16.1754 7.89668 16.1821 7.89668 16.1956C7.77527 17.2007 7.14795 17.7605 6.1429 17.7605C5.70445 17.7605 5.26601 17.7605 4.82756 17.7605C3.78204 17.7605 3.15472 17.2074 3.0333 16.1417C2.94562 16.1417 2.85793 16.1417 2.77024 16.1417C1.81915 16.1417 1.8683 16.1417 0.917211 16.1417C0.377585 16.1417 0.0133371 15.8179 0.0065918 15.339C-0.000153529 14.8533 0.370839 14.5228 0.923956 14.5228C1.8683 14.5228 1.80566 14.5228 2.75 14.5228C2.83769 14.543 2.93212 14.543 3.02656 14.543ZM6.25757 16.1484C6.25757 15.6088 6.25757 15.0827 6.25757 14.5633C5.71794 14.5633 5.19181 14.5633 4.67242 14.5633C4.67242 15.1029 4.67242 15.6155 4.67242 16.1484C5.19855 16.1484 5.71794 16.1484 6.25757 16.1484Z" fill="#999999"/>
              </svg>
            </div>
            <div class="summary">{{truncate(search.title,60,true)}}</div>
          </div>
        </div>
      </div>
      <div class="section-with-header" :class="{external:external}" v-if="regionResults && regionResults.length && searchText && searchText.length">
        <div class="header">
          Areas
        </div>
        <div class="results-list">
          <div class="results-list-item area" @click="fetchOrShowSummaryForRegion(result.label,idx)" v-for="(result,idx) in regionResults" v-if="idx < 5">
            <div class="area-icon-name-wrapper">
              <div class="icon">
                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5ZM7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 2C5.67392 2 4.40215 2.52678 3.46447 3.46447C2.52678 4.40215 2 5.67392 2 7C2 8 2 10 7 16.71C12 10 12 8 12 7C12 5.67392 11.4732 4.40215 10.5355 3.46447C9.59785 2.52678 8.32608 2 7 2Z" fill="#999999"/>
                </svg>

              </div>
              <div class="summary">
                <div>
                  <span v-html="translateLabel(result.label)"></span> ({{getRegionType(result.label)}})
                  <p class="small-label">
                    {{getSmallLabel(result.label)}}
                  </p>
                </div>
              </div>
            </div>
            <div class="summary-under" v-if="result.summary || result.summary && result.summary.loading">
              <svg class="loading-svg" v-if="result.summary && result.summary.loading" id="dots" width="50px" height="30px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch -->
                <title>dots</title>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                  <g id="dots" sketch:type="MSArtboardGroup" fill="#A3A3A3">
                    <circle class="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="8"></circle>
                    <circle class="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="10"></circle>
                    <circle class="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="12"></circle>
                  </g>
                </g>
              </svg>
              <div @click.stop="registerSearchAndNavigate('area',{item:result.label,'type':item})" class="breakdown-item" v-if="result.summary && result.summary.breakdown" v-for="item of result.summary.breakdown">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1H1.5ZM1 8H0.5C0.5 8.27614 0.723858 8.5 1 8.5V8ZM10.8536 8.35355C11.0488 8.15829 11.0488 7.84171 10.8536 7.64645L7.67157 4.46447C7.47631 4.2692 7.15973 4.2692 6.96447 4.46447C6.7692 4.65973 6.7692 4.97631 6.96447 5.17157L9.79289 8L6.96447 10.8284C6.7692 11.0237 6.7692 11.3403 6.96447 11.5355C7.15973 11.7308 7.47631 11.7308 7.67157 11.5355L10.8536 8.35355ZM0.5 1V8H1.5V1H0.5ZM1 8.5H10.5V7.5H1V8.5Z" fill="#007AAB"/>
                </svg>
                <span class="item-count">({{item.count}}) </span><span class="breakdown-type-dwelling">{{item.label}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-with-header" :class="{external:external}" v-if="listingResults && listingResults.length || loadingListingResults">
        <div class="header">
          Listings
        </div>
        <div class="results-list" :class="{external:external}">
          <svg class="loading-svg" v-if="loadingListingResults" id="dots" width="50px" height="30px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
            <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch -->
            <title>dots</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
              <g id="dots" sketch:type="MSArtboardGroup" fill="#A3A3A3">
                <circle class="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="8"></circle>
                <circle class="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="10"></circle>
                <circle class="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="12"></circle>
              </g>
            </g>
          </svg>
          <div class="results-list-item listing" @click="registerSearchAndNavigate('listing',result.record)" v-for="(result,idx) in listingResults" v-if="idx < 5">
            <div class="icon">
              <img class="listing-image" :src="getIconSrc(result.record)" alt="" />
            </div>
            <div class="summary">
              <span v-html="translateListingLabel(result.record.address)"></span>
              <p class="small-label mls">
                MLS <span v-html="translateListingLabel(result.record.mls_id)"></span>
              </p>
              <span class="small-grey-text" v-html="translateListingLabel(getBreadcrumbForListing(result.record))">
              </span>
            </div>
          </div>

        </div>
      </div>
      <div class="section-with-header" :class="{external:external}" v-if="agentSearchResults && agentSearchResults.length">
        <div class="header">
          Agents
        </div>
        <div v-if="loadingAgents">
          <svg class="loading-svg" id="dots" width="50px" height="30px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
            <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch -->
            <title>dots</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
              <g id="dots" sketch:type="MSArtboardGroup" fill="#A3A3A3">
                <circle class="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="8"></circle>
                <circle class="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="10"></circle>
                <circle class="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="12"></circle>
              </g>
            </g>
          </svg>
        </div>
        <div v-for="result in agentSearchResults" class="results-list">
          <div class="results-list-item agent">

            <div>
              <img class="agent-image" :src="result.tile_image_url" alt="">
            </div>
            <div class="summary" @click="goToAgentPage(result)">
              <span class="agent-name">{{result.user_name}}</span>
             <span class="designation">{{result.designation}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section-with-header" v-if="blogResults && blogResults.length">
        <div class="header">
          News
        </div>
        <div class="results-list">
          <div class="results-list-item" v-for="(post,index) in blogResults" v-if="index < 4" @click.stop="navigateToBlogPost(post)">
            <div class="icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4C6 4.53043 6.21071 5.03914 6.58579 5.41421C6.96086 5.78929 7.46957 6 8 6C8.53043 6 9.03914 5.78929 9.41421 5.41421C9.78929 5.03914 10 4.53043 10 4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 8.53043 2 8 2ZM8 9C10.67 9 16 10.33 16 13V16H0V13C0 10.33 5.33 9 8 9ZM8 10.9C5.03 10.9 1.9 12.36 1.9 13V14.1H14.1V13C14.1 12.36 10.97 10.9 8 10.9Z" fill="#999999"/>
              </svg>
            </div>
            <div class="summary">
              <span v-html="truncate(post.title.rendered,50)"></span>
              <span class="small-grey-text">
                {{getCategoryForBlogPost(post)}} {{getDateForBlogPost(post.date)}}
              </span>
            </div>
          </div>
        </div>
        <div class="see-all-blog-posts">
          <a href="https://www.hawaiilife.com/blog">See all Blog Posts</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchResultsList",
  props:['hiddenResultCount','external','highlight','allResultsHidden','getCategoryForBlogPost','showPreviousSearch','getIconSrc','navigateToBlogPost','getDateForBlogPost','truncate','getBreadcrumbForListing','translateListingLabel','getCDNURL','registerSearchAndNavigate','goToAgentPage','blogResults','agentSearchResults','listingResults','loadingAgents','searchHistory','removeSearchAtIndex','populateSearchTextAndSearch','activateSearch','savedSearches','regionResults','searchText','fetchOrShowSummaryForRegion','getRegionType','getSmallLabel','translateLabel','loadingListingResults','searchText'],
  methods:{
    displaySavedSearch(search){
      if(!this.searchText) return true;
      if(!this.searchText.length) return true;
      return search.title.toLowerCase().includes(this.searchText.toLowerCase());

    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixins/box-shadow.scss';
@import '../assets/scss/variables/media.scss';
@mixin hl-font {
  font-family: "Whitney A", "Whitney B",HL-Whitney-Book,sans-serif
}
.ds{
  background-color: white;
  position:relative;
  padding-bottom:60px;

}
.section-with-header{
  &.external{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  padding-top:10px;
  padding-bottom:10px;
  .header{
    @include hl-font;
    font-size:18px;
    padding-left:20px;
    font-family:'HL-Whitney-SemiBold',sans-serif;
  }
  &.external{
    .header{
      padding-top:5px;
      font-family:'Whitney A','Whitney B',sans-serif;
      font-weight:500;
    }
  }

}

.results-list-item{
  padding:10px;
  display:flex;
  align-items: center;
  flex-wrap: nowrap;
  &:hover{
    cursor:pointer;
  }
  .icon{
    min-width:80px;
    display:flex;
    justify-content: center;
  }
  &.area{
    &:hover{
      cursor:pointer;
    }
    .summary{
      padding-left:5px;
    }
  }



  .summary{
    font-family:HL-Whitney-Book,"Whitney A","Whitney B",sans-serif;
    display:flex;
    flex-direction:column;
    font-weight:500;
    strong{
      font-weight:700;
      font-family:HL-Whitney-Bold,sans-serif;
    }
  }
  p.small-label{
    font-size:12px;
    color:#737373;
    font-family: HL-Whitney-Book,sans-serif;
    margin-bottom:0;
    padding-bottom:0;
    &.mls{
      color:#333333;
    }
  }

  &.listing{
    .summary{
      padding-left:5px;
    }
  }
  &.agent{
    padding-left:20px;
    .summary{
      padding-left:20px;
    }
    .designation{
      font-size:12px;
      color:#737373;
    }

  }

}

#dots .dot1{
  animation: load 1s infinite;
}

#dots .dot2{
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots .dot3{
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.summary-under{
  width:100%;
  .loading-svg{
    margin-left:40px;
  }
  .breakdown-item{
    padding:10px;
    font-family: HL-Whitney-Book,'HLWhitneyA', "Whitney A", "Whitney B", sans-serif;
    display:flex;
    flex-direction: row;
    align-items: center;
    svg{
      margin-left:40px;
      margin-right:20px;
    }
    .item-count{
      font-size:12px;
      color:#737373;
    }
    .breakdown-type-dwelling{
      font-size:16px;
      margin-left:5px;
    }

  }
}

.agent-image{
  @include hl-box-shadow-md;
  border-radius:50px;
}
.agent-name{
  font-size:16px;
}

.need-help{
  font-size:12px;
  color:#737373;
  font-family: HL-Whitney-Book,sans-serif;
  padding-left:20px;
  background-color: white;
}

.results-list{
  background-color: white;
  > .loading-svg{
    margin-left:20px;
  }
  @media(min-width: $media-mobile){
  }
}

.listing-image,.agent-image{
  max-height:60px;
  max-width:60px;
  margin-right:0px;
  border-radius:30px;
}
.clear-input{
  position:absolute;
  right:80px;
  top:45px;
}
.ml-auto{
  margin-left:auto;
  padding-right:10px;
}
.ds{
  @include hl-box-shadow-md;
  height:calc(85vh);
  overflow-y: scroll;
  border-bottom:1px #737373 solid;
}

.small-grey-text{
  font-family: HL-Whitney-Book,sans-serif;
  font-size:12px;
  color:#737373;
  clear:both;
}
.see-all-blog-posts{
  padding:0;
  padding-left:10px;
  margin-bottom:70px;
  a{
    background-color: rgba(255,255,255,0);
    color:black;
    margin-bottom:10px;
  }
}
.external{
  .see-all-blog-posts{
    a {
      display: block;
      width: 100%;
    }
  }
}




.filter-overlay-bg{
  display:none;
}
.hidden-previous-searches{
  padding-left:20px;
  padding-top:10px;
  padding-bottom:10px;
  background-color: white;
  font-family: HL-Whitney-Book,sans-serif;
  font-size:12px;
  color:#737373;
}



@media(min-width: $media-mobile){
  .ds{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: stretch;
    height:auto;
    max-height:70vh;
    margin-top:10px;
    overflow-y: scroll;
    .results-list{
      flex-grow:1;
    }
  }
  .section-wrapper{
    width:100%;
  }
  .results-list-item.area{
    flex-wrap: wrap;
    .summary-under{
      padding-left:40px;
    }

  }
  .section-with-header{
    .header{
      @media(min-width: $media-mobile) {
        padding-top:0;
        padding-bottom:0;
      }
    }
  }


  .see-all-blog-posts a{
    margin-bottom:0px;
    padding-bottom:0;
  }
}

.area-icon-name-wrapper{
  display:flex;
  flex-direction:row;
  align-items: center;
  width:100%;
}


@media(max-width: $media-mobile){
  .results-list-item.area{
    display:flex;
    width:100%;
    flex-wrap:wrap;
    .summary-under{
      width:100%;
      display:table-cell;
    }
  }
}


</style>
