<template>
<div class="forbes-promo">
  <div class="row">
    <div class="fifty-width"  v-if="layout === 'three-two'">
      <img src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/forbesv1/Slice1.png" alt="Hawaii Life Forbes global aloha" />
    </div>
    <div class="fifty-width"  v-if="layout === 'three-two'">
      <img class="kick-down" src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/forbesv1/Slice2.png" alt="Forbes Global Properties Logo" />
    </div>
    <div class="hundred-width" v-if="layout === 'vertical'">
      <img @click="clickOnImage" src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/forbesv3/Slice1.png"  v-bind:class="{clickable:classForImages}" alt="Hawaii Life Forbes global aloha" />
    </div>
    <div class="hundred-width"  v-if="layout === 'vertical'">
      <img @click="clickOnImage" src="https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/forbesv3/Slice2.png" alt="Forbes Global Properties Logo" v-bind:class="{clickable:classForImages}" />
    </div>
    <div v-if="!hideText" class="hundred-width forbes-announcement" :class="{'center-text':layout === 'vertical' && !textAlignLeft}">
    <p>Hawai'i Life is proud to announce our founding membership in Forbes Global Properties. <a href="https://www.hawaiilife.com/company/forbes-global-properties/">Learn more ></a>  </p>
    </div>
  </div>


</div>



</template>

<script>
export default {
  props:['layout','hideText','textAlignLeft'],
  name: "Forbes",
  computed:{
    classForImages(){
      if(window.location.href && !window.location.href.includes('/company/forbes-global-properties')){
        return 'clickable';
      }

      return null;
    }


  },
  methods:{
    clickOnImage(){
      if(this.classForImages){
        window.location = 'https://www.hawaiilife.com/company/forbes-global-properties';
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
div.row{
  a{
    color:#12b5ea;
    text-decoration: none;
  }
  margin-top:80px;
  margin-bottom:40px;
  p{
    font-size:19px;
    font-weight:700;
  }
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  .fifty-width{
    align-items: center;
    justify-content: center;
    width:50%;
    img{
      margin:10px auto;
      max-width:80%;
      &.kick-down{
        margin-top:20px;
      }
    }
  }
  .hundred-width{
    width:100%;
      img{
        display:block;
        margin:10px auto;
        max-width:90%;
      }
  }

  .center-text{
    text-align: center;
  }



  .forbes-announcement{
    margin-top:30px;

  }

  .hundred-width,.fifty-width{
    margin-bottom:30px;
  }

  img.clickable{
  &:hover{
    cursor:pointer;
  }
  }

}
</style>
