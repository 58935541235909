<template>
  <div>
  <div class="video-and-search" :style="getOverlayStyle">
  <div class="video-background-wrapper">
  <video-background :src="selectedVideoUrl" :poster="selectedVideoPoster" :style="getOverlayStyle" :loop="true">
  </video-background>
  </div>
    <div class="search-container">
      <div class="search-wrapper">
        <h2 class="shadowed"><span class="single-line-mobile header">Find <i>your</i></span><span class="single-line-mobile header"> Hawai'i Life</span></h2>
        <div class="divisions-cta">
          <a class="shadowed" href="https://www.hawaiilife.com/listings?utm_content=homepage-welcome">Real Estate</a> | <a href="https://www.hawaiilife.com/company/rentals/?utm_content=homepage-welcome" class="shadowed" target="_blank">Rentals</a> | <a target="_blank" class="shadowed" href="https://vacations.hawaiilife.com/?utm_content=homepage-welcome">Vacations</a> | <a class="shadowed" href="https://www.hawaiilife.com/agents?utm_content=homepage-welcome">Agents</a>
        </div>
        <div class="cb-ml5">
        <autocomplete-search></autocomplete-search>
          </div>
        <div class="also-check-out" style="display:none;">
        <a href="https://www.hawaiilife.com/listings/-ghawaiilife?displayType=map&utm_content=homepage-welcome">Our Hawaii Life listings</a> <a href="https://www.hawaiilife.com/listings/-gluxury?displayType=map&utm_content=homepage-welcome">Luxury properties</a> | <a href="https://www.hawaiilife.com/listings/-greduced&utm_content=homepage-welcome">Recently reduced price</a>
        </div>
        <p class="call-or-text"><a href="tel:800-370-3848">Call</a> or <a href="sms://+18003703848?body=I%27m%20interested in..."> text</a>  us in Hawai'i <a href="tel:800-370-3848">800-370-3848</a></p>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import AutocompleteSearch from "@/components/AutocompleteSearch";

const video_base = 'https://d3kgroy8oa04a.cloudfront.net/homepage-videos/';
let videos = [];
// // const napali = {url: video_base + 'HL_BG_1920x1080_Napali.mp4','poster': video_base + 'HL_BG_1920x1080_NapaliStill.jpg'};
// // const balihai = {'poster': video_base  + 'HL_BG_1920x1080_BaliHaiStill.jpg',url: video_base + 'HL_BG_1920x1080_BaliHai.mp4'};
//  const ridge =  {poster: video_base + 'HL_BG_1920x1080_NapaliRidgeStill.jpg',url: video_base + 'HL_BG_1920x1080_NapaliRidge.mp4'};
// // const city = {poster:video_base + 'HL_BG_1920x1080_City.jpg',url:video_base + 'HL_BG_1920x1080_City.mp4'};
//  const diamond =   {poster:video_base + 'HL_BG_1920x1080_Diamond.jpg',url:video_base + 'HL_BG_1920x1080_Diamond.mp4'};
// // const waikiki =   {poster:video_base + 'HL_BG_1920x1080_Waiki.jpg',url:video_base + 'HL_BG_1920x1080_Waiki.mp4'};
//  const palm = {poster:video_base + 'HL_BG_1920x1080_Palm2Still.jpg',url: video_base + 'HL_BG_1920x1080_Palm2.mp4'};
// // const dolphins = {poster:video_base + 'HL_BG_1920x1080_Dolphins.jpg',url: video_base + 'HL_BG_1920x1080_Dolphins.mp4'};
// // const turtle1 = {poster:video_base + 'HL_BG_1920x1080_Turtle1Still.jpg',url: video_base + 'HL_BG_1920x1080_Turtle1.mp4'};
//  const turtle2 = {poster:video_base + 'HL_BG_1920x1080_Turtle2Still.jpg',url: video_base + 'HL_BG_1920x1080_Turtle2.mp4'};
//
//
// const video1 = {poster:video_base + 'HL_BG_1920x1080_Family.jpg',url: video_base + 'HL_BG_1920x1080_Family.mp4'};
// //const video2 = {poster:video_base + 'HL_BG_1920x1080_Walk.jpg',url: video_base + 'HL_BG_1920x1080_Walk.mp4'};

const vid1 = {poster:video_base + 'Bamboo.jpg',url: video_base + 'Bamboo.mp4'};
const vid2 = {poster:video_base + 'Beach.plg',url: video_base + 'Beach.mp4'};
const vid3 = {poster:video_base + 'Beach2.jpg',url: video_base + 'Beach2.mp4'};
const vid4 = {poster:video_base + 'Dolphins.jpg',url: video_base + 'Dolphins.mp4'};
const vid5 = {poster:video_base + 'MacroFood.jpg',url: video_base + 'MacroFood.mp4'};
const vid6 = {poster:video_base + 'Maui.jpg',url: video_base + 'Maui.mp4'};
const vid7 = {poster:video_base + 'Princeville1.jpg',url: video_base + 'Princeville1.mp4'};
const vid8 = {poster:video_base + 'Sail.jpg',url: video_base + 'Sail.mp4'};
const vid9 = {poster:video_base + 'Sail2.jpg',url: video_base + 'Sail2.mp4'};
const vid10 = {poster:video_base + 'waterfall.jpg',url: video_base + 'waterfall.mp4'};
const vid11 = {poster:video_base + 'Maui2.jpg',url: video_base + 'Maui2.mp4'};
const vid12 = {poster:video_base + 'Maui3.jpg',url: video_base + 'Maui3.mp4'};


videos.push(vid1);
videos.push(vid2);
videos.push(vid3);
videos.push(vid4);
videos.push(vid5);
videos.push(vid6);
videos.push(vid7);
videos.push(vid8);
videos.push(vid9);
videos.push(vid10);
videos.push(vid11);
videos.push(vid12);







// for(var i = 0; i <= 5; i++){
//   videos.push({...city});
//   videos.push(diamond);
//   videos.push(waikiki);
// }
//
// for (var i = 0; i <= 3; i++){
//   videos.push(balihai);
//   videos.push(napali);
// }


const r = Math.floor((Math.random() * (videos.length * 10)) / 10);
console.log('r',r);
export default {
  props:['height'],
name: "Welcome",
  components:{
  AutocompleteSearch,
    VideoBackground,
  },
  data() {
    const videoUrl = videos[r].url;
    const poster = videos[r].poster;
    return {
      selectedVideoUrl:videoUrl,
      selectedVideoPoster:poster,
    }
  },
  computed:{
  getOverlayStyle(){
    if(this.height){
      return {'height':this.height.toString() + 'px'};
    }
    return {'min-height':'600px'};
  }
  }
}
</script>

<style lang="scss">
div.video-and-search{
  position: relative;
}

.video-background-wrapper{
  position:absolute;
  left:0px;
  width:100vw;
}

.green{
  color:#47B048;
}

.cb-ml5{
  box-sizing: border-box;
  padding-left:0px;
}
p{
  font-family:"Whitney A","Whitney B",sans-serif;
  margin-left:5px;
}

.search-container{
  position:absolute;
  width:100%;
  top:80px;
  left:0px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow-y: visible;
  @media(max-width:520px){
    padding:10px;
    box-sizing: border-box;
    padding-right:18px;
  }
}
.hawaiilife-homepage-search .search-container{
  @media(max-width:520px){
    padding:20px;
  }
}


.search-wrapper{
  transition: all 0.5s ease;
  color:white;
  margin-top:70px;
  @media(max-width: 520px){
    margin-top:100px;
  }
  @media(min-width:800px) and (max-width: 1200px){
    margin-left:-28vw;
    margin-top:60px;
  }
  @media(min-width:520px) and (max-width: 900px){
    padding:10px;
  }


  box-sizing: border-box;
  width:auto;
  max-width:800px;
  @media(max-width:520px){
    width:100%;
  }
  .shadowed, .also-check-out a{
    text-shadow: -1px -1px 1px rgba(0,0,0,.1), 1px 1px 1px rgba(0,0,0,0.2), 2px 3px 5px rgba(0,0,0,0.13);
  }
  h2,h4{
    font-family: "Whitney A", "Whitney B", Helvetica, Arial, sans-serif;
    color:white;
    font-weight:600;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 0.3px;
  }
  h2{
      font-size:60px;
      @media(max-width:520px){
        font-size:31px;
      }
    @media(min-width:520px) and (max-width: 800px){
      font-size:45px;
    }
    margin-bottom:20px;
  }
  h4{
    font-size:25px;
    margin-bottom:20px;
    @media(max-width:520px){
      font-size:20px;
    }
  }

  > a{
    font-weight:bold;
    font-family:"Whitney A","Whitney B",sans-serif;
    font-size:19px;
    text-decoration:none;
    margin-top:10px;
    margin-bottom:10px;
    padding-left:5px;
    padding-right:5px;
    color:white;
    letter-spacing: 0.5px;
    word-wrap: normal;
    text-shadow: 10px 10px 10px 3px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.5s;
    &:hover{
      color:#12b5ea;
    }
    @media(max-width: 520px){
      font-size:14px;
    }
    &.hl-button{
      font-size:16px;
      text-decoration: none;
    }
  }

  .also-check-out{
    padding-top:30px;
    a{
      font-weight:500;
    }
  }

  .call-or-text{
    margin-top:30px;
    font-size:19px;
    margin-left:0px;
    color:white;
    @media(max-width:520px){
      font-size:15px;
    }
    a{
      font-weight:bold;
      padding-left:2px;
      margin-top:0px;
      color:white !important;
      text-decoration: none;
    }
  }

  .divisions-cta{
    font-size:19px;
    @media(max-width:520px){
      font-size:13px;
    }
    a{
      font-weight:bold;
      font-family:"Whitney A","Whitney B",sans-serif;
      text-decoration:none;
      margin-top:10px;
      margin-bottom:10px;
      padding-left:5px;
      padding-right:5px;
      color:white;
      letter-spacing: 0.5px;
      word-wrap: normal;
      text-shadow: 10px 10px 10px 3px rgba(0, 0, 0, 0.3);
      transition: all ease-in-out 0.5s;
      &:hover{
        color:#12b5ea;
      }
    }
    margin-left:-5px;
  }
  .divisions-cta a{
    font-weight:500;
  }

}
.hawaiilife-homepage-search{
  .video-background-wrapper{
    left:0;
  }
}

.search-wrapper{
  .result-items-header{
    h4{
      font-size:20px;
    }
  }
  .ac-property-detail{
    h4{
      font-size:20px;
    }
  }
}
span.single-line-mobile.header{
  @media(max-width:520px){
    display:block;
    width:100%;
    clear:both;
      font-size:50px;
  }
}

</style>
