<template>
<div class="related-listing">
    <div v-if="listing">
    <div class="listing-related-content-pane" :style="getBackgroundImageForListing()">

        <div class="details">
            <h2>{{listing.address.single_line}}</h2>
            <div v-if="listing.property_type_name !== 'land'">
                <h2>{{listing.total_beds}} br / {{listing.total_baths }} ba</h2>
                <h2>{{listing.total_floor_area}} sq/ft of Living</h2>
                <h2></h2>
                <h2></h2>
            </div>
            <div v-if="listing.property_type == 'land'">

            </div>
            <h2>{{Intl.NumberFormat('en-US',{ style: 'currency', currency: 'USD',minimumFractionDigits:0}).format(listing.asking_price)}}</h2>


            <a :href="getLinkToListing(listing)" class="hl-button">See it on HawaiiLife.com</a>
        </div>
    </div>
    </div>


</div>



</template>

<script>
    import axios from 'axios';
    export default {
        props:['type','url'],
        name: "RelatedListing",
        data(){
            return {
                listing:null
            }
        },
        methods:{
            getBackgroundImageForListing:function(){
                const i = this.listing.images[0] ? this.listing.images[0] : null;

               return {
                   'background-image': `url("${i.url}")`,
                   'box-shadow': 'inset 0 0 150px rgba(50,50,50,0.8)'
               };
            },
            getLinkToListing() {
                return `https://www.hawaiilife.com/listings/${this.listing.slug} ?src=blog`;
            }
        },
        async mounted(){
            if(this.type === 'listing'){
              const {data} = await axios.get('https://www.hawaiilife.com/api/v1/listings/' + this.url);
              this.listing = data;


            }


        }
    }
</script>

<style scoped lang="scss">
    .listing-related-content-pane{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        min-height:300px;
        height:auto;
        text-align: center;
        padding:40px;
        @media all and (max-width: 400px){
            padding:10px;
        }
        h2{
            color: #F4F4F4;
            text-shadow: 1.4px 1.4px 1px rgba(0,0,0,0.35);
            font-size: 38px;
            font-weight: 700;
            line-height: 45.6px;
            font-family:'Whitney A','WhitneyB', sans-serif;
        }
        .hl-button{
            width:50%;
            max-width:300px;
        }

        .details{
            width:50%;
            margin:0 auto;
            padding:20px;
            background-color: rgba(50,50,50,0.5);
            box-shadow: 20px 10px rgba(50,50,50,0.5);
            @media all and (max-width: 400px){
                padding:10px;
                width:70vw;
                max-width:70vw;
                h2{
                    font-size:22px;
                }
            }
        }

    }


</style>
