<template>
<div :id="`hl-map-${map_id}`">

</div>
</template>

<script>
import axios from "axios";

export default {
name: "MapListings",
  props:['mlsIds'],
  data(){
  return {
    listings:[],
    map_id:null,
  }
  },
  mounted(){
    this.loadListings();
    this.map_id = 'hl' + Math.floor(Math.random() * 1000);
  },
  methods:{
    async loadListings(){
      const ids = this.mlsIds.split(',');
      for(var id of ids){
       const result = await this.$store.dispatch('loadMLSById',id);
        if(result)  this.listings.push(result);
      }
    }
  }
}
</script>

<style scoped>

</style>
