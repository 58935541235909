class LocalStorageHelper {
    static put(key, value, seconds = 3000) {
        try {
            const now = new Date();

            // Item to be stored in localStorage
            const item = {
                value: value,
                expiry: now.getTime() + (seconds * 1000), // ttl is in milliseconds
            };

            localStorage.setItem(key, JSON.stringify(item));
        } catch (error) {
            console.error("Failed to write to localStorage:", error);
        }
    }

    static fetch(key) {
        try {
            const itemStr = localStorage.getItem(key);

            // If the item does not exist, return null
            if (!itemStr) {
                return null;
            }

            const item = JSON.parse(itemStr);
            const now = new Date();

            // Compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                // If the item has expired, remove it from storage and return null
                localStorage.removeItem(key);
                return null;
            }

            return item.value;
        } catch (error) {
            console.error("Failed to read from localStorage:", error);
            return null;
        }
    }
}

// Exporting class as default
export default LocalStorageHelper;
