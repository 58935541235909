<template>
    <div class="contact-lead-team-wrapper">
    <div class="contact-lead-team-body not-mobile">
        <div class="sixty">
            <h1 class="heading-font-size">Get connected with agents in Hawaii to help you.</h1>
            <div class="help-triggered">
                <a v-if="showHelp " class="hl-button margin-right" @click="startChatting">
                    Chat
                </a>
                <a v-if="showHelp" class="hl-button call white black-text margin-right" href="tel:800-370-3848">
                    Text Or Call
                </a>
            </div>
        </div>
        <div class="fourty background-cta" :style="getBackgroundImageStyle">
        </div>
    </div>
    <div class="contact-lead-team-body mobile-only">
    </div>
    </div>
</template>

<script>
    import styles from '../assets/styles.scss';
    export default {
        name: "ContactLeadTeam",
        data(){
          return {
              showHelp:true,
          }
        },
        methods:{
            startChatting(){
                if(window && window.embedded_svc){
                    try {
                        window.embedded_svc.inviteAPI.inviteButton.acceptInvite()
                    }catch(e){
                                            }

                }else{
                    if(console) console.warn('could not find embedded chat');
                }


            },
          getBackgroundImageStyle(){
              return {}
          }
        },
    }
</script>

<style lang="scss" scoped>
div.contact-lead-team-body{
    display:flex;
    flex-direction: row;
    div.sixty{
        width:60%;
    }
    div.fourty{
        width:40%;
    }

    .background-cta{
        height:350px;
        background-image: url(https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/lead-team/DKeyes_7517_edit_web.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
    }
    @media all and (max-width: 600px) {
        .background-rud{
            display:none;
        }
        div.sixty{
            width:100%;
            padding-bottom:20px;
        }

        .hl-button{}
    }


}


</style>
