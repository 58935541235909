<template>
<span style="position: relative">
  <RequestShowing :mls-number="mlsNumber" v-if="requestShowing" :show-modal="showModal" :close-modal="() => { this.showModal = false;}"></RequestShowing>
  <span v-if="!layout" class="button" @click="requestShowing = true; showModal = true;">
<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 331.37 331.37" style="enable-background:new 0 0 331.37 331.37;" xml:space="preserve">
<g>
	<g id="Layer_5_15_">
		<g>
			<g>
				<path d="M111.374,188.961c0,4.956-4.055,9.01-9.01,9.01H57.313c-4.955,0-9.01-4.055-9.01-9.01v-30.113
					c0-4.956,4.055-9.01,9.01-9.01h45.051c4.956,0,9.01,4.055,9.01,9.01V188.961z"/>
				<path d="M111.374,268.091c0,4.956-4.055,9.01-9.01,9.01H57.313c-4.955,0-9.01-4.055-9.01-9.01v-30.113
					c0-4.956,4.055-9.01,9.01-9.01h45.051c4.956,0,9.01,4.055,9.01,9.01V268.091z"/>
			</g>
			<g>
				<path d="M197.222,188.961c0,4.956-4.055,9.01-9.01,9.01H143.16c-4.956,0-9.01-4.055-9.01-9.01v-30.113
					c0-4.956,4.055-9.01,9.01-9.01h45.052c4.956,0,9.01,4.055,9.01,9.01V188.961z"/>
				<path d="M197.222,268.091c0,4.956-4.055,9.01-9.01,9.01H143.16c-4.956,0-9.01-4.055-9.01-9.01v-30.113
					c0-4.956,4.055-9.01,9.01-9.01h45.052c4.956,0,9.01,4.055,9.01,9.01V268.091z"/>
			</g>
			<g>
				<path d="M282.018,188.961c0,4.956-4.055,9.01-9.01,9.01h-45.052c-4.956,0-9.01-4.055-9.01-9.01v-30.113
					c0-4.956,4.055-9.01,9.01-9.01h45.052c4.956,0,9.01,4.055,9.01,9.01V188.961z"/>
				<path d="M282.018,268.091c0,4.956-4.055,9.01-9.01,9.01h-45.052c-4.956,0-9.01-4.055-9.01-9.01v-30.113
					c0-4.956,4.055-9.01,9.01-9.01h45.052c4.956,0,9.01,4.055,9.01,9.01V268.091z"/>
			</g>
			<path d="M70.786,82.453c-5.383,0-9.787-4.404-9.787-9.788V19.697c0-5.384,4.404-9.788,9.787-9.788h20.361
				c5.383,0,9.788,4.404,9.788,9.788v52.968c0,5.383-4.404,9.788-9.788,9.788H70.786z"/>
			<path d="M240.301,82.453c-5.383,0-9.787-4.404-9.787-9.788V19.697c0-5.384,4.404-9.788,9.787-9.788h20.361
				c5.383,0,9.788,4.404,9.788,9.788v52.968c0,5.383-4.404,9.788-9.788,9.788H240.301z"/>
			<path d="M321.917,49.935c0,0-16.16,0-28.491,0c-1.628,0-4.64,0-4.64,3.753v16.754c0,15.996-8.86,29.01-29.01,29.01h-18.745
				c-19.106,0-29.01-13.014-29.01-29.01l0.001-15.879c0-3-2.096-4.628-4.596-4.628c-23.869,0-58.035,0-82.751,0
				c-1.836,0-5.326,0-5.326,4.753v15.754c0,15.996-7.976,29.01-29.01,29.01H71.594c-23.292,0-29.01-13.014-29.01-29.01V55.313
				c0-4.25-3.826-5.378-5.909-5.378c-12.187,0-27.221,0-27.221,0C4.254,49.935,0,54.189,0,66.393v252.618
				c0-1.804,4.254,2.45,9.454,2.45h312.462c5.2,0,9.454-4.254,9.454-2.45V66.393C331.37,54.189,327.116,49.935,321.917,49.935z
				 M310.362,290.998c0,5.2-4.254,9.454-9.454,9.454H30.463c-5.2,0-9.454-4.254-9.454-9.454V134.464c0-5.2,4.254-9.454,9.454-9.454
				h270.445c5.2,0,9.454,4.254,9.454,9.454V290.998z"/>
		</g>
	</g>
</g>
</svg> See this property
    <small>Virtual or in-person</small>
  </span>
</span>


</template>

<script>
import RequestShowing from "@/components/RequestShowing";
export default {
name: "RequestShowingButton",
  props:['mlsNumber','layout'],
  components: {RequestShowing},
  data(){
  return {
    requestShowing:false,
    showModal:false,
  }
  }
}
</script>

<style lang="scss" scoped>

.listing-actions {
    position:relative;
  .new-label{
    position:absolute;
    top:-15px;
    right:-15px;
    font-size:8px;
    height:30px;
    width:30px;
    border-radius:15px;
    font-weight:700;
    text-transform: uppercase;
    font-family: "Whitney A", "Whitney B", Helvetica, Arial, sans-serif;
    background-color: #665726;
    color:white;
    display:block;
    opacity:1;
    display:flex;
    align-items: center;
    justify-content: center;
  }
     span{
      padding: 0;
      margin-left:0;
       > span.button{
         position:relative;
         width: 100%;
         padding: 13px 12px 13px 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         @media(max-width:520px){
           justify-content: center;
         }
       }
       &:hover{
        cursor:pointer;
       }
    }
  }
.button svg{
  height:30px;
  width:30px;
  fill:white;
  margin-right:10px;
  margin-left:-10px;
}
.button small{
  position:absolute;
  font-size:10px;
  bottom:0px;
  right:10px;
}

</style>
