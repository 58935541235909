<template>
  <div class="video-and-search" :style="getOverlayStyle">
  <div class="video-background-wrapper">
  <video-background :src="selectedVideoUrl" :poster="selectedVideoPoster" :style="getOverlayStyle" :loop="true">
  </video-background>
  </div>
    <div class="search-container">
      <div class="search-wrapper">
        <h2 v-if="section === 'buy'" class="shadowed"><span class="single-line-mobile header">Find <i>your</i></span><span class="single-line-mobile header"> Hawai'i Life</span></h2>
        <h2 v-if="section === 'sell'" class="shadowed"><span class="single-line-mobile header">List With</span><span class="single-line-mobile header"> Hawai'i Life</span></h2>
        <h2 v-if="section === 'estimate'" class="shadowed"><span class="single-line-mobile header">Get Your</span><span class="single-line-mobile header"> Home Value</span></h2>

        <div class="divisions-cta">
          <a class="shadowed" :class="{'underline-in-divisions':section === 'buy'}" href="https://www.hawaiilife.com/listings?new_homepage_embed=1">Buy</a> | <a :class="{'underline-in-divisions':section === 'sell'}" @click.prevent="section = 'sell'" href="https://www.hawaiilife.com/company/rentals/?utm_content=homepage-welcome" class="shadowed" target="_blank">Sell</a> | <a :class="{'underline-in-divisions':section === 'estimate'}" @click.prevent="section = 'estimate'" class="shadowed" href="https://vacations.hawaiilife.com/?utm_content=homepage-welcome">Estimate</a>
        </div>
        <div class="cb-ml5" v-if="section === 'buy'">
        <universal-search-component :external="true" :relative="true"></universal-search-component>
        </div>
        <div class="cb-ml5 sell" v-if="section === 'sell'">
          <input type="text" v-model="lead.address" id="sell-your-property" placeholder="Enter your address" />
          <div @click="sellconfirm = true;" class="btn"><span>Next</span></div>
        </div>
        <Modal :close-modal="hideForm" :hide-close="true" :layout="'wide'" v-if="sellconfirm || submittedToHL">
          <div class="sell-form" v-if="!submittedToHL">
            <div class="promo-graphic">
              <div>
              <img src="https://hawaiilife.s3.us-west-2.amazonaws.com/ce/global-reach-local-style.jpg" alt="">
              <p style="text-align: center" class="call-or-text black">We're available now to discuss your needs. <br /><a href="tel:800-370-3848" style="color:black !important;">Call 800-370-3848</a></p>
              </div>
            </div>
            <div id="sell-form" class="form-wrapper">
            <div class="form-body-copy">
              <svg @click="hideForm" width="14" height="14" viewBox="0 0 14 14" fill="#333333" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black"/>
              </svg>
              <h3 class="property-valuation blue">Receive a professional property valuation.</h3>
              <h4 class="process">A local expert from our team will reach out to gather more information.</h4>
              <p>Our foundations of <span class="green">Smart Marketing</span> and <span class="green">Solid Representation</span> work to connect buyers and sellers in Hawaii, and around the world.</p>
            </div>
            <div class="rel">
              <label for=""><span class="required">*</span> Name</label>
              <input :class="{error: errorForm && errorName}" v-model="lead.fullName" type="text" />
            </div>
              <div class="rel">
                <label for=""><span class="required">*</span>Email</label>
                <input :class="{error: errorForm && errorEmail}" v-model="lead.email" type="text" required />
              </div>
              <div class="rel">
                <label><span class="required">*</span> Phone</label>
                <input :class="{error: errorForm && errorPhone}" v-model="lead.phone" type="text" />
              </div>
              <div class="rel">
                <label for=""><span class="required">*</span> Address</label>
                <input :class="{error: errorForm && errorAddress}" v-model="lead.address" type="text" />
              </div>
              <div class="rel">
                <label for="">Comments</label>
                <textarea v-model="lead.comments" type="textarea"></textarea>
              </div>
            <a @click.prevent="sendLead" class="button hl-button" id="submit-cma">Submit</a>
            <p>We will never share your information or spam you because that would be lame.</p>
            </div>
          </div>
          <div class="cta-text" v-if="submittedToHL">
            <svg @click="hideForm" width="14" height="14" viewBox="0 0 14 14" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white"/>
            </svg>
            <h2>We'll do some research and get right back to you.</h2>
            <p>We fired all our robots and real people do need to sleep, so it might take a bit longer to respond to after-hours inquiries. Mahalo.</p>
          </div>
        </Modal>


        <div class="cb-ml5" v-if="section === 'estimate'">
          <div class="hvs-border-wrapper">
            <hvs-widget states="HI" placeholder="Please enter your address to get an estimated value for your property." v-pre apikey="1651860285768014"> </hvs-widget>
          </div>
        </div>
        <div class="also-check-out" style="display:none;">
        <a href="https://www.hawaiilife.com/listings/-ghawaiilife?displayType=map&utm_content=homepage-welcome">Our Hawaii Life listings</a> <a href="https://www.hawaiilife.com/listings/-gluxury?displayType=map&utm_content=homepage-welcome">Luxury properties</a> | <a href="https://www.hawaiilife.com/listings/-greduced&utm_content=homepage-welcome">Recently reduced price</a>
        </div>
        <p class="call-or-text"><a href="tel:800-370-3848">Call</a> or <a href="sms://+18003703848?body=I%27m%20interested in..."> text</a>  us in Hawai'i <a href="tel:800-370-3848">800-370-3848</a></p>
        </div>
    </div>
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import AutocompleteSearch from "@/components/AutocompleteSearch";
import Modal from "@/components/Modal.vue";
import axios from 'axios';
import validatePhone from "@/helpers/validatePhone";
import validateEmail from "@/helpers/validateEmail";
import UniversalSearchComponent from "../../search/components/UniversalSearchComponent.vue";

const video_base = 'https://d3kgroy8oa04a.cloudfront.net/homepage-videos/';
let videos = [];
// // const napali = {url: video_base + 'HL_BG_1920x1080_Napali.mp4','poster': video_base + 'HL_BG_1920x1080_NapaliStill.jpg'};
// // const balihai = {'poster': video_base  + 'HL_BG_1920x1080_BaliHaiStill.jpg',url: video_base + 'HL_BG_1920x1080_BaliHai.mp4'};
//  const ridge =  {poster: video_base + 'HL_BG_1920x1080_NapaliRidgeStill.jpg',url: video_base + 'HL_BG_1920x1080_NapaliRidge.mp4'};
// // const city = {poster:video_base + 'HL_BG_1920x1080_City.jpg',url:video_base + 'HL_BG_1920x1080_City.mp4'};
//  const diamond =   {poster:video_base + 'HL_BG_1920x1080_Diamond.jpg',url:video_base + 'HL_BG_1920x1080_Diamond.mp4'};
// // const waikiki =   {poster:video_base + 'HL_BG_1920x1080_Waiki.jpg',url:video_base + 'HL_BG_1920x1080_Waiki.mp4'};
//  const palm = {poster:video_base + 'HL_BG_1920x1080_Palm2Still.jpg',url: video_base + 'HL_BG_1920x1080_Palm2.mp4'};
// // const dolphins = {poster:video_base + 'HL_BG_1920x1080_Dolphins.jpg',url: video_base + 'HL_BG_1920x1080_Dolphins.mp4'};
// // const turtle1 = {poster:video_base + 'HL_BG_1920x1080_Turtle1Still.jpg',url: video_base + 'HL_BG_1920x1080_Turtle1.mp4'};
//  const turtle2 = {poster:video_base + 'HL_BG_1920x1080_Turtle2Still.jpg',url: video_base + 'HL_BG_1920x1080_Turtle2.mp4'};
//
//
// const video1 = {poster:video_base + 'HL_BG_1920x1080_Family.jpg',url: video_base + 'HL_BG_1920x1080_Family.mp4'};
// //const video2 = {poster:video_base + 'HL_BG_1920x1080_Walk.jpg',url: video_base + 'HL_BG_1920x1080_Walk.mp4'};

const vid1 = {poster:video_base + 'Bamboo.jpg',url: video_base + 'Bamboo.mp4'};
const vid2 = {poster:video_base + 'Beach.plg',url: video_base + 'Beach.mp4'};
//const vid3 = {poster:video_base + 'Beach2.jpg',url: video_base + 'Beach2.mp4'};
const vid4 = {poster:video_base + 'Dolphins.jpg',url: video_base + 'Dolphins.mp4'};
const vid5 = {poster:video_base + 'MacroFood.jpg',url: video_base + 'MacroFood.mp4'};
const vid6 = {poster:video_base + 'Maui.jpg',url: video_base + 'Maui.mp4'};
const vid7 = {poster:video_base + 'Princeville1.jpg',url: video_base + 'Princeville1.mp4'};
const vid8 = {poster:video_base + 'Sail.jpg',url: video_base + 'Sail.mp4'};
const vid9 = {poster:video_base + 'Sail2.jpg',url: video_base + 'Sail2.mp4'};
const vid10 = {poster:video_base + 'waterfall.jpg',url: video_base + 'waterfall.mp4'};
const vid11 = {poster:video_base + 'Maui2.jpg',url: video_base + 'Maui2.mp4'};
const vid12 = {poster:video_base + 'Maui3.jpg',url: video_base + 'Maui3.mp4'};


videos.push(vid1);
videos.push(vid2);
//videos.push(vid3);
videos.push(vid4);
videos.push(vid5);
videos.push(vid6);
videos.push(vid7);
videos.push(vid8);
videos.push(vid9);
videos.push(vid10);
videos.push(vid11);
videos.push(vid12);




const r = Math.floor((Math.random() * (videos.length * 10)) / 10);
export default {
  props:['height'],
name: "Welcome",
  components:{
    Modal,
  AutocompleteSearch,
    VideoBackground,
    UniversalSearchComponent,
  },
  mounted(){
    (function() {
      var po = document.createElement('script');
      po.type = 'text/javascript';
      po.async = true;
      po.src = 'https://cdn.jsdelivr.net/npm/@percy.ai/hvs-autocomplete@latest/dist/umd/index.bundled.min.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(po, s);
    })();
  },
  methods:{
    hideForm(){
      this.sellconfirm = false;
      this.submittedToHL = false;
    },
    async sendLead(){
      if(!this.lead.email || !validateEmail(this.lead.email)){
        this.errorEmail = true;
      }else{
        this.errorEmail = false;
      }

      if(!this.lead.fullName){
        this.errorName = true;
      }else{
        this.errorName = false;
      }
      if(!this.lead.phone || !validatePhone(this.lead.phone)){
        this.errorPhone = true;
      }else{
        this.errorPhone = false;
      }

      if(!this.lead.address){
        this.errorAddress = true;
      }else{
        this.errorAddress = false;
      }
      if(this.errorEmail || this.errorName || this.errorAddress || this.errorPhone){
        this.errorForm = true;
        return;
      }
      await axios.post("https://www.hawaiilife.com/api/v1/leads",{
        name:this.lead.fullName,
        email:this.lead.email,
        phone:this.lead.phone,
        source_type:'Home Evaluation',
        scheduling_preference:"",
        source_description:"",
        source_url:'https://www.hawaiilife.com/?welcome-embed=1',
        comments:`${this.lead.address}\n comments:${this.lead.comments}`,
      });

      this.lead.email = null;
      this.lead.phone = null;
      this.lead.fullName = null;
      this.submittedToHL = true;
      this.errorForm = false;
    }
  },
  data() {
    const videoUrl = videos[r].url;
    const poster = videos[r].poster;
    return {
      selectedVideoUrl:videoUrl,
      lead:{
        fullName:null,
        email: null,
        phone: null,
        address: null,
        comments: null
      },
      errorName:false,
      errorEmail:false,
      errorPhone:false,
      errorAddress:false,
      errorComments:false,
      selectedVideoPoster:poster,
      section:'buy',
      errorForm:false,
      sellconfirm:false,
      submitted:false,
      submittedToHL:false,
    }
  },
  computed:{
  getOverlayStyle(){
    if(this.height){
      return {'height':this.height.toString() + 'px'};
    }
    return {'min-height':'600px'};
  }
  }
}
</script>

<style lang="scss" scoped>
div.video-and-search{
  position: relative;
}

.video-background-wrapper{
  position:absolute;
  left:0px;
  width:100vw;
}

.green{
  color:#47B048;
}

.cb-ml5{
  box-sizing: border-box;
  padding-left:0px;
  margin-top:10px;
  &.sell{
    display:flex;
    flex-direction:row;
    input[type="text"]{
      padding:15px;
      width:90%;
      outline:none;
      border:none;
      &::placeholder{
        color:#666666;
      }
    }
    .btn{
      padding:5px;
      background-color: #12b5ea;
      color:white;
      text-align: center;
      min-width:50px;
      display:flex;
      align-items: center;
      align-content: center;
      justify-content: space-around;
      min-width:90px;
      max-width:50%;
      span{
        font-weight: 700;
        font-family: "Whitney A", "Whitney B", sans-serif;
        text-align: center;
      }
    }
  }
}
p{
  font-family:"Whitney A","Whitney B",sans-serif;
  margin-left:0px;
  line-height:24px;
}

.search-container{
  position:absolute;
  width:100%;
  top:80px;
  left:0px;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow-y: visible;
  @media(max-width:520px){
    padding:10px;
    box-sizing: border-box;
    padding-right:18px;
  }
}
.hawaiilife-homepage-search .search-container{
  @media(max-width:520px){
    padding:20px;
  }
}


.search-wrapper{
  transition: all 0.5s ease;
  color:white;
  margin-top:70px;
  @media(max-width: 520px){
    margin-top:100px;
  }
  @media(min-width:800px) and (max-width: 1200px){
    margin-left:-28vw;
    margin-top:60px;
  }
  @media(min-width:520px) and (max-width: 900px){
    padding:10px;
  }


  box-sizing: border-box;
  width:auto;
  max-width:800px;
  @media(max-width:520px){
    width:100%;
  }
  h2,h4{
    font-family: "Whitney A", "Whitney B", Helvetica, Arial, sans-serif;
    color:white;
    font-weight:600;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 0.3px;
  }
  .shadowed, .also-check-out a{
    text-shadow: -2px -2px 2px rgba(0,0,0,.1), 2px 2px 2px rgba(0,0,0,0.3), 2px 3px 5px rgba(0,0,0,0.1);
  }


  h2{
      font-size:60px;
      @media(max-width:520px){
        font-size:31px;
      }
    @media(min-width:520px) and (max-width: 800px){
      font-size:45px;
    }
    margin-bottom:10px;
  }
  h4{
    font-size:22px;
    margin-bottom:20px;
    @media(max-width:520px){
      font-size:20px;
    }
  }

  > a{
    font-weight:bold;
    font-family:"Whitney A","Whitney B",sans-serif;
    font-size:19px;
    text-decoration:none;
    margin-top:10px;
    margin-bottom:10px;
    padding-left:5px;
    padding-right:5px;
    color:white;
    letter-spacing: 0.5px;
    word-wrap: normal;
    text-shadow: 10px 10px 10px 3px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.5s;
    &:hover{
      color:#12b5ea;
    }
    @media(max-width: 520px){
      font-size:14px;
    }
    &.hl-button{
      font-size:16px;
      text-decoration: none;
    }
  }

  .also-check-out{
    padding-top:30px;
    a{
      font-weight:500;
    }
  }

  .call-or-text{
    margin-top:30px;
    font-size:19px;
    margin-left:0px;
    color:white;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.2), 2px 3px 5px rgba(0, 0, 0, 0.13);
    &.black{
      text-shadow: none;
      a{
        color:#12b5ea !important;
      }
      color:black;
    }
    @media(max-width:520px){
      font-size:15px;
    }
    a{
      font-weight:bold;
      padding-left:2px;
      margin-top:0px;
      color:white !important;
      text-decoration: none;
    }
  }

  .divisions-cta{
    font-size:24px !important;
    @media(max-width:520px){
      font-size:17px !important;
    }
    a{
      font-weight:bold;
      font-family:"Whitney A","Whitney B",sans-serif;
      text-decoration:none;
      margin-top:10px;
      margin-bottom:10px;
      padding-left:5px;
      padding-right:5px;
      color:white;
      letter-spacing: 0.5px;
      word-wrap: normal;
      text-shadow: 10px 10px 10px 3px rgba(0, 0, 0, 0.6) !important;
      transition: all ease-in-out 0.5s;
      &:hover{
        color:#12b5ea;
      }
      &.underline-in-divisions{
        font-size:24px !important;
        color:#12b5ea !important;
      }
    }
    margin-left:-5px;
  }
  .divisions-cta a{
    font-weight:500;
  }

}
.hawaiilife-homepage-search{
  .video-background-wrapper{
    left:0;
  }
}

.search-wrapper{
  .result-items-header{
    h4{
      font-size:20px;
    }
  }
  .ac-property-detail{
    h4{
      font-size:20px;
    }
  }
}
span.single-line-mobile.header{
  @media(max-width:520px){
    display:block;
    width:100%;
    clear:both;
      font-size:50px;
  }
}

.form-body-copy{
  color:black !important;
  position:relative;
  svg{
    position:absolute;
    top:-10px;
    right:0px;
  }
}
.sell-form{
  background-color: white;
  display:flex;
  flex-direction: row;
  align-items: center;
  > div{
    width:50%;
    @media(max-width: 768px){
      width:100%;
    }
    &.promo-graphic{
      display:flex;
      justify-content: center;
      align-items: center;
      img{
        max-width:600px;
      }
      @media (max-width:768px){
        display:none;
      }
    }
  }
}

#sell-your-property{
  height:55px;
  padding:0 !important;
  padding-left:20px !important;
  background-color: white !important;
}

#sell-form.form-wrapper{
  font-family: "Whitney A", "Whitney B", sans-serif;
  background-color: white;
  display:flex;
  flex-direction: column;
  padding:10px;
  padding-top:30px;
  padding-left:20px;
  padding-right:20px;
  input[type="text"], textarea{
    font-size: 17px;
    height:40px !important;
    padding-left:10px;
    color:#777777;
    background-color: #fafafa;
    border:none;
    padding-top:20px !important;
    padding-right:-10px;
    font-size:15px;
    box-sizing: content-box !important;
    width:100%;
    &.error{
      border:1px rgba(255,0,0,0.8) solid;
      outline:none !important;
    }
    font-family: sans-serif;
    border: 1px solid #EEEEEE;
    /* sm */

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  }
  textarea{
    padding:10px;
    padding-top:20px;
    padding-right:2px;
  }
  label, h2, h3, p{
    color:black !important;
  }
  h3.property-valuation.blue{
    color:#12b5ea !important;
    margin-top:0px;
    margin-bottom:5px;
  }
  h4.process{
    color: #4B3900 !important;
  }

  .hl-button{
    margin-top:20px;
  }
}

:root{
  --hvs-input-background: #ffffff;
  --hvs-input-container-border-color:rgba(255,255,255,0.0);
  --hvs-input-ring-color: rgba(255,255,255,0.0);
  --hvs-input-font-size: 14px;
}

#hvs-input{
  border-radius:0px !important;

}

.hvs-border-wrapper{
  background-color: white;
  padding:5px;
}

.cta-text{
  background-color:#12b5ea;
  position:relative;
  svg{
    position:absolute;
    top:15px;
    right:15px;
    fill:white;
  }
  max-width:50vw;
  padding:40px;
  font-size: 26px;
  line-height: 1.2em;
  font-weight: 600;
  h2{
    font-size: 48px;
    line-height: 1.2em;
    font-weight: 700;
  }
  @media(max-width: 768px){
    max-width:80vw;
    margin-left:2vw;
  }
}
svg:hover{
  cursor:pointer;
}
#submit-cma{
  max-width:50%;
}

.blue,.property-valuation{
  color:#12b5ea !important;
}

.brown{
  color: rgb(102, 87, 38) !important;
}
h3{
  font-size:28px;
}

div.rel{
  width:calc(100% - 30px);
  position:relative;
  margin-bottom:20px;
  label{
    position:absolute;
    font-weight:100;
    left:10px;
    top:-4px !important;
    font-size:14px;
    color:rgba(115, 115, 115, 1) !important;
  }
}

@media(max-width: 768px){
  .promo-graphic{
    display:none;
  }
  .form-wrapper{
    max-width:90vw;
    padding-left:15px;
    padding-right:10px;
  }
  .form-body-copy svg{
    right:-20px;
  }
  .button.hl-button{
    width:calc(100% - 55px)
  }

}

span.required{
  color:red !important;
}

* {
  --hvs-input-border-style: none;
}




</style>
