function normalizePropertyTypeName (name,dwelling_type_name = null) {
    if(dwelling_type_name) {
        if(dwelling_type_name === 'Single Family') {
            return 'Residential';
        }
        if(dwelling_type_name === 'Condo') {
            return 'Condo';
        }
        if(dwelling_type_name === 'Townhouse') {
            return 'Condo';
        }
        if(dwelling_type_name === 'Multi-Dwelling') {
            return 'Residential';
        }
    }
    if(Array.isArray(name) && name[0]) {
        name = name[0];
    }
    if(name.includes('Land')) {
        return 'Land';
    }
    if(name.includes('Condo')) {
        return 'Condo';
    }
    if(name.includes('Lots/Land/Farm')) {
        return 'Land';
    }
    if(name.includes('ommerc')){
        return 'Commercial';
    }
    if(name.includes('Single')){
        return 'Residential'
    }
    if(name.includes('Townhouse')){
        return 'Condo';
    }
    return name;
}

export default normalizePropertyTypeName;
