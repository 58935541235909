<template>
<div>
  <m-l-s-powered-listings :team-slug="teamSlugOrOverride" v-if="mls_ids" :mls-ids="mls_ids"></m-l-s-powered-listings>
</div>
</template>

<script>
import axios from 'axios';
import MLSPoweredListings from "./MLSPoweredListings.vue";
export default {
  name: "SearchMLSListings",
  components:{
    MLSPoweredListings
  },
  props:['query','teamSlug'],
  data(){
    return {
      mls_ids:null,
    }
  },
  async mounted(){
    const {data} = await axios.get(this.query);
    const mlsIds = data.map(item => {
      return item.mls_id;
    });
    this.mls_ids = mlsIds.join(',');
  },
  computed:{
    teamSlugOrOverride(){
      if(this.teamSlug) return this.teamSlug;

      if(this.query.includes('advisor_ids=706,567,110')){
        return '/teams/team-hokulia';
      }


      if(this.query.includes('hokulia')){
        return '/teams/team-hokulia';
      }
      return null;
    }
  }
}
</script>

<style scoped>

</style>