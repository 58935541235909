<template>

<div class="hl-modal" :class="layout">
  <div style="position: relative;">
  <svg v-if="!hideClose" class="close-modal-hl-modal" :fill="modalFillColor" @click="closeModal" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
    <slot @click.stop=""></slot>
    </div>
</div>
</template>

<script>
export default {
name: "Modal",
  props:{
    closeModal:{
    type:Function,
    },
    hideClose:{
      type:Boolean,
      default:false,
    },
    layout:{
      type:String,
      default:'normal'
    },
    modalFillColor:{
      type:String,
      default:'#333333'
    }
    },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.close-modal-hl-modal{
  &:hover{
    cursor:pointer;
  }
}

.hl-modal{
  position:fixed;
  top:0;
  left:0;
  min-height:100vh;
  min-width:100vw;
  z-index:9000;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(100,100,100,0.5);
  .close-modal-hl-modal{
    position:absolute;
    top:8px;
    right:8px;
  }
  > div{
    min-width:500px;
    max-height:100vh;
    overflow-x:scroll;
    box-shadow: 0 3px 9px rgba(0,0,0,0.5);
    @media(max-width:520px){
      max-width:100vw;
    }
  }
  &.wide{
    > div {
      min-width:700px;
    }
  }
  &.narrow{
    > div{
      max-width:50vw;
      min-width:600px;
    }
  }
  &.auto{
    > div{
      width:auto;
    }
  }

}
</style>
