<template>
  <div class="menu-wrapper-target">
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path id="Rectangle" fill="000000" fill-rule="evenodd" stroke="none" d="M 3 8 L 21 8 L 21 6 L 3 6 Z"/>
      <path id="path1" fill="#000000" fill-rule="evenodd" stroke="none" d="M 3 13 L 21 13 L 21 11 L 3 11 Z"/>
      <path id="path2" fill="#000000" fill-rule="evenodd" stroke="none" d="M 3 18 L 18 18 L 18 16 L 3 16 Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "NavIcon"
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables/media";
.menu{
  &:hover{
    cursor:pointer;
    border-bottom: 1px #12b5ea solid;
    color:#12b5ea;
   }
  font-weight:500;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display:inline-block;
  @media(max-width: $media-mobile){
    font-size:12px;
    font-weight:500;
    border-bottom: 1px #333 solid;
    display:none;
  }


}

.menu-wrapper-target{
  &:hover{
    color:#12b5ea;
  }
  display:flex;
  flex-direction:row;
  align-items:center;
  svg{
    height:30px !important;
    width:30px !important;
    display:block;
    margin-right:5px;
    @media(max-width:$media-mobile){
      height:30px !important;
      width:30px !important;
      margin-right:10px;
    }
  }
}

</style>
