import axios from 'axios';
import sfapi from '../src/Salesforce/api';
import {serialize} from "object-to-formdata";
import normalizePropertyTypeName from "../../src/helpers/normalizePropertyTypeName";
import formatPhoneNumber from "./formatPhoneAreaCodeDashes";
export default (data,advisor_id = null,listing = null,passed_lead_source = null) => {
  const p = new Promise(async (resolve, reject) => {
    let prefix = window.location.origin.includes('localhost')? 'http://localhost:5000' : '';
    let debug = false;
    if(debug) console.log('line 9 data',data);
    let adv_id = data.advisor_id ? data.advisor_id : advisor_id;
    let {agent_email} = data;
    if (adv_id) {
      const agent = await axios.get(`${prefix}/api/v3/search/agents/id/${adv_id}`);
      agent_email = agent.data.email;
    }
    let first_name = data.name.split(' ')[0];
    let last_name = data.name.split(' ').slice(1).join(' ');
    if(!last_name) last_name = "Unknown"
    let apiData = {
      contact: {
        first_name: first_name,
        last_name: last_name,
        name: data.name,
        email: data.email,
        phone: data.phone
      },
      listing: data.listing ? data.listing : null,
      lead_source: data.lead_source,
      source_description: data.source_description,
      form: {
        comments: data.comments,
        preapprove: data.preapprove,
      },
      agent_email: agent_email,
    };

    if(listing && !data.listing){
      apiData.listing = listing;
    }
    const l = new sfapi();
    let agentEmail = null;

    l.action = apiData.lead_source === 'HL Web - Scheduled Showing' ? 'add_showing_request' : 'ask_question';
    l.setFirstName(apiData.contact.first_name);
    l.setLastName(apiData.contact.last_name);
    l.setEmail(apiData.contact.email);
    l.setPhone(apiData.contact.phone);
    l.setLeadSource(apiData.lead_source);
    l.setComments(apiData.form.comments);

    if(!apiData.lead_source){
      l.setLeadSource('HL Web - Email Message');
    }
    if(apiData.agent_email && apiData.agent_email === 'customerexperience@hawaiilife.com' || !apiData.agent_email){
      l.setLeadSource('HL Web - Email Message');
    }
    if(apiData.agent_email && apiData.listing){
      l.setLeadSource('HL Web - Question');
    }
    if(agentEmail && agentEmail.includes('customerexperience')){
      agentEmail = null;
    }

    if(!agentEmail){
      if(apiData.lead_source === 'HL Web - Direct Email'){
        apiData.lead_source = 'HL Web - Email Message';
      }
    }
    console.log('the agent email is: ', agentEmail);
    if(agentEmail === null){
      apiData.lead_source = 'HL Web - Email Message';
    }
    if (apiData.agent_email) {
      if(!apiData.agent_email.includes('customerexperience')) {
        l.setAgentEmail(apiData.agent_email);
        agentEmail = apiData.agent_email
      }
    } else {
      if (apiData.listing && apiData.listing.listing_agent && apiData.listing.listing_agent.email) {
        l.setAgentEmail(apiData.listing.listing_agent.email);
        agentEmail = apiData.listing.listing_agent.email;
      }
    }
    l.setFormData(apiData.form);
    if(apiData.listing) {
      l.setListing(apiData.listing);
    }
    l.setWebsource(data.source_url ? data.source_url : window.location.href.substring(0, window.location.href.indexOf('?')));
    //the nuclear option
    if(passed_lead_source) l.setLeadSource(passed_lead_source);

    try {
      if(!debug) l.create();
    } catch (error) {
      console.log('Error creating lead:', error);
    }

    if (data.preapprove && !debug) {
      const lsCopy = l.getLeadSource();
      l.setLeadSource('HL Web - Lending');

      apiData.form.comments = `Help me get pre-approved. \n \n` + apiData.form.comments;
      l.setFormData(apiData.form);
      if(!debug) await l.create();
      l.setLeadSource(lsCopy);
    }
    let property_type_name = null;
    if(apiData.listing){
      property_type_name = apiData.listing.property_type_name;

    if(Array.isArray(property_type_name) && property_type_name.length){
      property_type_name = property_type_name[0];
    }
      property_type_name = normalizePropertyTypeName(property_type_name,apiData.listing.dwelling_type_name);
    }

    let dataF = {
      contact: {
        FirstName: l.sendData.contact.first_name,
        LastName: l.sendData.contact.last_name,
        Email: l.sendData.contact.email,
        Phone: l.sendData.contact.phone,
        LeadSource:l.getLeadSource(),
      },
      request: {
        Inquiry_Message__c: l.sendData.pba__Request__c.Inquiry_Message__c ? l.sendData.pba__Request__c.Inquiry_Message__c : '',
        LeadSource__c: l.getLeadSource(),
        MLS_Number__c: apiData.listing ? apiData.listing.mls_id : null,
        Listing_Agent_Email__c: agentEmail,
        web_source__c: window.location.href.substr(0, 200),  //page url
        Neighborhood__c: apiData.listing ? apiData.listing.geo_town : null,
        Bedrooms_min__c:apiData.listing ? apiData.listing.total_beds : null,
        Region__c:apiData.listing ? apiData.listing.geo_island : null,
        Property_Price__c:apiData.listing ? apiData.listing.asking_price : null,
        District__c:apiData.listing ? apiData.listing.geo_district : null,
        pba__PropertyType__c: property_type_name, //Condo, Land, Residential, Commercial
      }
    }
    dataF.request.Inquiry_Message__c += "\n" + dataF.contact.Phone ? formatPhoneNumber(dataF.contact.Phone) : "";
    if(!dataF.contact.LastName || !dataF.contact.LastName.length) dataF.contact.LastName = "Unknown";
    if(passed_lead_source) dataF.request.LeadSource__c = passed_lead_source;
    if(passed_lead_source) dataF.contact.LeadSource = passed_lead_source;

    if(debug) {
      console.log(195, dataF);
      return;
    }

    const fd = serialize(dataF);
    await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',
        fd, {headers: {'Content-Type': 'multipart/form-data'}});
    if(data.preapprove) {
      dataF.request.LeadSource__c = 'HL Web - Lending';
      dataF.request.Inquiry_Message__c = `I need help with pre-approval. \n \n`;
      await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',
          fd, {headers: {'Content-Type': 'multipart/form-data'}});
    }
    resolve(true);
  });
  return p;
}

