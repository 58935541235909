<template>
  <div class="embedded-carousel-listings">
    <div v-if="listings && listings.length">
      <carousel :per-page="1" :navigationEnabled="true" paginationActiveColor="#12b5ea" :navigationClickTargetSize="30" :paginationPosition="'bottom-overlay'" :mouse-drag="true" :navigation-next-label="chevronRight" :navigation-prev-label="chevronLeft">
        <slide v-for="(item,idx) in listings" v-bind:key="idx" v-if="item.images && item.images[0]">
          <div class="cover">
          </div>
          <div class="bg-img-carousel" :style="getBackgroundImageStyle(item)" @click="openListing(item)">
              <div class="content">
                <h2>{{caseStrings(item.address.single_line)}}</h2>
                <p v-if="item.public_remarks"> {{item.public_remarks.substring(0,300).replace(/(<([^>]+)>)/gi, "")}}...<br />
                  <br /></p>
              </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import axios from 'axios';
import price from '@/helpers/price';
import caseStrings from "@/helpers/caseStrings";

import { Carousel, Slide } from 'vue-carousel';
// If you use Swiper 6.0.0 or higher

const arrowFill = '#12b5ea';

const chevronLeft = `<svg version="1.1" fill="${arrowFill}" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
<g>
<polygon points="207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`;

const chevronRight = `
<svg version="1.1" id="Capa_2" fill="${arrowFill}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" x="0px" y="0px" viewBox="0 0 256 256">
<g>
<g>
<polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
`;


export default {
  name: "QuerySlideshow",
  props:['showAgents','showSlideshow','showAgentTeam','query','limit'],
  components:{
    Carousel,Slide
  },
  data(){
    return {
      chevronRight,
      chevronLeft,
      listings:[],
    }
  },
  async mounted(){
    console.log('this.query',this.query);
    try{
      const {data} = await axios.get(this.query);
      if(data && data.length){
        if(this.limit){
          this.listings = data.slice(0,this.limit);
        }else{
          this.listings = data;
        }
      }


    }catch(e){
    }
  },
  methods: {
    price,
    caseStrings,
    openListing(item){
      window.open('https://www.hawaiilife.com/listings/' + item.slug,'_blank');
    },
    getBackgroundImageStyle(item) {
      return {'background-image': `url(${item.images[0].url})`,'background-size':'cover','height':'100%','padding':'20px;','background-position':'center'};
    },
    depluralize(str){
      return str.substring(0, str.length - 1);
    },
  },
  computed:{
    featuredListings(){
      let ret = [];
      if(!this.relatedListings && !this.relatedListings.listings) return [];
      return ret;
    },
  }
}
</script>

<style lang="scss">

.embedded-carousel-listings{
.flex-row{
  display:flex;
  align-items: center;
  flex-direction:row;


  .w-50{
    padding:20px;
    width:50%;
    &.pl-20{
      padding-left:40px;
    }
    img{
      max-width:100%;
      display:block;
      margin-left:auto;
      margin-right:auto;
    }
  }
}
.show-agent-team p{
  font: 20px/1.6 "Whitney A","Whitney B","Arial","Helvetica Neue","Helvetica",sans-serif;
}

.VueCarousel-navigation{
  button{
    height:100px !important;
    width:100px !important;
  }
  svg {
    height:100px !important;
    width:100px !important;
    fill:white;
  }
}

.VueCarousel-navigation-button{
  margin-top:-30px !important;
}

.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family:'Whitney A','Whitney B',sans-serif;
  display:flex;
  width:100%;
  height:600px;
  @media (max-width: 500px){
    height:300px;
  }
  h2{
    color: #F4F4F4;
    text-shadow: 1.4px 1.4px 1px rgba(0, 0, 0, 0.75);
    font-size: 38px;
    font-weight: 700;
    line-height: 45.6px;
  }
  p{
    font-size:17px;
    text-shadow: 1.4px 1.4px 1px rgba(0, 0, 0, 0.35);
  }

  .dropshadow{
    box-shadow:1.4px 1.4px 3px rgba(0, 0, 0, 0.1);
  }
}

.hover-pointer{
  display:flex;
  position:absolute;
  z-index:3010;
  height:600px;
  &:hover{
    cursor:pointer;
  }
}

div.cover{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  background-color: rgba(0,0,0,0.30);
  z-index:3000;
}

.bg-img-carousel{
  height:600px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
  .content{
    position:absolute;
    z-index: 3001;
    max-width:60%;
  }

.VueCarousel-navigation-prev {
  left: 60px !important;
}
.VueCarousel-navigation-next {
  right: 120px !important;
}

  button{
    outline: 0 !important;
  }

}
</style>
