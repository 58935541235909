<template>
<div class="agent-search-inline">
  <input type="text" @keyup="searchAgents" v-model="agentSearchText" class="hl-input" placeholder="Find an Agent.." /><a class="hl-button smaller" @click="runSearch">Search</a>
  <div class="search-results" v-if="agentSearchResults && agentSearchResults.length">
    <div class="result"  v-for="result in agentSearchResults">
      <a :href="`https://www.hawaiilife.com/${result.path}`" v-html="highlight(result.user_name)"></a>
    </div>

  </div>
  </div>


</template>

<script>
import axios from 'axios';
import { debounce } from "debounce";
var f = debounce((t) => {
  axios.get('https://www.hawaiilife.com/api/v1/advisors?query=' + t.agentSearchText).then((result) => {
    t.agentSearchResults = result.data;
  });
},600,false);



export default {
  name: "AgentSearch",
  data(){
    return {
      agentSearchText:null,
      agentSearchResults:[],
    }
  },
  methods:{
     searchAgents(){
        f(this);
     },
    highlight(text){
      text = text.replace(new RegExp(this.agentSearchText, "gi"), (match) => `<mark>${match}</mark>`);
      return text;
    },
    runSearch(){
       window.location = 'https://www.hawaiilife.com/agents?query=' + this.agentSearchText;
    }
  }
}
</script>

<style lang="scss">
.agent-search-inline{
  display:flex;
  position:relative;
  flex-direction: row;
  width:100%;
  input{
    max-width:50%;
    border-color:#666666;
    border-radius: 0px;
  }
  .hl-button{
    padding:10px;
    margin-top:0px;
    margin-left:10px;
    width:auto;
    padding-top:12px;
    font-size:14px;
    &:hover{
     text-decoration: none;
    }
  }
  .search-results{

    box-shadow:1.4px 1.4px 3px rgba(0, 0, 0, 0.1);
    position:absolute;
    top:40px;
    min-width:300px;
    background-color: white;
    height:auto;
    z-index:3000;
    padding:10px;
    .result{
      font-weight:700;
      margin-top:5px;
      font-size:15px;

      a{
        color:#333333;
        font-weight:300;
        text-decoration: none;
        &:hover{
          color:#12b5ea;
          text-decoration: underline;
        }
      }
      mark{
        font-weight:500;
        background-color: #f8f8f8;
        text-decoration: underline;
      }
    }
  }
}



</style>
