<template>
    <div class="hl-web-component recent-activity-list">
        <h2 class="loading"></h2>
        <h4 v-if="title" class="title">{{title}}</h4>
        <a v-if="!lang" class="market-activity__link" @click="navigateToListing(listing)" v-for="activity in recentActivity">
<span class="market-activity__price-type-loc ng-binding">

{{getPriceForActivity(activity)}}
{{getTranslation(activity.dwelling_type_name)}} {{getTranslation('in')}} {{getAreaForActivity(activity)}},
<span class="market-activity__island ng-binding">{{getIslandForActivity(activity)}},</span>
</span>
            <span v-if="activity.reduced" class="market-activity__tag market-activity__tag--new price">{{getTranslation('New Price')}}</span>
            <span v-if="!activity.reduced" class="market-activity__tag market-activity__tag--new ">{{getTranslation('New Listing')}}</span>
        </a>
        <a v-if="lang && lang == 'ja'" class="market-activity__link" @click="navigateToListing(listing)" v-for="activity in recentActivity">
<span class="market-activity__price-type-loc ng-binding">
{{getTranslation(activity.dwelling_type_name)}} {{getPriceForActivity(activity)}}, {{getAreaForActivity(activity)}},
<span class="market-activity__island ng-binding">{{getIslandForActivity(activity)}}</span>
</span>
            <span v-if="activity.reduced" class="market-activity__tag market-activity__tag--new price">{{getTranslation('New Price')}}</span>
            <span v-if="!activity.reduced" class="market-activity__tag market-activity__tag--new ">{{getTranslation('New Listing')}}</span>
        </a>
    </div>
</template>

<script>
    import priceHelper from './../helpers/price';
    import caseStrings from './../helpers/caseStrings';
    import axios from 'axios';
    export default {
        props:['title','lang'],
        name: "RecentActivity",
        data(){
            return {
                loading:true,
                recentActivity:null,
                exchangeRates:null,
            }
        },
        methods:{
            priceHelper,
            getAreaForActivity(listing){
                return listing.address ? caseStrings(listing.address.city) : 'somewhere';
            },
            getIslandForActivity(listing){

                return listing.area ? listing.area.name : 'Hawaii';
            },
            getPriceForActivity(listing){
                if(!this.lang) return '$' + priceHelper(listing.asking_price);
                if(this.lang === 'ja'){
                    return '￥' + ((listing.asking_price * this.exchangeRates.rates.JPY / 1000000) ).toFixed(1
                    ) + 'M'
                }
            },
            getTranslation(text){
                let data = {
                    ja:{
                        'New Price':'新価格',
                        'New Listing':'新規売り物件',
                        'in': '',
                        'Condominium':'コンドミニアム',
                        'Condo':'コンド',
                        'Residential':'住居',
                        'Land': '土地',
                        'Land,Residential':'土地、建物',
                        'Lots/Land/Farm':'土地',
                        'House':'戸建て'
                    }
                }
                if(data[this.lang] && data[this.lang][text]) return data[this.lang][text];
                return text;
            }
        },
        async mounted(){
            const rates = await axios.get('https://api.exchangeratesapi.io/latest');
            this.loading = false;
            this.exchangeRates = rates.data;
            const prs = [
                new Promise((res,rej) => {
                    axios.get('https://www.hawaiilife.com/api/v1/recent_activity').then(result => {
                        const {data} = result;
                        res(data);
                        this.recentActivity = data;
                    });
                })
            ]
            await Promise.all(prs);
        }
    }
</script>

<style lang="scss">
    .hl-web-component{
        &.recent-activity-list{
            padding:10px;
            a{
                :hover{
                    cursor:pointer;
                }
                font-size:15px;
            }
        }

        font-family:'Whitney Bold','Whitney A','Whitney B',sans-serif;
        .market-activity__link{
            display:block;
            float:left;
            clear:both;
            color:black;
            &:hover{
                cursor:pointer;
                color:black;
            }
            &:visited{
                color:black;
            }
            font-weight:600;
            font-size:21px;
            margin-bottom:10px;
            .market-activity__island {
                color: #9e9e9e;
            }
            .market-activity__tag.market-activity__tag--new{
                display:inline-block;
                margin-left:5px;
                text-transform: uppercase;
                color:#47B048;
                &.price{
                    color:#12b5ea;
                }

            }
        }
    }

</style>
