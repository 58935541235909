<template>
<div class="cta-blocking-modal-backdrop">
  <div class="hl-cta" :class="this.bgColor" :style="customStyles">
    <close-x :close="close"></close-x>
  <slot>
  </slot>
  </div>
</div>
</template>

<script>
import CloseX from "./close-x";
export default {
  name: "BlockingCTABox",
  props:['close','bgColor','fontColor'],
  components:{
    CloseX
  },
  computed:{
    customStyles(){
      if(this.bgColor){
        return {'background-color':this.bgColor,color:this.fontColor,'fill':this.bgColor}
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/variables/media';
@import '../assets/scss/mixins/box-shadow';
.cta-blocking-modal-backdrop{
  height:100vh;
  width:100vw;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(255,255,255,0.9);
  position:fixed;
  top:0;
  left:0;
  z-index:7000;
  svg{
    fill:white;
  }
  h1,h2,h3,h4,h5,h6{
    margin-bottom:20px;
  }
  .hl-cta{
    background-color: #12b5ea;
    padding:40px;
    @media(max-width:$media-mobile){
      padding:20px;
      width:95vw;
    }
    &.white{
      svg{
        fill:black;
      }
    }
    position:relative;
    color:white;
    font-size:17px;
    font-weight:500;
    a{
      color:white;
      font-weight:700;
      text-decoration: none;
      display:inline-block;
      padding:20px;
      padding-top:10px;
      padding-bottom:10px;
      border:2px solid white;
      text-transform: uppercase;
      margin-bottom:20px;
      &:hover{
        cursor:pointer;
        background-color: #4B3900;
      }
    }

  }
}
</style>
