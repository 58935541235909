<template>
  <div class="hl-listing-embed" @keyup="inspectKeyup">
    <div class="hl-listing-embed-image-container">
      <div v-if="activeImage" class="modal"  @click.self="closeModal();">
        <svg class="navigation-svg left" xmlns="http://www.w3.org/2000/svg" @click.stop="loadPreviousImage(true)" style="transform: scaleX(-1);" viewBox="0 0 32 32"><path d="M5.6 27.1L15.9 16 5.6 4.9 12.2 0 27 16 12.2 32l-6.6-4.9z"/></svg>
        <svg class="navigation-svg right" xmlns="http://www.w3.org/2000/svg" @click.stop="loadNextImage(true)" style="" viewBox="0 0 32 32"><path d="M5.6 27.1L15.9 16 5.6 4.9 12.2 0 27 16 12.2 32l-6.6-4.9z"/></svg>
        <div v-if="loadingImage" class="loading-placeholder"><h1>...</h1></div>
        <img class="modal-active-image" @load="loadingImage = false;" :src="activeImage" alt="" />
        <link rel="preload"  :href="getCDNUrl(this.activeListing.images[this.activeImageIndex - 1] ? this.activeListing.images[this.activeImageIndex - 1].url : '')" as="image" />
        <link rel="preload"  :href="getCDNUrl(this.activeListing.images[this.activeImageIndex - 1] ? this.activeListing.images[this.activeImageIndex + 1].url : '')" as="image" />
      </div>
      <div v-for="(listing,idx) of listings" class="listing-layout">
        <div class="listing-description" :style="{order: idx % 2 ? '1' : '2'}">
          <h1 v-if="listing.title && listing.title.length">{{listing.title}}</h1>
          <h2 class="top-headline">{{listing.address.single_line}} - ${{price(listing.asking_price)}}</h2>
          <h4 v-html="getDetailsForListing(listing).summary.join(' | ')">
          </h4>
          <div class="embedded-public-remarks" v-html="getPublicRemarks(listing)">
          </div>
          <div class="button-wrapper">
          <a :href="`https://www.hawaiilife.com${teamSlug ? teamSlug  + '' : ''}/listings/${listing.slug}`" target="_blank" class="see-details-button hokulia-button">See Details</a> <a href="/contact" style="margin-left:10px;" class="hokulia-button embedded-listing-contact-button">Contact Us</a>
          </div>
          <div class="additional-listing-details" v-if=" 1 === 2">
            <div class="agent-details">
              <span class="contact-agents">Contact</span>
              <div v-for="advisor of sortAdvisors(listing.advisors)">
                <img :src="advisor.tile_image_url" alt="" />
                <div class="bio">
                  <h4>{{advisor.user_name}}</h4>
                  <span>
                    {{advisor.designation}}<br />
                    <a :href="`tel:${advisor.phone}`">{{formatPhoneNumber(advisor.phone)}}</a>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="listing-detail-images" :style="{order: idx % 2 ? '2' : '1'}" style="position:relative;">
          <img @click="setActiveImage(listing.images[0],0,listing,true)" :src="getCDNUrl(listing.images[0].url)" style="width:100%;" alt="" />
          <div class="listing-status over-image" style="color:white;" v-if="listing.listing_status && listing.listing_status.name === 'Sold'">Sold</div>
          <div class="listing_images">
          <img v-for="(image,idx) in listing.images" v-if="idx && idx < 6" @click="setActiveImage(image,idx,listing,true)" :src="getCDNUrl(image.tile_url)" :alt="listing.address.line_1" />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
var animationInterval = null;

import price from '../helpers/price';
export default {
  name: "MLSPoweredListings",
  props:['mlsIds','descriptionLimit','teamSlug'],
  data(){
    return {
      activeImage:null,
      listings:[],
      activeImageIndex:null,
      activeListing:null,
      loadingImage:false,
      animatingCarousel:false,
    }
  },
  mounted(){
    this.loadListings();
  },
  computed:{
  },
  methods:{
    swapActiveImage(e,image){
      this.activeImage = image.url;
      e.stopPropagation();
    },
    sortAdvisors(advisors){
      let ret = [];
      let ret2 = [];
      for(var advisor of advisors){
        if(advisor.email.includes('carrie')){
          ret.push(advisor);
        }else{
          ret2.push(advisor);
        }
      }
      return ret.concat(ret2);
    },
    inspectKeyup(e) {
          },
    price,
    closeModal(){
      this.activeImage = false;
      clearInterval(animationInterval);
    },
    setActiveImage(image,idx,listing,startAnimating = false){
      if(startAnimating) this.animatingCarousel = true;
        this.loadingImage = true;

      this.activeImage = this.getCDNUrl(image.url);
      this.activeImageIndex = idx;
      this.activeListing = listing;
      if(startAnimating){
        animationInterval = setInterval(this.loadNextImage,5000);
      }
    },
    formatPhoneNumber(str){
      //Filter only numbers from the input
      let cleaned = ('' + str).replace(/\D/g, '');

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      };

      return null
    },
    loadPreviousImage(stop){
      if(stop && animationInterval) clearInterval(animationInterval);
      const imageLength = this.activeListing.images.length - 1;
      if(this.activeImageIndex == 0){
        this.activeImageIndex = imageLength - 1;
      }else{
        this.activeImageIndex -= 1;
      }
      this.setActiveImage(this.activeListing.images[this.activeImageIndex],this.activeImageIndex,this.activeListing);
    },
    loadNextImage(stop){
      if(stop) clearInterval(animationInterval);
      const imageLength = this.activeListing.images.length - 1; //zero based
      if(imageLength <= this.activeImageIndex){
        this.activeImageIndex = 0;
      }else{
        this.activeImageIndex += 1;
      }
      this.setActiveImage(this.activeListing.images[this.activeImageIndex],this.activeImageIndex,this.activeListing);
    },
    getAdvisorsForListing(listing){
      return listing.advisors ? listing.advisors : [];

    },
    getCDNUrl(url){
      const r = 'https://d1gt3v5rrahuun.cloudfront.net/';
      url = url.replace('https://s3-us-west-2.amazonaws.com/rets-mls/',r);
      return url;
    },
    getPublicRemarks(listing){
      let limit = this.$props["description-limit"] ? this.$props.descriptionLimit : 350;
      const public_remarks = listing.public_remarks;
      const arr = public_remarks.split(' ');
      let desc = '';
      let words = 0;
      for(var word of arr){
        if(words > 49) continue;
        desc += word + ' ';
        words+= 1;
        if(desc.length > 280) continue;
      }
      desc = desc.trim();
      return desc + ' <span class="grey">...</span>';
    },
    getDetailsForListing(listing){
      var meta = {
        summary:[],
        link:[],
        listing_agents:null
      }

      if(listing.total_beds){
        meta.summary.push(`${listing.total_beds} br`);
      }

      if(listing.total_baths){
        meta.summary.push(`${listing.total_baths} ba`);
      }
      if(listing.lot_size_ft){
        meta.summary.push(`${listing.lot_size_ft.toLocaleString()} sq ft land`);
      }
      return meta;
    },
    async loadListings(){
      const ids = this.mlsIds
      this.listings = await this.$store.dispatch('loadMLSByIds',ids);
    }
  },

}



</script>

<style lang="scss">

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.2); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.1); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}



.hl-listing-embed-image-container{
  .modal {
    .loading-placeholder {
      position: absolute;
      top: calc(45% - 90px);
      z-index: 9000;

    }

    h1 {
      font-size: 90px;
      color: white;
      opacity: 0.5;
      animation-name: pulse_animation;
      animation-duration: 3000ms;
      transform-origin:70% 70%;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
.hl-listing-embed{
  width:90%;
  margin:0 auto;
  padding:0px;
  box-sizing: border-box;
  @media(max-width:520px){
    width:100%;
    padding:0px;
  }
  .button-wrapper{
    margin-top:20px;
    display:flex;
    flex-direction: row;
    a{
      width:50%;
      display:flex;
    }
  }
  .modal{
    width:calc(100vw + 5px);
    height:calc(100vh + 5px);
    position:fixed;
    top:0px;
    left:0px;
    overflow-x: hidden;
    overflow-y: hidden;
    svg.navigation-svg{
      position:fixed;
      height:100px;
      @media(max-width:768px){
        height:50px;
      }
      @media(max-width:520px){
        height:35px;
      }
      fill:white;
      &.left{
        left:5px;
      }
      &.right{
        right:0px;
      }
    }
    img{
      max-width:70vw;
      @media(max-width:520px){
      max-width:80vw;
    }
    }
  }
  font-family: system-ui,'Helvetica',sans-serif;
  .listing-layout{
    margin-bottom:50px;
    padding-bottom:50px;
    border-bottom:1px #999 solid;
    .listing-description{
      h2.top-headline{
        margin-top:0px;
      }
      p{
        margin-bottom:40px;
      }
      a{
        margin-top:10px;
        margin-bottom:10px;
        color:#12b5ea;
        text-decoration: none;
      }
    }
    display:flex;
    flex-direction:row;
    @media(max-width:850px) {
      flex-direction: column;
    }
    justify-content: space-between;
    > div{
      width:48%;
      @media(max-width:850px){
        width:100%;
      }
      img{
        max-width:100%;
      }
    }
  }
  .listing_images{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left:-5px;

    img{
      margin-left:5px;
      width:calc(20% - 5px);
      height:calc(20% - 5px);
      margin-bottom:5px;
      &:hover{
        transform: scale(1.2); /* Equal to scaleX(0.7) scaleY(0.7) */
      }
    }
    &::after {
      content: "";
      flex: auto;
    }
  }
  .additional-listing-details{
    .agent-details{
      h1,h2,h3,h4{
        margin-block-start: 0;
      }
      h4{
        line-height:1.2em;
      }
      a{
        font-size:14px;
        font-weight:bold;
        @media(max-width:520px){
          font-size:14px;
          font-weight:bold;
        }
        &::after{
          content:''
        }
      }
      > div{
        vertical-align: middle;
        /**
        agent details go in here
         */
        display:flex;
        flex-direction:row;
        .bio{
          padding-left:20px;
        }
        img{
          width:100px;
          height:100px;
        }

        margin-bottom:20px;
      }
    }
  }

  @media(max-width:820px){
    .listing-detail-images{
      order:0 !important;
    }
    .listing-description{
      order: 1 !important;
    }
    .top-headline{
      margin-top:20px !important;
    }
  }

  .contact-agents{
    display:block;
    margin-bottom:10px;
  }

  a.hokulia-button{
    color: #fff !important;
    background-color: #a8a6a1;
    border-color: #a8a6a1;
    padding:20px;
    justify-content: center;
    font-weight:700;
    max-width:50%;
    margin-bottom:20px;

  }
  span.grey{
    color:#999;
  }

  .listing-status{
    background-color: #a8a6a1;
    color:white;
    font-weight:700;
    text-align: center;
    display:inline-block;
    box-sizing: border-box;
    padding:10px;
    margin-top:0px;
    margin-bottom:20px;
    &.over-image{
      position:absolute;
      top:10px;
      left:0px;
      padding:10px 20px;
    }
  }

}
</style>
