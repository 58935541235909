<template>
  <modal v-if="showModal" :close-modal="closeModal">
    <Working v-if="showWorking"><h2>Give us just a second to get you set up..</h2></Working>
<div style="margin:0 auto;" class="request-showing">
  <div v-if="showSuccess">
    <h2>Mahalo. We will be in touch with the next steps for your showing.</h2>
    <p>If you have any questions, feel free to reach out using the chat widget in the lower right, or by calling us at
      <a href="tel:800-370-3848" class="hl-blue bold no-underline">1 (800) 370-3848</a></p>

    <div v-if="!asap">
    <p>Add to your personal calendar</p>
    <div class="flex-row calendar-links">
      <a target="_blank" :href="google(calendarEvent)">Google </a>
      <a target="_blank" :href="ics(calendarEvent)">iCal (Apple)</a>
      <a target="_blank" :href="office365(calendarEvent)">Office 365</a>
      <a target="_blank" :href="outlook(calendarEvent)">Outlook</a>
      </div>
      </div>
  </div>
  <div v-if="!continued && !showSuccess" style="position: relative;">
    <h2>Meet with an agent to tour this property,<br />
    Virtually or in person.
    </h2>
    <p style="margin-bottom:5px;">You'll hear from a one of our customer experience team members to get you a showing.</p>
    <div v-if="listingData" class="hidden-mobile" style="margin-top:10px;">
      <div class="row vertical-align-center" v-if="listingData">
          <img style="height:80px; width:80px;" v-if="listingData && listingData.images && listingData.images[0]" :src="listingData.images[0].tile_url" :alt="listingData.address.single_line" />
          <h4>{{listingData.address.single_line}}</h4>
      </div>
    </div>
    <div style="position:relative;">
      <svg :style="{opacity: scrollCounter == 0 ? 0.5 : 1}" class="navigation-svg" xmlns="http://www.w3.org/2000/svg" @click="scrollDate('left')" style="transform: scaleX(-1); left:-40px;" viewBox="0 0 32 32"><path d="M5.6 27.1L15.9 16 5.6 4.9 12.2 0 27 16 12.2 32l-6.6-4.9z"/></svg>
      <svg :style="{opacity: scrollCounter >= -7 ? 1 :  0.5}" class="navigation-svg" xmlns="http://www.w3.org/2000/svg" @click="scrollDate('right')" style="right: -40px;" viewBox="0 0 32 32"><path d="M5.6 27.1L15.9 16 5.6 4.9 12.2 0 27 16 12.2 32l-6.6-4.9z"/></svg>
      <div class="date-row">
      <div :style="dateOffsetMargin()" style="transition: margin-left 0.5s ease-in-out;"></div>
        <div class="date-box" :class="{active:asap}" @click="asap = true;">
          <h3>ASAP<br />
          <small>I'm ready now!</small>
          </h3>

        </div>
        <div v-for="(date,idx) of forwardDates" :class="{active:idx === chosenDateIndex}" @click="chooseDateAtIndex(idx)" class="date-box">
          <h3 class="mt-15">
            {{getUpcomingDayFromJsDate(date)}} <br />
            {{getUpcomingDateFromJsDate(date)}}</h3>
        </div>
  <div v-for="(date,idx) of forwardDates" :class="{active:idx === chosenDateIndex}" @click="chooseDateAtIndex(idx)" class="date-box">
    <h3 class="mt-15">
      {{getUpcomingDayFromJsDate(date)}} <br />
      {{getUpcomingDateFromJsDate(date)}}</h3>
  </div>
    </div>
      <div class="select-one-of-two">
        <div :class="{active:meeting_type === 'in_person',error:this.error_fields.includes('meeting_type')}"  @click="meeting_type = 'in_person'"><h3 class="mt-15">Tour in person</h3></div><div :class="{active:meeting_type === 'video',error:this.error_fields.includes('meeting_type')}" @click="meeting_type = 'video'"><h3 class="mt-15">Video Walkthrough</h3></div>
      </div>
      </div>

    <div>
      <h4 v-if="!asap">What Time?</h4>
      <p v-if="!asap">Meetings are scheduled in Hawaii time. it is currently <span style="text-transform: lowercase;">{{getHawaiiTime}}</span> in Hawai'i.</p>
      <p v-if="asap">We will get you a showing as soon as we possibly can.</p>

      <select class="hl-select" name="" id="" v-model="chosenTime" v-if="!asap">
        <option :value="null">Choose time</option>
        <option v-for="(time,v) of times" :value="v">{{times[v]}}</option>
      </select>
      <div class="hl-button w-100-no-padding" v-if="(chosenDate || asap) && !continued && !showSuccess" @click="checkForContinue">{{asap ? 'Next' : 'Request this time'}}</div>
    </div>
  </div>
    <div class="meet-up" v-if="((timeSelected && chosenDate && continued) || (asap && continued)) && !showSuccess" style="width:100%;">
      <a @click="continued = false;" class="hl-blue">Go Back</a>
      <h2 v-if="!asap">Meet up on {{dateSelectedNiceString}} Hawaii Time</h2>
      <h2 v-if="asap">We will reach out with the next available time for a showing.</h2>
      <strong v-if="!asap">Your local time: {{theirTime}}</strong>
      <p class="" v-if="meeting_type === 'in_person'">We will schedule to meet you in person.</p>
      <p v-if="meeting_type === 'video'">We will have an agent reach out with a video tour for you.</p>
      <div class="details-form">
        <div class="rel form-group-inline">
        <label for="">First Name</label>
        <input type="text" class="hl-input w-80" v-model="leadData.contact.FirstName" :class="{error:this.error_fields.includes('first_name')}" placeholder="First Name..." />
          </div>
        <div class="rel form-group-inline">
        <label for="">Last Name</label>
        <input type="text" class="hl-input w-80" :class="{error:this.error_fields.includes('last_name')}" v-model="leadData.contact.LastName" placeholder="Last Name..." />
          </div>
        <div class="rel form-group-inline">
        <label for="">Email</label>
        <input type="text" :class="{error:this.error_fields.includes('email')}" class="hl-input w-80" placeholder="Email..." v-model="leadData.contact.Email" />
          </div>
        <div class="rel form-group-inline">
        <label for="">Phone</label>
        <input type="text" class="hl-input w-80" :class="{error:this.error_fields.includes('phone')}" placeholder="Phone number" v-model="leadData.contact.Phone" />
          </div>
        <textarea v-if="!showWorking" v-model="leadData.request.Inquiry_Message__c" cols="30" rows="10" placeholder="Anything else we should know?"></textarea>
        <a @click="validateSendLead()" class="hl-button w-100-no-padding"> Request Showing</a>
      </div>

    </div>
</div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import axios from 'axios';
import {serialize} from "object-to-formdata";
import Working from "@/components/Working";
const { google, outlook, office365,  ics } = require("calendar-link");
const { DateTime } = require("luxon");

export default {
  props:[
      'mlsNumber',
      'propertyAddress',
      'showModal',
      'closeModal',
  ],
  components:{
    Modal,
    Working
  },
name: "RequestShowing",
  mounted(){
    this.fetchListingDetails();

    if(window.config){
      this.leadData.contact.Email = config.userId;
      this.leadData.contact.FirstName = config.firstName;
      this.leadData.contact.LastName = config.lastName;
    }
    },
  data(){
  return {
      error_fields:[],
      meeting_type:null,
      chosenDateIndex:null,
      chosenTime:null,
      chosenDate:null,
      timeSelected: true,
      continued:false,
      scrollCounter:0,
      forwardDatesGen:[],
      showSuccess:false,
      listingData:null,
      showWorking:false,
      showErrors:true,
      theirTime:null,
      message:null,
      asap:false,
      calendarEvent:{
        title: "Hawaii Life, Requested Showing",
        description: "preliminary time to meet up",
        start: "2021-03-06 18:00:00+0100",
        duration: [2, "hour"],
      },
      times:{
        540:'9am',
        570:'9:30am',
        600:'10am',
        630:'10:30am',
        660:'11am',
        690:'11:30am',
        720:'12pm',
        750:'12:30pm',
        780:'1pm',
        810:'1:30pm',
        840:'2pm',
        870:'2:30pm',
        900:'3pm',
        930:'3:30pm',
        960:'4pm',
        990:'4:30pm',
        1020:'5pm'
      },
      leadData:{
        contact:{
          FirstName:'',
          LastName:'',
          Email:'',
          Phone:'',
          Leadsource: 'HL Web - Scheduled Showing'
        },
        request:{
          Inquiry_Message__c:'',
          LeadSource__c:'HL Web - Scheduled Showing',
          Showing_Request_Date__c:'2021-5-05T16:20:00-1000',
          MLS_Number__c:this.mlsNumber,
          Listing_Agent_Email__c:'',
          web_source__c:'',
          Neighborhood__c:'',
          Bedrooms_min__c:'',
          Region__c:'',
          Property_Price__c:'',
          District__c:'',
        }
      }
  }

  },
  computed:{
    validToSend(){
      return this.error_fields.length === 0;
    },
    getHawaiiTime(){
      const d = DateTime.fromJSDate(new Date(),{zone:'Pacific/Honolulu'});
      return d.toFormat('h:mma');
    },
    forwardDates(){
      let dates = [];
      let tomorrow = DateTime.local().plus({days:1});
      for(var i = 0; i < 10; i++){
        tomorrow = tomorrow.plus({days:1});
        dates.push(tomorrow.toJSDate());
      }
      this.forwardDatesGen = dates;
    return dates;
    },
    dateSelectedNiceString(){
      const dateObject = DateTime.fromJSDate(this.chosenDate);
      return dateObject.toFormat('EEE, MMM dd') + ' at ' +  this.times[this.chosenTime];
    },
  },
  methods:{
    google,
    ics,
    outlook,
    office365,
    async fetchListingDetails(){
      let base = window.location.href.includes('localhost') ? 'http://localhost:3000' : 'https://www.hawaiilife.com'

      const {data} = await axios.get(`${base}/api/v1/listings?mls_id=` + this.mlsNumber);
      this.listingData = data && data[0] ? data[0] : null;
            if(this.listingData.area){
        this.leadData.request.Region__c = this.listingData.area.name;
      }
      if(this.listingData.sub_area){
        this.leadData.request.District__c = this.listingData.sub_area.name;
      }
      if(this.listingData.asking_price){
        this.leadData.request.Property_Price__c = this.listingData.asking_price;
      }
      if(this.listingData.advisors && this.listingData.advisors.length){
        this.leadData.request.Listing_Agent_Email__c = this.listingData.advisors[0].email;
      }
    },
    sendInteraction(){
      if(window && window.HLIntelligence){
        window.HLIntelligence.track('ce_widget','request_showing');
      }
    },
    checkForContinue(){
      if(!this.meeting_type) this.error_fields.push('meeting_type');
      if(!this.chosenTime && !this.asap) this.error_fields.push('chosen_time');
      if(!this.chosenDate && !this.asap) this.error_fields.push('chosen_date');





      if(!this.error_fields.length){
        this.continued = true;
        if(!this.asap){
        const date = DateTime.fromJSDate(this.chosenDate).toISODate();
        const hour = Math.floor(this.chosenTime / 60);
        const minute = this.chosenTime % 60;
        const str = `${date}T${hour < 10 ? '0' + hour.toString() : hour.toString()}:${minute ? minute : '00'}:00-1000`;
        this.calendarEvent.start  = `${date}T${hour < 10 ? '0' + hour.toString() : hour.toString()}:${minute ? minute : '00'}:00-1000`;
        const tt = DateTime.fromISO(this.calendarEvent.start);
        this.theirTime = tt.toFormat(`ccc LLLL dd`) + ' at ' + tt.toFormat('h:mm') + tt.toFormat('a').toLowerCase();
        this.leadData.request.Showing_Request_Date__c = str;
        }
      }
    },
    async validateSendLead() {
      const blankOrNull = (val) => {
        if(!val) return true;
        if(val && !val.length) return true;
      }
      this.error_fields = [];
      if(this.leadData.contact.Email == null || this.leadData.contact.Email == '') {
        this.error_fields.push('email');
      }else {
        const email = this.leadData.contact.Email;
              }
      if(blankOrNull(this.leadData.contact.FirstName) ){
        this.error_fields.push('first_name');

      }
      if(blankOrNull(this.leadData.contact.LastName) ){
        this.error_fields.push('last_name');
      }
      if(blankOrNull(this.leadData.contact.Phone)){
        this.error_fields.push('phone');
      }
      const r = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      const result = r.test(this.leadData.contact.Phone);
      if(!result){
          this.error_fields.push('phone');
      }

      if(!this.error_fields.length){
        await this.sendLeadData();
      }else{
        this.showErrors = true;
      }

    },
    async sendLeadData(){
      this.showWorking = true;
      this.sendInteraction();
      if(!this.asap){
      this.leadData.request.Inquiry_Message__c += `\n-----\n They chose to meet up at ${this.dateSelectedNiceString} via ${this.meeting_type} for https://www.hawaiilife.com/mls/${this.mlsNumber} -- ${this.listingData ? this.listingData.address.single_line : ''}`;
      this.leadData.request.Inquiry_Message__c += `\n-----\n Add to your google calendar ${this.google(this.calendarEvent)}`;
      }
      if(this.asap){
        this.leadData.request.LeadSource__c = "HL Web - Request Showing";
        this.leadData.request.Inquiry_Message__c += `\n-----\n This is an ASAP request`;

      }
      this.leadData.request.web_source__c = window.location.href.substring(0,240);
      if(this.asap){
        this.leadData.request.Showing_Request_Date__c = null;
      }
      const r = serialize(this.leadData);
      const {data} = await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',
      r, { headers: {'Content-Type': 'multipart/form-data' }});
      this.showSuccess = true;
      this.showWorking = false;
    },


    scrollDate(direction){

      if(direction == 'left'){
        if(this.scrollCounter == 0) return;
        this.scrollCounter += 1;
      }
      if(direction == 'right'){
        if(this.scrollCounter < -7 ) return;
        this.scrollCounter -= 1;

      }
    },
    dateOffsetMargin(){
      const offset = (this.scrollCounter * 131);
      return {'margin-left':`${offset}px`};
    },
    getUpcomingDateFromJsDate(date){
      const d = DateTime.fromJSDate(date);
      return d.toLocaleString({ month: 'short', day: 'numeric' });
    },
    getUpcomingDayFromJsDate(date){
      const d = DateTime.fromJSDate(date);
      return d.toFormat('EEE');
    },
    getChosenDateFromindex(){
      const d = DateTime.fromJSDate(this.forwardDatesGen[this.chosenDateIndex]);
      return d;
    },
    chooseDateAtIndex(idx){
      this.asap = false;
      this.chosenDate = this.forwardDatesGen[idx];
      this.chosenDateIndex = idx;
    }
  }
}
</script>

<style lang="scss">
$brown : #665726;
.bold{
  font-weight:700;
  &.no-underline{
    text-decoration: none;
  }
  &:hover{
    cursor:pointer;
  }
}
.request-showing{
  text-align: left;
  transition: width 2s ease-in-out;
  transition: height 2s ease-in-out;
  padding:20px;
  background-color: white;
  max-width:600px;
  padding-left:40px;
  padding-right:40px;
  @media(max-width:520px){
    max-width:100vw !important;
    padding-top:10px;
  }

  .flex-row.calendar-links{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    a{
      padding:10px;
      background-color: #12b5ea;
      color:white;
      width:20%;
      text-align: center;
      font-weight:500;
      text-decoration: none;
      &:hover{
        background-color:$brown;

      }
    }
  }

  .navigation-svg{
    position:absolute; top:60px; height:30px; width:30px;
    transition: all .2s ease-in-out;
    @media(max-width:520px){
      top:44px;
    }
    &:hover{
      transform: scale(1.2);
    }
  }
  h3.mt-15{
    margin-top:15px !important;
  }

  p{
    margin-top:20px;
    margin-bottom:20px;
  }
  h1,h2,h3,h4{
    font-family: "Whitney A","Whitney B",sans-serif;
  }
}
@mixin margin-top-bottom{
  margin-top:20px;
  margin-bottom:20px;
}

@mixin select-item{
  @include margin-top-bottom;
  border:1px #f8f8f8 solid;
  padding:10px 10px;
  background-color: #f8f8f8;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  min-width:100px;
  border:1px #f3f3f3 solid;
  &:hover{
    background-color: rgba(18, 181, 234,0.6);
    cursor:pointer;
  }
  &.active{
    color:white;
    background-color: rgba(18, 181, 234,1);
  }
}
.date-row{
  width:100%;
  overflow: hidden;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .date-box{
    display:flex;
    @include select-item;
    height:80px;
    margin-right:10px;
    @media(max-width:520px){
      height:60px;
    }
  }
}
.select-one-of-two{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  @media(max-width:520px){
  flex-wrap: wrap;
}
  > div{
    @include select-item;
    flex: 0 0 47.5%;
    padding:0px;
    @media(max-width:520px){
      flex: 0 0 100%;
      margin-top:5px;
      margin-bottom:5px;
    }
  }
}
label{
  font-family: "Whitney A","Whitney B",sans-serif;
  font-weight:700;
}

.hl-input::placeholder{
  color:#999;
}

.w-80{
  width:80%;
  @media(max-width: 520px){
    width:80%;
  }
}
.hl-button{
  padding:20px;
}

select.hl-select{
  padding-right:30px;
}
p{
  margin-left:0px;
}
.details-form{
  .rel.form-group-inline{
    position:relative;
    display:flex;
    flex-direction:row;
    align-items: center;

    label {
      position:absolute;
      width:100px;
      @media(max-width:520px){
        display:none;
      }
    }
    input[type="text"]{
      margin-bottom:10px;
      margin-left:100px;
      @media(max-width:520px){
        margin-left:0px;
      }
    }
  }
  textarea{
    border:1px #f8f8f8 solid;
    width:100%;
    font-size:17px;
    padding:5px;
    font-family: 'Whitney B','Whitney A',sans-serif;
  }
  .error{
    outline:1px red solid;
  }
}




input[type="text"].error{
  outline:1px red solid;
}
a{
  font-family: "Whitney A","Whitney B",sans-serif;
  &:hover{
    text-decoration: underline;
  }
}

select.hl-select{
  padding:10px;
  height:60px;
  font-size:16px;
  padding-right:50px;
}

.hl-button{
  @media(max-width:520px){

  }
}

div.row.vertical-align-center{
  display:flex;
  flex-direction: row;
  vertical-align: middle;
  img{
    margin-right:20px;
  }
}

.hidden-mobile{
  @media(max-width:520px){
    display:none;
  }
}

.w-100-no-padding{
  width:100%;
  box-sizing: border-box;
}


</style>
