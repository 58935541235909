import { parse } from 'node-html-parser';
export default (htmlData,category = null) => {
    console.log('we are loading up our blog posts');
    let allPosts = [];
    let mainPost = null;
    let postOffsetStart = 0;
    const result = parse(htmlData);
    const articleSelect = result.querySelectorAll('.packery article');
    const main = result.querySelectorAll('div.visual-text');
    let imageUrl = null;
    let t = null;
    let description = null;
    let url = null;
    if(main && main.length){
        const vc = parse(main[0].innerHTML);
        const imageUrlRegex = /\((.*)\)/;
        const r = imageUrlRegex.exec(vc.innerHTML);
        const title = vc.querySelectorAll('a.feature-title');
        url = title[0].attributes.href;
        t = title[0].text.trim();
        if(r && r[1]){
            imageUrl = r[1];
        }

        const list = vc.querySelectorAll('ul.list li');
        const pubDate = list[1].text;
        const blogAuthor = list[0].text;
        const categoryE = vc.querySelector('span.category');
        const category = categoryE.text;
        mainPost = {title:t,link:url,thumbLarge:imageUrl,pubDate,category,author:blogAuthor};
    }
    if (articleSelect) {
        for (var item of articleSelect) {
            let html = parse(item.innerHTML);
            const titleElement = html.querySelector('h3.title a');
            const title = titleElement.text;
            const link = titleElement.attributes.href + '?ref=wc-embed';
            const list = html.querySelectorAll('ul.list li');
            const pubDate = list[1].text;
            const blogAuthor = list[0].text;
            const childHref = list[0] && list[0].childNodes ? list[0].childNodes[0] : null;
            const authorLink = childHref ? childHref.attributes.href : null;
            const blogAuthorLink = authorLink;
            const descElement = html.querySelector('.content-holder p');
            let desc = '';
            desc = descElement ? descElement.text : '';
            const thumbE = html.querySelector('img');
            const categoryE = html.querySelector('span.category');
            const categoryLink = categoryE ? categoryE.attributes.href : null;
            const category = categoryE ? categoryE.text : 'Real Estate';
            let thumb = null;
            if (thumbE) {
                thumb = thumbE.attributes.src;
            }
            const thumbLarge = thumb && thumb.length ? thumb.replace(/379x[0-9]+/,'1280x720') : '';
            if (!title) continue;
            allPosts.push({
                title,
                link: link,
                url: link,
                pubDate: pubDate,
                desc,
                thumb,
                thumbLarge,
                thumbDefault:thumb,
                category,
                categoryLink,
                pubDateFormat: pubDate,
                blogAuthorLink,
                author: blogAuthor.trim(),
                //     desc:item.description ? item.description[0] : "",
                //     author:item['dc:creator'] ? item['dc:creator'][0] : null,
                //     pubDateFormat:new Date(item.pubDate).toLocaleDateString('en-US',{month:'long',year:'numeric',day: 'numeric'})
            });
        }

    }
    postOffsetStart = 1;
    return {allPosts,mainPost,postOffsetStart};
}
