<template>
  <div class="hl-embed-video-background-wrapper">
    <div v-if="sources && sources.length">
  <video-background :style="getStyle()" :src="videoUrl" :sources="sources" :poster="posterUrl" :loop="true">
    <div class="content-wrapper" v-html="content">
    </div>
  </video-background>
    </div>
  </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player';
import axios from 'axios';

export default {
name: "FullScreenBackground",
  props:['poster','video','mobile','content','cssStyle'],
  components:{
  VideoBackground,
  },
  data(){
  return {
    isWistia: false,
    wistiaAssets:null,
  }
  },
  async mounted(){
     if(this.$props.video.includes('wistia')){
       this.isWistia = true;
       const result = this.$props.video.split('/medias/');
       const {data} = await axios.get(`https://api.wistia.com/v1/medias/${result[1]}.json`,{params:{
           'access_token':'42455998b6bbbe612d2db92181b21a38b62548dec62ee4d0a66b7651a0fa1861'
         }});
       this.wistiaAssets = data.assets;
     }
     return true;
  },
  methods:{
  getStyle(){
    if(this.cssStyle) return this.cssStyle;
    return "max-height: 400px; height: 100vh;";
    },
  },
  computed:{
    sources(){
      let sources = [];
      if(this.isWistia && !this.wistiaAssets){
                return [];
      }
      if(this.wistiaAssets){
        sources.push({src: this.wistiaAssets[5].url.replace('embed','embed-ssl').replace('http://','https://').replace('.bin','.mp4'),autoplay:true,poster:this.wistiaAssets[5].url.replace('.bin','.jpg').replace('embed','embed-ssl')});
        sources.push({src: this.wistiaAssets[5].url.replace('embed','embed-ssl').replace('http://','https://').replace('.bin','.mp4'),autoplay:true,poster:this.wistiaAssets[5].url.replace('embed','embed-ssl').replace('.bin','.jpg')});
        return sources;
      }
      const w = window.innerWidth;
      if(this.mobile && w <= 640){
        sources.push({src:this.mobile,res: 640,autoplay:true,poster:this.poster})
      }
      sources.push({src: this.video,autoplay:true,poster:this.poster});
      if(sources.length === 1){
        sources.push({src: this.video,autoplay:true,res:900,poster:this.poster});
      }
      return sources;
    },
    videoUrl(){
      if(this.video && !this.isWistia) return this.video;
      if(this.sources && this.sources.length) return this.sources[0].src;
    },
    posterUrl(){
     if(!this.isWistia) return this.poster;
      if(this.sources && this.sources.length) return this.sources[0].poster;
    }
  }
}
</script>

<style>
.hl-embed-video-background-wrapper{
  width:100%;
  min-height:400px;
}
.hl-embed-video-background-wrapper .videobg-content{
  display:flex;
  align-items: center;
  justify-content: center;
}

</style>
