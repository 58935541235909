<template>
  <div :class="classes ? classes : 'top-right'">
    <span v-if="label" class="close-label">{{label}}</span>
  <svg width="24" class="hl-close-icon"  @click.stop="close" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g><path fill="black" d="M 10.050,23.95c 0.39,0.39, 1.024,0.39, 1.414,0L 17,18.414l 5.536,5.536c 0.39,0.39, 1.024,0.39, 1.414,0 c 0.39-0.39, 0.39-1.024,0-1.414L 18.414,17l 5.536-5.536c 0.39-0.39, 0.39-1.024,0-1.414c-0.39-0.39-1.024-0.39-1.414,0 L 17,15.586L 11.464,10.050c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 15.586,17l-5.536,5.536 C 9.66,22.926, 9.66,23.56, 10.050,23.95z"></path></g></svg>
  </div>
</template>

<script>
export default {
  name: "close-x",
  props:['close','closeLabel','position','additionalClass','additionalClasses'],
  computed:{
    label(){
      return this.$props['closeLabel']
    },
    classes:function(){
      let ret = {};
      if(this.position === 'top-right' || !this.position){
        ret['top-right'] = true;
      }
      if(this.additionalClass){
        ret[this.additionalClass] = true;
      }

      if(this.additionalClasses){

        for (var className of this.additionalClasses){
          ret[className] = true;
        }
      }

      return ret;
    }
  }
}
</script>

<style scoped lang="scss">
  .top-right{
    position:absolute;
    top:10px;
    right:10px;
  }
  .top-right-20px{
    position:absolute;
    top:20px;
    right:20px;
  }

  svg{
    width:35px;
    height:35px;
    &.larger{
      height:35px;
      width:35px;
    }
    &:hover{
    cursor:pointer;
      border-bottom:1px black solid;
     }
    &.dropdown{
      width:25px !important;
      height:25px !important;
      fill:black !important;
    }
    &.margin-right-5vw{
      margin-right:5vw;
    }
    &.margin-top-45{
      margin-top:0px;
    }
  }

  .white{
    fill:white !important;

  }
  .close-label{
    letter-spacing: 1px;
    color:#333333;
    padding-top:15px;
    font-size:13px;
    text-transform: uppercase;
    margin-right:-3px;
  }



</style>
