<template>
  <div class="hl-working">
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "Working"
}
</script>

<style lang="scss" scoped>
.hl-working{
  position:fixed;
  top:0;
  left:0;
  min-height:100vh;
  min-width:100vw;
  z-index:9999;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(255,255,255,0.75);
> div{
  max-width:500px;
  max-height:90vh;
  overflow-x:scroll;
@media(max-width:520px){
  max-width:100vw;
}
}
}
</style>
