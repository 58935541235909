export default  (urlChange,replace = null,replaceWith = null) => {
  if(!urlChange || !urlChange.length) return urlChange;
  //urlChange = urlChange.replace('https://d1gt3v5rrahuun.cloudfront.net','');
  if(urlChange.includes('https://d1g')){
    return urlChange;
  }
  urlChange = urlChange.replace('/rets-mls/','');
  let url = `https://d1gt3v5rrahuun.cloudfront.net/${urlChange}`;
  if(replace && replaceWith){
    url = url.replace(replace,replaceWith);
  }
  return url;
};
