<template>
    <div class="hl-web-component featured-collection">
        <div class="splash-image" :style="splashImageStyle">
            <h2 v-if="collection_data && collection_data.title">{{collection_data.title}}</h2>
            <div class="content" v-if="collection_data && collection_data.content" v-html="collection_data.content">
            </div>
        </div>
        <div class="collection-listings">
            <div v-for="listing in collection_data.listings">
                <img :src="getImageURLForListing(listing)" />
            </div>
        </div>
        <p>{{this.collectionHandle}}</p>
        <Listings v-if="collection_data" :listings_override="collection_data.listings"></Listings>
    </div>
</template>

<script>
    import axios from 'axios';
    import Listings from './Listings';
    export default {
        name: "FeaturedCollections",
        props:['collectionHandle','url'],
        components:{
            Listings,
        },
        async mounted(){

            this.fetchCollection(this.$props.collectionHandle);
        },
        data(){
            return {
                collection_data:null
            }
        },
        methods:{
            fetchCollection:async function(handle){
                const base = window.location.href.includes('localhost') ? 'http://localhost:3000' : 'https://www.hawaiilife.com';
                const {data} = await axios.get(base + '/api/v1/collections/' + handle);
                this.collection_data = data;
            },
            getImageURLForListing(listing){
                const {images} = listing;
                let firstImage = null;
                if(images && images[0]){
                    firstImage = images[0];
                    return firstImage.tile_url;
                }
            }
        },
        computed:{
            splashImageStyle(){
                if(this.collection_data){
                    return {'background-image':`url(${this.collection_data.image})`}
                }
                return {}
            }
        }
    }
</script>

<style lang="scss">
    .featured-collection{
        .splash-image{
            background-size: cover;
            background-repeat: no-repeat;
            min-height:300px;
            background-position: center center;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h2 {
                font-size: 34px;
                line-height: 24px;
                color:white;
            }
            .content{
                clear:both;
            }
        }
        div.collection-listings{
            padding-top:30px;
            display:block;
            width:100%;
            display:flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

</style>
