<template>
<div v-if="!fadedOut" @click="openPage" class="cta-banner-maui">
  <div>
    <span><strong>Maui Strong:</strong> Please Help Maui – Donate Now!</span>
  </div>
  <svg @click.stop="dismissBanner" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill="white"/>
  </svg>

</div>
</template>

<script>
import Cookie from "js-cookie";

export default {
  name: "CTABanner",
  data(){
    const dismissed = Cookie.get('dismissed_cta_banner');
    console.log('dis',dismissed);
    if(dismissed){
      return {
        fadedOut:true,
      }
    }
    return {
      fadedOut:false,
    }
  },
  mounted(){
  },
  methods:{
    dismissBanner(){
      Cookie.set('dismissed_cta_banner',1,7);
      this.fadedOut = true;
      document.body.classList.remove('cta-banner-added');
    },
    openPage(){
          window.open(' https://www.hawaiilife.com/company/charitable-fund/', '_blank');
    }
  }
}
</script>

<style lang="scss">
.cta-banner-maui{
  background-color: #FF6633;
  font-family:"WhitneyA","WhitneyB",sans-serif;
  filter: drop-shadow(3px 0px 6px rgba(180,180,180,0.8));
  display:flex;
  justify-content: center;
  z-index:3000;
  &:hover{
    cursor:pointer;
  }
  div{
    flex-grow:1;
  }

  span{
    color:white;
    text-decoration:none;
    font-weight:500;
    strong{
      font-weight:700;
    }
  }
  text-align: center;
  padding:5px;
  padding-bottom:3px;
  font-size: 15px;
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  svg{
    margin-left:auto;
    margin-right:20px;
  }
}


body.cta-banner-added{
header.masthead{
  margin-top:30px !important;
}
header#header{
  margin-top:5px !important;
}
}


</style>
