<template>
<div>
  <div v-if="showAgents && featuredAgents && featuredAgents.length" style="margin-bottom:20px;">
    <div v-for="agent in featuredAgents" class="flex-row">
     <strong>Contact {{agent.user_first_name}} {{agent.user_last_name}}, {{agent.designation}}, {{depluralize(specialistHeadline)}} |
       <a @click="openContact">Inquire Now</a></strong>
    </div>
  </div>
  <div v-if="showSlideshow && featuredListings && featuredAgents && featuredAgents.length">
    <carousel :per-page="1" :navigationEnabled="true" paginationActiveColor="#12b5ea" :navigationClickTargetSize="30" :paginationPosition="'bottom-overlay'" :mouse-drag="true" :navigation-next-label="chevronRight" :navigation-prev-label="chevronLeft">
      <slide v-for="(item,idx) in featuredListings" v-bind:key="idx" v-if="item.images && item.images[0]">
        <div :style="getBackgroundImageStyle(item)" @click="openListing(item)">
          <h2>{{caseStrings(item.address.single_line)}}</h2>
          <p v-if="item.public_remarks"> {{item.public_remarks.substring(0,300).replace(/(<([^>]+)>)/gi, "")}}...<br />
          <br /><a @click="openListing(item)" class="hl-button dropshadow" style="max-width: 200px;">See it!</a></p>
        </div>
      </slide>
    </carousel>
  </div>
  <div class="show-agent-team" v-if="showAgentTeam && featuredAgentHeader">
    <div class="flex flex-row">
      <div class="w-50">
        <img :src="featuredAgentCTAImgSrc" alt="" />

      </div>
      <div class="w-50 pl-20" v-if="featuredAgentHeader">
        <h1>{{featuredAgentHeader}}</h1>
        <div v-html="featuredAgentCTAText" style="margin-top:20px; margin-bottom:20px;"></div>
        <a @click="openContact" class="hl-button mt-20">Contact Us</a>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {mapGetters} from 'vuex';
import price from '@/helpers/price';
import caseStrings from "@/helpers/caseStrings";

import { Carousel, Slide } from 'vue-carousel';
// If you use Swiper 6.0.0 or higher

const arrowFill = '#12b5ea';

const chevronLeft = `<svg version="1.1" fill="${arrowFill}" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
<g>
<polygon points="207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`;

const chevronRight = `
<svg version="1.1" id="Capa_2" fill="${arrowFill}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" x="0px" y="0px" viewBox="0 0 256 256">
<g>
<g>
<polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"/>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
`;


export default {
name: "AreaAdditionalContent",
  props:['showAgents','showSlideshow','showAgentTeam'],
  components:{
  Carousel,Slide
  },
  data(){
  return {
    chevronRight,
    chevronLeft,
  }
  },
  methods: {
    price,
    caseStrings,
    openListing(item){
      window.open('/listings/' + item.slug,'_blank');
    },
    openContact() {
      document.querySelector('.contact-live-chat').click();
    },
    getBackgroundImageStyle(item) {
      return {'background-image': `url(${item.images[0].url})`,'background-size':'cover','height':'100%','padding':'20px;','background-position':'center'};
    },
    depluralize(str){
      return str.substring(0, str.length - 1);
    },
  },
  computed:{
  ...mapGetters(['areaContentData','relatedListings']),
    featuredAgents(){
      if(this.areaContentData) return this.areaContentData.featured_agents;
    },
    featuredListings(){
    let ret = [];
    if(!this.relatedListings && !this.relatedListings.listings) return [];
    let copy = this.relatedListings.listings;
    if(copy && copy.length){
      copy.map((item,index) => {
        if(item.advisors && item.advisors.length){
          ret.push(item);
        }
      });
    }
    return ret;
    },
    featuredAgentCTAText(){
    if(this.areaContentData) return this.areaContentData.featured_agents_body;
    },
    featuredAgentCTAImgSrc(){
      if(this.areaContentData && this.areaContentData.featured_agents_image){
        if(!this.areaContentData.featured_agents_image.includes('missing.png')) return this.areaContentData.featured_agents_image;
      }
      if(this.featuredAgents && this.featuredAgents[0]){
        return this.featuredAgents[0].tile_image_url;
      }
    },
    featuredAgentHeader(){
      if(this.areaContentData) return this.areaContentData.featured_agents_header;
    },
    specialistHeadline(){
    let headline = '';
      if(this.areaContentData){
        if(this.areaContentData.featured_agents_header){
          headline = this.areaContentData.featured_agents_header;
        }
      }
      let plural = false;
    if(this.featuredAgents && this.featuredAgents.length > 1){
        plural = true;
    }
    if(plural) return headline + 's';
    return headline;
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-row{
  display:flex;
  align-items: center;
  flex-direction:row;


  .w-50{
    padding:20px;
    width:50%;
    &.pl-20{
      padding-left:40px;
    }
    img{
      max-width:100%;
      display:block;
      margin-left:auto;
      margin-right:auto;
    }
  }
}
.show-agent-team p{
  font: 20px/1.6 "Whitney A","Whitney B","Arial","Helvetica Neue","Helvetica",sans-serif;
}

.VueCarousel-navigation{
  button{
    height:100px !important;
    width:100px !important;
    outline:5px orange solid !important;
  }
    svg {
      outline: 1px red solid;
      height:100px !important;
      width:100px !important;
      fill:black;
    }
}

.VueCarousel-navigation-button{
  margin-top:-30px !important;
}

.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family:'Whitney A','Whitney B',sans-serif;
  height:600px;
  @media (max-width: 500px){
    height:300px;
  }
  h2{
    position:absolute;
    top:200px;
    color: #F4F4F4;
    text-shadow: 1.4px 1.4px 1px rgba(0, 0, 0, 0.35);
    font-size: 38px;
    font-weight: 700;
    line-height: 45.6px;
    margin-left:40px;
  }
  p{
    position:absolute;
    top:300px;
    margin-left:40px;
    margin-right:40px;
    font-weight:bold;
    max-width:80%;
    text-shadow: 1.4px 1.4px 1px rgba(0, 0, 0, 0.35);
  }

  .dropshadow{
    box-shadow:1.4px 1.4px 3px rgba(0, 0, 0, 0.1);
  }
}


</style>
