<template>

    <div class="featured-posts stacked" v-if="posts && posts.length">
        <intersect @enter="setIntersected">
        <div>
            <div class="flex-row-direction more-link header-wrapper">
            <h2 v-if="!hidetitle" v-html="title"></h2>
            </div>
           <div @click="openBlogPost(mainBlogPost.link)" v-if="mainBlogPost" class="big-image hover-pointer" :style="getBigPostImageStyle(mainBlogPost)">
               <div class="inner-content">
                   <span class="category" v-if="!hideCategoryBlocks">{{categoryOverride ? categoryOverride : mainBlogPost.category}}</span>
                   <h2>{{mainBlogPost.title}}</h2>
                   <ul style="margin-top:-20px;" class="list"><li><a :href="mainBlogPost.blogAuthorLink">{{mainBlogPost.author}} </a> |</li> <li>{{mainBlogPost.pubDate}}</li></ul>
               </div>
           </div>
            <div class="blog-post-collection-stacked" :style="getStyleForPostCollection()">
                <div class="blog-post-collection-item" v-for="blogPost in posts.slice(this.postOffsetStart,4)" @click="openBlogPost(blogPost.url)">
                    <div class="img-bg-wrapper">
                    <div v-if="blogPost.thumb" class="image-bg">
                        <img style="width:100%;" :src="getPostImageUrl(blogPost)" alt="" />

                    </div>
                    </div>
                        <div class="content-item">
                    <a :href="blogPost.url" target="_blank" class="no-underline"><h2>{{blogPost.title}}</h2></a>
                    <div class="blog-post-description" v-html="blogPost.desc">
                    </div>
                    <div class="blog-post-summary">
                        <span>{{blogPost.pubDateFormat}}</span>
                    </div>
                    </div>
                </div>
            </div>
            <div class="flex-center padded more-bottom">
                <a :href="url" class="more-link arrow not-mobile">{{morehreftext}}</a>
                <a :href="url" class="more-link arrow mobile-only">More</a>
            </div>
        </div>
        </intersect>
    </div>
</template>

<script>
    import axios from 'axios';
    import loadPostsFromWordpressHTML from '../helpers/loadPostsFromWordpressHtml';
    import loadPostsFromVR from '../helpers/loadPostsFromVR';
    import Intersect from 'vue-intersect'
    export default {
        name: "FeaturedBlogPosts",
        props:['title','url','category','src','utmsrc','hidetitle','morehreftext','categoryOverride','hideCategoryBlocks'],
        data(){
            return {
                posts:[],
                mainPost:null,
                postOffsetStart:1,
            }
        },
        components:{
            Intersect
        },
        methods:{
            getStyleForPostCollection(){
                if(this.hideCategoryBlocks){
                    return {'margin-top':'30px'};
                }
                return {}
            },
            setIntersected(){
            },
            getPostImageStyle(post){
                return this.getBigPostImageStyle(post);
            },
          getBigPostImageStyle(post){

              if(post.thumb && post.thumb.includes('vacations.hawaiilife.com')){
                  return {'background-image':`url('${post.thumb.replace('styles/listing_teasers/public/blog-teaser','uploads')}'),url(${post.thumb})`};
              }

              return {'background-image':`url('${post.thumbLarge}'),url('${post.thumbLarge.replace('720','700')}'),url('${post.thumbLarge.replace('1280','1200')}'),url('${post.thumbLarge}'),url('${post.thumbDefault}'),url('https://about.hawaiilife.com/wp-content/uploads/2013/01/iStock-903132006-1-1280x720.jpg')`};
          },
            getPostImageUrl(post){
                return post.thumbLarge;
            },
            openBlogPost(url){
                let append = '?utm_source=';
              append += this.utmsrc ? this.utmsrc : 'featured-embedded-blog-post';
              url += append;
              window.open(url ,'_blank');
              return false;
            }
        },
        computed:{
            mainBlogPost(){
                if(this.mainPost) return this.mainPost;
                if(this.posts && this.posts[0]) return this.posts[0];
            }
        },
        async mounted() {
            let allPosts = [];
            let data = {};
            try {
                 result = await axios.get(this.url);
                 data = result.data;

            }catch(e){
                return {};
            }
            let result = {};

            if(!this.src) result = loadPostsFromWordpressHTML(data);
            if(this.src == 'vr'){
                result = loadPostsFromVR(data);
                this.posts = result.allPosts;
                this.postOffsetStart = result.postOffsetStart;
                this.mainPost = result.mainPost;
                return;
            }
            this.posts = result.allPosts;
            this.postOffsetStart = result.postOffsetStart;
            this.mainPost = result.mainPost;
            this.$forceUpdate();
        }

    }
</script>

<style lang="scss" scoped>
    @import '../assets/styles.scss';
    div.featured-posts.stacked {
        font-family: "Whitney A", "Whitney B", sans-serif;
        div.big-image{
            min-height:300px;
            background-size:cover;
            background-position: center bottom;
        }
        div.blog-post-collection-stacked, .header-wrapper {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin:0 auto;
            @media(max-width:1000px){
                width:80%;
            }
            @media(max-width: 800px){
                width:100%;
            }
            @media(max-width:500px){
                .blog-post-description{
                    display:none;
                }

            }

            &.header-wrapper{
                padding-left:20px;
            }


            .blog-post-collection-item {
                display: flex;
                flex-direction: row;
                border-bottom:1px #f8f8f8 solid;

                .img-bg-wrapper {
                    width: 40%;
                    max-width: 300px;
                    padding: 20px;
                    position:relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        display:block;
                    }
                }

                .content-item {
                    flex-basis: 70%;
                    padding: 20px;
                    padding-top:0px;

                    a {
                        color: black;
                        text-decoration: none;
                        text-underline: none;
                    }
                }
            }
        }
        .big-image{
            display:flex;
            align-items: center;
            align-content: center;
            .inner-content{
                width:50%;
                margin:0 auto;
            }
        }

        .big-image h2{
            color: #F4F4F4;
            text-shadow: 1.4px 1.4px 1px rgba(0, 0, 0, 0.35);
            font-size: 38px;
            font-weight: 700;
            line-height: 45.6px;
            font-family: 'Whitney A', 'Whitney B', sans-serif;
        }
        .big-image ul{
            list-style-type: none;
            -webkit-padding-start: 0px;
            float:left;
            color:white;

            li{
                margin-left:10px;
                display:inline-block; float:left;
            }
            a{
                color:white;
                text-decoration: none;
            }
        }

        div.blog-post-summary{
            margin-top:20px;
        }

        .image-bg{
            height:auto;
        }

    }
</style>
